import React from 'react'
import { ClubMateMessage, MessageType } from '../state/useClubPortalState'
import {AiFillCheckCircle,AiFillInfoCircle,AiFillWarning} from "react-icons/ai";


const lookupBg = (messageType: MessageType) => {
    switch(messageType){
        case "error":
            return "bg-error";
        case "warning":
            return "bg-warning";
        case "info":
            return "bg-primary";
        default:
            throw Error();
    }
}

const lookupIcon = (messageType: MessageType) => {
    switch(messageType){
        case "error":
            return  <AiFillCheckCircle className='text-white'/>;
        case "warning":
            return <AiFillWarning className='text-white'/>;
        case "info":
            return <AiFillInfoCircle className='text-white'/>;
        default:
            throw Error();
    }
}
 
export default function AppMessage(props: {message: ClubMateMessage}) {
  return (
    <div className={"rounded-lg  text-white flex flex-row space-x-5 items-center p-4 justify-center " + lookupBg(props.message.type)}>
        {lookupIcon(props.message.type)}
        <span>{props.message.message}</span>

    </div>
  )
}
