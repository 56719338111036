import React, { ReactNode, useEffect, useState } from 'react'
import { fetchClubSettings } from './api/clubSettingsApi';
import { onIssuedEntranceTicketBatches } from './api/entranceTicketsApi';
import { initializeFirebase } from './api/firebaseApi';
import { fetchMainEvents } from './api/mainEventApi';
import ClubSelection from './components/ClubSelection';
import LoadingIndicatorModal from './components/LoadingIndicatorModal';
import { Club, ClubSettings, ClubUser, ClubUserType, IssuedEntranceTicketBatch, MainEvent } from './model/lottery';
import { useClubPortalState } from './state/useClubPortalState';
import { parseClubRoles } from './tools/userTools';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";

import HomePage from './components/home/HomePage';
import HandleIssuedEntranceTicketBatches from './components/entranceTickets/HandleIssuedEntranceTicketBatches';
import IssueEntranceTickets from './components/entranceTickets/IssueEntranceTickets';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Layout from './components/Layout';
import AppMessage from './components/AppMessage';
import EntranceTicketPage from './components/entranceTickets/EntranceTicketPage';
import HelpModal from './components/HelpModal';
import { onAuthStateChanged, getAuth } from 'firebase/auth';

export default function App() {


    const [hasFetchedUser, setHasFetchedUser] = useState(false);
    const {helpMessage, hideHelpModal, showHelpModal, setClub, setClubSettings, setIssuedEntranceTicketBatches, setMainEvents, club, setUser,issuedEntranceTicketBatches,clubSettings, mainEvents,message, hideMessage,user } = useClubPortalState();
    const [clubRoles, setClubRoles] = useState(undefined as undefined | {[clubId:string] : ClubUserType});
console.log("hehehehhehe");
    useEffect(() => {
      onAuthStateChanged(getAuth(), (user) => {
        if(user != undefined){
          parseClubRoles(user).then((clubRoles) => setClubRoles(clubRoles));
          
        }else{
          setClubRoles(undefined);
        }
        setHasFetchedUser(true);
        setUser(user ?? undefined);

      });
    }, []);
  
    useEffect(() => {
      setTimeout(() => {
        hideMessage();
      }, 5000);
    }, [message])
  


    useEffect(() => {
      if(club != undefined){
        fetchMainEvents(club.id).then((ms) => {
          setMainEvents(ms);
        });
        fetchClubSettings(club.id).then(c => {
          setClubSettings(c);
        });
        onIssuedEntranceTicketBatches(club.id, (ie => {
          setIssuedEntranceTicketBatches(ie);
        }));
        
      }
    }, [club]);

  const isLoadingUser = !hasFetchedUser;
  const isLoadingClubRoles = user != undefined && clubRoles == undefined;
  const isLoadingAppData = !(mainEvents &&  clubSettings && issuedEntranceTicketBatches) && user != undefined; 

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout><HomePage/></Layout>,
    },
    {
      path: "/entre",
      element: <Layout><AuthenticatedRoute><EntranceTicketPage /></AuthenticatedRoute></Layout>,
    },
  ], );
  

  return (
    <>
    {(isLoadingUser || isLoadingClubRoles || isLoadingAppData) && <LoadingIndicatorModal/>}

    {(clubRoles != undefined && club == undefined) && <ClubSelection clubRoles={clubRoles} onClubSelected={(c) => setClub(c)}/>}

    {!isLoadingAppData &&  <RouterProvider router={router} />}

    {message != undefined && <div className='fixed bottom-0 mx-auto'><AppMessage message={message}/></div>}
    {helpMessage != undefined && <div className='modal modal-open'>
      <div className='modal-box'>
          <HelpModal paragraphs={helpMessage.paragraphs} title={helpMessage.title}/>
        </div></div>}
  
    </>
  )
}
