import React, { useState } from 'react'
import { FaRegPaperPlane } from 'react-icons/fa';
import { sendEmailInvite } from '../../../api/emailListApi';
import { validateEmail } from '../../../common/tools/inputValidation';
import { EntranceTicketClassCount, MainEvent } from '../../../model/lottery';
import { useClubPortalState } from '../../../state/useClubPortalState';
import { countEntranceTickets } from '../../../tools/entranceTicketTools';
import { getMainEventText } from '../../MainEventList';
import EntranceTicketClassCountInputCarousel from '../EntranceTicketClassCountInputCarousel';

export default function InviteByEmail(props: {emails: string[], selectedMainEvent: MainEvent}) {
  
    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const {showMessage, club} = useClubPortalState();
    const [entranceTicketClassCounts, setEntranceTicketClassCounts] = useState(props.selectedMainEvent.entranceEvent!.entranceTickets.filter(e => e.price == 0).map(e => EntranceTicketClassCount.fromPartial({
        entranceTicketClass:  e,
        numberOfTickets: 0
    })));
     const [email, setEmail] = useState("");
    const filterEmail = (e:string) => {
        return e.toLowerCase().includes(email.toLowerCase());
    };
  
  return (
    <div className='flex flex-col h-[70%] justify-center items-center'>
        
       <div className='flex flex-col items-center space-y-1'>

        <EntranceTicketClassCountInputCarousel entranceTicketClassCounts={entranceTicketClassCounts}  onChange={(c) => {
            setEntranceTicketClassCounts(c);
        }} />

        <h3 className='font-bold' >Ange en email</h3>
        <div className='relative'>
        <input
        value={email}
        onChange={(e) => {setEmail(e.target.value)}}
         onFocus={() => {
            setIsDropDownVisible(true)
        }} className='input input-primary w-[500px] text-center text-primary font-bold' type={"email"}/>
        <button onClick={() => {
            sendEmailInvite(email,props.selectedMainEvent.id, club!.id,entranceTicketClassCounts).then(() => {
                showMessage({message: email + " Har fått ett mail med biljett(er) för: " + getMainEventText(club!,props.selectedMainEvent), type : "info"});
                setEmail("");
            }).catch(() => {
                showMessage({message: "Något gick fel", type : "error"});

            });
            
            
        }} className={'btn  absolute -right-32 ' + ((validateEmail(email) && countEntranceTickets(entranceTicketClassCounts) > 0)  ? "btn-primary" : "btn-disabled")}>
            <span className='mr-2'>Bjud in</span>
            <FaRegPaperPlane className='text-white inline'/>
        </button>
        </div>
        </div>
        {isDropDownVisible && <ul className='flex flex-col shadow-2xl p-1 rounded-xl w-[500px] h-[200px] overflow-y-auto'>
            {props.emails.filter(filterEmail).map(e => <li onClick={() => {
                setIsDropDownVisible(false);
                setEmail(e);
            }}  className='list-item font-bold text-xs'>{e}</li>)}
      
        </ul>}
        {!isDropDownVisible && <div className='w-[500px] h-[200px] bg-transparent'/>}
    </div>
  )
}
