import React, { useState } from 'react'
import IssueEntranceTickets from './IssueEntranceTickets';
import HandleIssuedEntranceTicketBatches from './HandleIssuedEntranceTicketBatches';
import InviteByEmail from './invite/InviteByEmail';
import { useClubPortalState } from '../../state/useClubPortalState';
import { MainEvent } from '../../model/lottery';
import MainEventSelectionButton from '../MainEventSelectionButton';

type ScreenContent = "create" | "print" | "email";

export default function EntranceTicketPage() {
    const [screenContent, setScreenContent] = useState("create" as ScreenContent);
    const [selectedMainEvent, setSelectedMainEvent] = useState(undefined as MainEvent | undefined);
    const {club, mainEvents} = useClubPortalState();
  return (
    <div className='flex flex-col items-center h-full space-y-5'>
      <div className={selectedMainEvent == undefined ? "" : ""}>
        <MainEventSelectionButton club={club!} mainEvents={mainEvents!.filter(m => !m.hasEnded)} selectedMainEvent={selectedMainEvent} onMainEventSelected={(m) => setSelectedMainEvent(m)}/>
        </div>
    <div className='btn-group	space-x-[0.5px]'>
        <button onClick={() => setScreenContent("create")} className={'btn btn-sm'+ (screenContent == "create" ? "" : " btn-active")}>Skapa biljetter</button>
        <button onClick={() => setScreenContent("print")} className={'btn btn-sm' + (screenContent == "print" ? "" : " btn-active")}>Skriv ut biljetter</button>
        {selectedMainEvent != undefined &&  <button onClick={() => setScreenContent("email")} className={'btn btn-sm' + (screenContent == "email" ? "" : " btn-active")}>Bjud in till evenemanget</button>}
    </div>
        {screenContent == "create" && <IssueEntranceTickets selectedMainEvent={selectedMainEvent} onIssueTickets={() => setScreenContent("print")}/>}
        {screenContent == "print" && <HandleIssuedEntranceTicketBatches  selectedMainEvent={selectedMainEvent} />}
        {(screenContent == "email" && selectedMainEvent != undefined) && <InviteByEmail selectedMainEvent={selectedMainEvent!} emails={[""]}/>}
  
    </div>
  )
}
