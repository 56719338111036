import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import { Club, IssuedEntranceTicket, MainEvent } from "../../model/lottery";
import MainEventHeader from "../MainEventHeader";




export default function OneSidedEntranceTicketContent (props: {mainEvent: MainEvent, club: Club, issuedEntranceTicket: IssuedEntranceTicket }){
    return (
      <div className='flex flex-col items-center h-full justify-between border'>
        <div className="flex flex-col items-center space-y-2 flex-grow justify-end">
      <h3 className='font-bold text-lg mt-3  '>ENTRÉBILJETT</h3>
      <MainEventHeader club={props.club} mainEvent={props.mainEvent} scale={0.8}/>
      </div>
      <div className='flex flex-col items-center space-y-2 mt-5'>
      <h4 className='font-bold text-xs'>{props.issuedEntranceTicket.entranceTicketClass?.name!}</h4>
      <QRCodeSVG  className='rounded-sm'  size={130} value={props.issuedEntranceTicket.entranceTicketId} imageSettings={{excavate : true, src: props.club?.logoUrl!, height: 25, width: 25}}/>
      </div>
      <div className='p-2 mb-4 flex flex-row  w-full justify-evenly'>
        <div className='flex flex-col items-center'>
          <span className='text-xs'>Datum</span>
          <span className='font-bold'>{moment(props.mainEvent.eventTime).format("YYYY-MM-DD")}</span>
          </div>
          <div className='flex flex-col items-center'>
          <span className='text-xs'>Tid</span>
          <span className='font-bold'>{moment(props.mainEvent.eventTime).format("HH:mm")}</span>
          </div>
      </div>
      </div>
  
    )
  }
  
  // nystarts match - 10 fribiljetter, 4 olika, styrelsen kontrakt
