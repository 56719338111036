import { getFunctions, httpsCallable } from "firebase/functions"
import { EntranceTicketClassCount, SendEntranceTicketInviteEmailRequestPayload } from "../model/lottery";


export const sendEmailInvite = async (email: string, mainEventId: string, clubId: string,entranceTicketClassCounts: EntranceTicketClassCount[]) => {
    const callable = httpsCallable(getFunctions(), "sendEntranceTicketInviteEmail");
    return await callable({
        clubId : clubId,
        email : email,
        mainEventId : mainEventId,
        entranceTickets: entranceTicketClassCounts
    } as SendEntranceTicketInviteEmailRequestPayload);
}