import moment from 'moment'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import { Club, IssuedEntranceTicket, IssuedEntranceTicketBatch, MainEvent } from '../../model/lottery'
import MainEventHeader from '../MainEventHeader'
import OneSidedEntranceTicketContent from './OnesidedEntranceTicketContent'
import OneSidedEntranceTicketWrapper from './OneSidedEntranceTicketWrapper'
import TwoSidedEntranceTicketWrapper from './TwoSidedEntranceTicketWrapper'




export default function OneSidedEntranceTicket(props: {mainEvent: MainEvent, club: Club, issuedEntranceTicket: IssuedEntranceTicket }) {
  return (
    <OneSidedEntranceTicketWrapper>
     <OneSidedEntranceTicketContent club={props.club} issuedEntranceTicket={props.issuedEntranceTicket} mainEvent={props.mainEvent}/>


    </OneSidedEntranceTicketWrapper>
  )
}
