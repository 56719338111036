import React from 'react'
import { Club, EntranceTicketClass, IssuedEntranceTicket } from '../../model/lottery'
import TwoSidedEntranceTicketWrapper from './TwoSidedEntranceTicketWrapper'
import { QRCodeSVG } from "qrcode.react";
import { getRGBACssRule } from '../../common/tools/clubColors';
import moment from 'moment';


export default function EntranceTicketBack(props: {club : Club,issuedEntranceTicket: IssuedEntranceTicket,}) {
  return (
    <TwoSidedEntranceTicketWrapper>
        <div className='flex flex-col space-y-3 items-center justify-center h-full'>
            <h3 className='font-bold text-center text-md text-black'>{props.issuedEntranceTicket.entranceTicketClass?.name!}</h3>
            <QRCodeSVG  className='rounded-sm'  size={120} value={props.issuedEntranceTicket.entranceTicketId} imageSettings={{excavate : true, src: props.club?.logoUrl!, height: 25, width: 25}}/>
            <div className='flex flex-row w-full justify-between px-3 py-1'>
              <img width={40} src='/logo.png'/>
            </div>
        </div>
    </TwoSidedEntranceTicketWrapper>
  )
}
