import moment from 'moment';
import React from 'react'
import { AiTwotoneCalendar } from 'react-icons/ai';
import { Club, MainEvent, MainEventType } from '../model/lottery'
import MainEventListContent from './mainEvents/MainEventListContent';

export const getMainEventText = (club: Club,mainEvent:MainEvent) => {
    if(mainEvent.mainEventType == MainEventType.GENERIC){
        return mainEvent.genericName;
    }else{
        return club.name + " vs " + mainEvent.opponentClub?.name!;
    }
}

export const MainEventListItem = (props: {club: Club,mainEvent: MainEvent, isSelected:boolean, onSelected: () => void}) => {
    return <li onClick={props.onSelected} className='list-item'>
       <MainEventListContent club={props.club } mainEvent={props.mainEvent}/>
    </li>;
}

export default function MainEventList(props: {club: Club,mainEvents: MainEvent[], selectedMainEvent?: MainEvent, onMainEventSelected: (mainEvent: MainEvent) => void,title: string}) {
  return (
    <div className='flex flex-col'>

        <h2 className=' text-center text-lg'>{props.title}</h2>
    <ul>
        {props.mainEvents.map(m => <MainEventListItem onSelected={() => props.onMainEventSelected(m)} isSelected={props.selectedMainEvent?.id == m.id} club={props.club} mainEvent={m}/>)}
    </ul>
    </div>
  )
}
