import SignIn from '../../signin/SignIn';
import ModulesMenu from '../menu/ModulesMenu';

import { useClubPortalState } from '../../state/useClubPortalState';

export default function HomePage() {
const{user} =  useClubPortalState();
  if(user == undefined){
    return <SignIn/>
  }else{
    return <div className='flex flex-col items-center w-full h-full justify-center  '><ModulesMenu/></div>;
  }
}
