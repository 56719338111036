import { collection, doc, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { collectionClubs, collectionIssuedEntranceTicketBatches } from "../common/firebase_api/firestore_collections";
import { generateFirebaseId } from "../common/firebase_api/tools";
import { EntranceTicketClassCount, EntranceValidity, IssuedEntranceTicket, IssuedEntranceTicketBatch } from "../model/lottery";



export const createEntranceTicketBatch = async (clubId: string, entranceValidity: EntranceValidity,entranceTicketClassCounts: EntranceTicketClassCount[]) => {

    let issuedEntranceTickets = [] as IssuedEntranceTicket[];
    for(const etc of entranceTicketClassCounts){
        for(let i = 0; i < etc.numberOfTickets; i++){
            issuedEntranceTickets.push({entranceTicketClass : etc.entranceTicketClass, entranceTicketId : generateFirebaseId(), mainEventUsageCount: 0});
        }
    }
    const batch = IssuedEntranceTicketBatch.fromPartial({
        entranceValidity,
        id: generateFirebaseId(),
        issuedEntranceTickets: issuedEntranceTickets,
        issuedTimestamp : new Date()
    });;


    const ref = doc(getFirestore(), collectionClubs.subCollection(clubId, collectionIssuedEntranceTicketBatches).document(batch.id).path);
    await setDoc(ref, IssuedEntranceTicketBatch.toJSON(batch));

}

export const deleteIssuedEntranceTicketBatch = async (clubId: string, batchId: string) => {
    const ref = doc(getFirestore(), collectionClubs.subCollection(clubId, collectionIssuedEntranceTicketBatches).document(batchId).path);
   await updateDoc(ref, {isDeleted: true});
}

export const onIssuedEntranceTicketBatches = async (clubId: string, onData: (issuedEntranceTicketBatches: IssuedEntranceTicketBatch[]) => void) => {

 
    const ref = collection(getFirestore(), collectionClubs.subCollection(clubId, collectionIssuedEntranceTicketBatches).path);
    
    const orderByIssuetTime = orderBy("issuedTimestamp", "desc")
    onSnapshot(query(ref, orderByIssuetTime), (q) => {
        onData(q.docs.map(d => IssuedEntranceTicketBatch.fromJSON(d.data()))); 
    });

}