import React from 'react'
import {IoMdHelpCircle} from "react-icons/io";

export default function HelpModal(props: {title :string, paragraphs: string[]}) {
  return (
    <div className='flex flex-col clubmate-card relative'>
        <IoMdHelpCircle className='text-blue-600 text-lg absolute top-0 left-0'/>
        <h2 className='text-center font-bold text-lg'>{props.title}</h2>

        <div className='flex flex-col space-y-5 items-start'>{props.paragraphs.map(p => <p className=''>{p}</p>)}</div>

    </div>
  )
}
