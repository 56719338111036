import { FaTicketAlt } from "react-icons/fa";
import { GiTicket } from "react-icons/gi";
import { Club, EntranceTicketClassCount, MainEvent } from "../../model/lottery"
import EntranceTicketBack from "./EntranceTicketBack";
import EntranceTicketFront from "./EntranceTicketFront";
import OneSidedEntranceTicket from "./OneSidedEntranceTicket";

const inputToInt = (str: string) => {
    let cleaned =  str.replace("-", "");
    return isNaN(parseInt(cleaned)) ? 0 : parseInt(cleaned);
}



export const EntranceTicketClassCountInput = (props: {entranceTicketClassCount: EntranceTicketClassCount, onCountChanged: (n: number) => void}) => {

    return <article className='flex flex-col space-y-2 items-center  relative'>

        <h4 className='font-bold'>{props.entranceTicketClassCount.entranceTicketClass?.name}</h4>
        <input placeholder='Antal' value={props.entranceTicketClassCount.numberOfTickets == 0 ? "" : props.entranceTicketClassCount.numberOfTickets} onChange={(e) => props.onCountChanged(inputToInt(e.target.value))} className='input input-bordered' type={"number"}/>
    </article>
}