import React from 'react'
import { createEntranceTicketBatch } from '../../api/entranceTicketsApi';
import { Club, EntranceTicketClassCount, MainEvent } from '../../model/lottery';
import { useClubPortalState } from '../../state/useClubPortalState';
import { countEntranceTickets } from '../../tools/entranceTicketTools';

export default function IssueEntranceTicketBatchButton(props: {onIssueEntranceTickets: () => void,mainEvent: MainEvent,club: Club,entranceTicketClassCounts: EntranceTicketClassCount[]}) {


 

  return (
    <button  disabled={countEntranceTickets(props.entranceTicketClassCounts) == 0} onClick={() => {
        if(countEntranceTickets(props.entranceTicketClassCounts)  > 0){
           props.onIssueEntranceTickets();
        }
    }} className={'btn btn-primary '}>Skapa biljetter</button>
  )
}
