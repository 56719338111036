import create from 'zustand'
import { Club, ClubSettings, IssuedEntranceTicket, IssuedEntranceTicketBatch, MainEvent } from '../model/lottery';
import { User } from 'firebase/auth';

export type MessageType = "info" | "warning" | "error";

export interface ClubMateMessage{
    type: MessageType;
    message: string;
}

export interface HelpMessage{
    title: string,
    paragraphs: string[]
}

export interface ClubPortalState{
    showMessage: (message: ClubMateMessage) => void; 
    hideMessage: () => void;

    showHelpModal:(helpMessage: HelpMessage) => void;
    hideHelpModal: () => void;

    message : ClubMateMessage | undefined; 
    helpMessage: HelpMessage | undefined;
    mainEvents?: MainEvent[];
    club?: Club;
    clubSettings?: ClubSettings;
    issuedEntranceTicketBatches?: IssuedEntranceTicketBatch[]; 
    user?: User;

    setMainEvents: (mainEvents: MainEvent[]) => void;
    setClubSettings: (clubSettings: ClubSettings) => void;
    setClub: (club: Club) => void;
    setIssuedEntranceTicketBatches: (club: IssuedEntranceTicketBatch[]) => void;
    setUser: (user: User | undefined) => void;

    

}

export const useClubPortalState = create<ClubPortalState>((set) => ({
    message: undefined,
    helpMessage : undefined,
    showMessage: (message :ClubMateMessage) => set((state) => ({...state, message: message})),
    hideMessage: () => set((state) => ({...state, message : undefined})),
  
    showHelpModal: (message :HelpMessage) => set((state) => ({...state, helpMessage: message})),
    hideHelpModal: () => set((state) => ({...state, helpMessage : undefined})),

    setMainEvents: (v) => set((state) => ({...state, mainEvents : v})),
    setClubSettings: (v) => set((state) => ({...state, clubSettings : v})),
    setClub: (v) => set((state) => ({...state, club : v})),
    setIssuedEntranceTicketBatches: (v) => set((state) => ({...state, issuedEntranceTicketBatches : v})),
    setUser: (v) => set((state) => ({...state, user : v})),


    
}));