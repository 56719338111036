import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { AiFillEdit } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { MdModeEditOutline } from 'react-icons/md';
import { Club, MainEvent } from '../model/lottery';
import MainEventList, { getMainEventText } from './MainEventList';
import MainEventListContent from './mainEvents/MainEventListContent';



export default function MainEventSelectionButton(props: {club: Club,mainEvents: MainEvent[], selectedMainEvent: MainEvent | undefined,onMainEventSelected: (m : MainEvent) => void}) {
  const [showMainEventList, setShowMainEventList] = useState(false);

  return (
    <>
    {showMainEventList && <div className={'modal ' + (showMainEventList ? "modal-open" : "") } >
      <div className=' modal-box'>
      <button onClick={() => {setShowMainEventList(false)}}  className='absolute right-2 top-2 btn btn-sm btn-circle'>x</button>
      <MainEventList selectedMainEvent={props.selectedMainEvent} onMainEventSelected={(m) => {
        props.onMainEventSelected(m);
        setShowMainEventList(false);
      }} title='Välj evenemang' club={props.club} mainEvents={props.mainEvents}/>
      </div>
      </div>}
    { <button onClick={() => setShowMainEventList(true)} className='btn btn-outline flex flex-row items-center relative  '>
        {props.selectedMainEvent != undefined && <div className='flex flex-row space-x-2'>
          <MainEventListContent club={props.club} mainEvent={props.selectedMainEvent}/>
        </div>}
        {props.selectedMainEvent == undefined && <span className='flex flex-row space-x-2'><span className='text-xs font-bold'>Välj evenemang</span><BsChevronDown className='text-sm inline'/></span>}
    </button>}
    </>
  )
}
