import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import { collectionClubs } from "../common/firebase_api/firestore_collections";
import { Club } from "../model/lottery";



export const fetchClubs = async (clubIds: string[]) => {
  return await Promise.all(clubIds.map((id => fetchClub(id))));
}

export async function fetchClub(clubId: string) {
    const firestore = getFirestore();
    const collectionRef = collection(firestore, collectionClubs.path);
    const clubJson = (await getDoc(doc(collectionRef, clubId))).data();
    return Club.fromJSON(clubJson);
  }
  