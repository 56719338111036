export const functionConfirmSupporterTransaction : string = "confirmSupporterTransaction";
export const functionRequestOtp : string = "requestOtp";
export const functionConfirmOtp : string = "confirmOtp";
export const functionCreateCustomTokenWithPhoneNumber = "createCustomTokenWithPhoneNumber";
export const functionToggleSupporterEmailPreference = "toggleSupporterEmailPreference";
export const functionSendSupporterTransactionConfirmation = "sendSupporterTransactionConfirmation";
export const functionCreateFreeTransaction = "createFreeTransaction";
export const functionSendEntranceTicketInviteEmail = "sendEntranceTicketInviteEmail";


export const functionsRegion = "europe-west1"; 