import React from 'react'
import { Club, EntranceTicketClassCount, MainEvent } from '../../model/lottery'
import { EntranceTicketClassCountInput } from './EntranceTicketClassCountInput'

export default function EntranceTicketClassCountInputCarousel(props: {entranceTicketClassCounts: EntranceTicketClassCount[], onChange: (change: EntranceTicketClassCount[]) => void}) {

    const handleChange = (count: number, id: string) => {
        const updateTicket = props.entranceTicketClassCounts!.find(e => e.entranceTicketClass?.id == id)!;
        updateTicket.numberOfTickets = count;
        props.onChange([...props.entranceTicketClassCounts!])
    }

  return (
    <div className='grid grid-cols-2  gap-3 ' >
        {props.entranceTicketClassCounts.map((e) => <div className=''>
            <EntranceTicketClassCountInput entranceTicketClassCount={e} onCountChanged={(n) => {handleChange(n, e.entranceTicketClass!.id)}}/>
        </div>)}
    </div>
  )
}
