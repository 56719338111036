import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { createEntranceTicketBatch } from '../../api/entranceTicketsApi';
import {  ClubSettings, EntranceTicketClass, EntranceTicketClassCount, MainEvent } from '../../model/lottery';
import { useClubPortalState } from '../../state/useClubPortalState'
import { getMainEventText } from '../MainEventList';
import MainEventSelectionButton from '../MainEventSelectionButton'
import { EntranceTicketClassCountInput } from './EntranceTicketClassCountInput';
import EntranceTicketClassCountInputCarousel from './EntranceTicketClassCountInputCarousel';
import IssueEntranceTicketBatchButton from './IssueEntranceTicketBatchButton';




export default function IssueEntranceTickets(props: {selectedMainEvent: MainEvent | undefined,onIssueTickets: () => void}) {
    const {club, clubSettings, mainEvents, issuedEntranceTicketBatches, showMessage} = useClubPortalState();
    const [entranceTicketClassCounts, setEntranceTicketClassCounts] = useState(undefined as EntranceTicketClassCount[] | undefined);
    useEffect(() => {
        if(props.selectedMainEvent != undefined){
            setEntranceTicketClassCounts(createEntranceTickets(props.selectedMainEvent.entranceEvent!.entranceTickets! ))
        }
        
    }, [props.selectedMainEvent])

    const createEntranceTickets = (entranceTicketClasses: EntranceTicketClass[] ) => {
            return entranceTicketClasses.map((e) => {
                return EntranceTicketClassCount.fromPartial({
                    entranceTicketClass : e,
                    numberOfTickets : 0
                })
            })
   
    }
   

    const countTickets = () => {
        if(entranceTicketClassCounts == undefined){
            return 0;
        }
        return entranceTicketClassCounts!.length > 0 ? entranceTicketClassCounts!.map(e => e.numberOfTickets).reduce((a,b) => a+b) : 0
    }
    

  return (
    <div className='flex flex-col items-center w-full h-full space-y-2 justify-center'>

        {(props.selectedMainEvent != undefined && entranceTicketClassCounts != undefined) && 
        <div className='flex flex-col items-center space-y-2'>
            <h3 className='  text-lg'>Välj antal biljetter</h3>
            <EntranceTicketClassCountInputCarousel entranceTicketClassCounts={entranceTicketClassCounts} onChange={(c) =>setEntranceTicketClassCounts(c)}/>
            </div>}
        
        <div className='h-6'/>

        {(props.selectedMainEvent != undefined && entranceTicketClassCounts != undefined) &&
          <div className='flex flex-col items-center fixed right-2 bottom-2 '>
        <div className='flex flex-col items-center'>
            <p className='text-xs font-bold'>Totalt: {countTickets()}st</p>
         </div>
   

        <IssueEntranceTicketBatchButton onIssueEntranceTickets={() => {
             createEntranceTicketBatch(club!.id, {
                start: moment([1996, 2, 1]).toDate(),
                end: moment(new Date()).add(365, "days").toDate(),
                mainEventId : props.selectedMainEvent?.id ?? "",
                maxMainEventUsageCount : 1

             }, entranceTicketClassCounts!).then(() => {                    
                showMessage({message:"Biljetterna är skapade, du kan nu skriva ut dem!", type:"info"});
                props.onIssueTickets();

            }).catch((r) => {
                showMessage({message:"Något gick fel, testa igen!", type:"error"});
            })
        }} club={club!} entranceTicketClassCounts={entranceTicketClassCounts} mainEvent={props.selectedMainEvent}/>
                    <p className='italic text-[0.5rem] text-gray-700'>* Biljetterna gäller endast för evenemanget: {getMainEventText(club!,props.selectedMainEvent!)}</p>

        </div>}
    </div>
  )
}
