
import React, { ReactNode } from 'react'

export default function OneSidedEntranceTicketWrapper(props: {children: ReactNode}) {
    return (
        <article className='bg-white w-[33.3333vw] h-[33.3333vh] '>
            {props.children}
        </article>
      )
}
