import { collection, doc, getDoc, getFirestore } from "firebase/firestore"
import { collectionClubSettings } from "../common/firebase_api/firestore_collections"
import { ClubSettings } from "../model/lottery";


export const fetchClubSettings = async (clubId: string) => {
    
        const firestore = getFirestore();
        const collectionRef = collection(firestore, collectionClubSettings.path);
        const clubJson = (await getDoc(doc(collectionRef, clubId))).data();
        return ClubSettings.fromJSON(clubJson);
      

}