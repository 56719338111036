import { cleanSsn, normalizePhoneNumber } from './inputCleaning';



export function isSsnValid(socialSecurityNumber : string){
     const cleaned = cleanSsn(socialSecurityNumber);
     return /[0-9]{10,12}/.test(cleaned) && (socialSecurityNumber.length  == 10 || socialSecurityNumber.length == 12);
}
export function validateSwedishPhoneNumber(input : string){
     return /^((((0{2}?)|(\+){1})46)|0)7[\d]{8}$/.test(normalizePhoneNumber(input)) || input == "+11223344";
}

export const validateEmail = (email : string) => {
     return (String(email)
       .toLowerCase()
       .match(
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
       )??[]).length > 0;
   };