import React, { ReactNode, useEffect } from 'react'
import { useClubPortalState } from '../state/useClubPortalState'
import { useNavigate } from "react-router-dom";


export default function AuthenticatedRoute(props: {children : ReactNode}) {
  const{user} = useClubPortalState();
  const navigate = useNavigate();
  useEffect(() => {
    if(user == undefined){
      navigate("/");
    }
  }, [user]);
  return <>
  {user == undefined && <>Du loggas ut...</>}
  {user != undefined && props.children}
  </>
  
}
