export class FirestoreCollection{

    constructor(public path : string){
    }
    subCollection(docName : string, collection : FirestoreCollection){
        return new FirestoreCollection(this.path + "/" +  docName  + "/" + collection.path);
    }
    document(docName : string){
        return new FirestoreCollection(this.path + "/" + docName);
    }
}

export const collectionMainEvents = new FirestoreCollection("mainEvents");
export const collectionSupporterTransactions = new FirestoreCollection("supporterTransactions");
export const collectionClubs = new FirestoreCollection("clubs");
export const collectionUnpaidTransactions = new FirestoreCollection("unpaidTransactions");
export const collectionClubSettings = new FirestoreCollection("clubSettings");
export const collectionApp = new FirestoreCollection("app");
export const documentSearchableClubs = collectionApp.document("searchableClubs");
export const documentAllCounties = collectionApp.document("allCounties");
export const documentClubNotifications = collectionApp.document("clubNotifications");
export const collectionSupporters = new FirestoreCollection("supporters");
export const collectionOtpCodes = new FirestoreCollection("otpCodes");
export const collectionInterestedClubs = new FirestoreCollection("interrestedClubs");
export const collectionTransactionResults = new FirestoreCollection("transactionResults");
export const collectionClubUsers = new FirestoreCollection("clubUsers");
export const collectionClubAgreements = new FirestoreCollection("clubAgreements");
export const collectionOpponentClubLogos = new FirestoreCollection("opponentClubLogos");
export const collectionSupporterAppErrors = new FirestoreCollection("supporterAppErrors");
export const collectionWebsiteSettings = new FirestoreCollection("websiteSettings");
export const collectionIssuedEntranceTicketBatches = new FirestoreCollection("issuedEntranceTicketBatches");
export const collectionStreamCommands = new FirestoreCollection("streamCommands");
export const collectionEmails = new FirestoreCollection("emails");