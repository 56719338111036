import {  MainEvent } from '../model/lottery';
import {getFirestore, collection, onSnapshot, orderBy, query, limit, where, QuerySnapshot, getDocs, collectionGroup, doc, getDoc} from "firebase/firestore";
import {collectionClubs, collectionMainEvents, collectionStreamCommands } from "../common/firebase_api/firestore_collections";



const snapshotToMainEvents = (snapshot : QuerySnapshot) => {
  return snapshot.docs.map((d) => {
    return MainEvent.fromJSON(d.data());
  }) ;
}

export const fetchMainEvents = async (clubId: string) => {
  const firestore = getFirestore();
  const ref = collection(firestore, collectionClubs.subCollection(clubId, collectionMainEvents).path);
  const mostRecent = query(ref,orderBy("eventTime", "asc"));
  const q = (await getDocs(mostRecent));
  return q.docs.map(d => MainEvent.fromJSON(d.data()));
}


export const fetchActiveMainEvents = (clubId: string, onMainEvents : (mainEvents: MainEvent[]) => void) => {
  const firestore = getFirestore();
  const ref = collection(firestore, collectionClubs.subCollection(clubId, collectionMainEvents).path);
  const hasStarted = where("startTime", "<=", new Date().toISOString());
  const hasNotEnded = where("hasEnded", "==", false);
  const orderByTime = orderBy("startTime", "asc");
  return onSnapshot(query(ref, hasStarted, hasNotEnded, orderByTime), {next : (snapshot) => {
     onMainEvents(snapshotToMainEvents(snapshot));
  }});
}

export const onMostCurrentActiveMainEvent =  (clubId: string, onMainEvent: (mainEvent: MainEvent) => void) => {
  const firestore = getFirestore();
  const ref = collection(firestore, collectionClubs.subCollection(clubId, collectionMainEvents).path);
  const hasStarted = where("startTime", "<=", new Date().toISOString());
  const hasNotEnded = where("hasEnded", "==", false);
  const orderByTime = orderBy("startTime", "asc");
  return onSnapshot(query(ref, hasStarted, hasNotEnded, orderByTime, limit(1)), (d) => onMainEvent(MainEvent.fromJSON(d.docs[0].data())));
}


export const fetchCompletedMainEvents =  (clubId: string, onMainEvents: (mainEvents: MainEvent[]) => void,maxCount: number= 15) => {
  const firestore = getFirestore();
  const ref = collection(firestore, collectionClubs.subCollection(clubId, collectionMainEvents).path);
  const mostRecent = query(ref,orderBy("endTime", "desc"), limit(maxCount));
  return onSnapshot(mostRecent, { next : (snapshot) => {
      onMainEvents(snapshotToMainEvents(snapshot));

  } });
}

export const fetchUpcomingMainEvents = (clubId: string, onMainEvents : (mainEvents: MainEvent[]) => void) => {
  const firestore = getFirestore();
  const ref = collection(firestore, collectionClubs.subCollection(clubId, collectionMainEvents).path);
  const isUpcoming = where("startTime", ">", new Date().toISOString());
  const orderByTime = orderBy("startTime", "asc");
  return onSnapshot(query(ref, isUpcoming, orderByTime), {next : (snapshot) => {
     onMainEvents(snapshotToMainEvents(snapshot));
  }});
}


export const fetchMainEventsByIds = async (mainEventIds: string[]) => {
  if(mainEventIds.length === 0){
    return [];
  }
  if(mainEventIds.length > 10){
    console.error("Main event ids > 10 all mainEvents wont be fetched, slicing index 0,...,9");
  }
  let thresholdedMainEventIds = mainEventIds.slice(0,10);
  const firestore = getFirestore();
  const ref =  collectionGroup(firestore, collectionMainEvents.path);
  
  const q = query(ref, where("id", "in", thresholdedMainEventIds));
  const querySnapshot = await getDocs(q);
  return snapshotToMainEvents(querySnapshot);
}

export const onMainEvent = (clubId: string, mainEventId: string, onData: (mainEvent: MainEvent | undefined) => void) => {
 
  const firestore = getFirestore();
  const p = doc(firestore, collectionClubs.subCollection(clubId, collectionMainEvents).document(mainEventId).path);
  onSnapshot(p, (s) => {
    if(s.exists()){
      onData(MainEvent.fromJSON(s.data()));
    }else{
      onData(undefined);
    }
  });

}

export const onMainEventsByIds =  (mainEventIds: string[], onMainEvents : (mainEvents: MainEvent[]) => void) => {
  if(mainEventIds.length === 0){
    onMainEvents([]);
    return;
  }
  if(mainEventIds.length > 10){
    console.error("Main event ids > 10 all mainEvents wont be fetched, slicing index 0,...,9");
  }
  let thresholdedMainEventIds = mainEventIds.slice(0,10);
  const firestore = getFirestore();
  const ref =  collectionGroup(firestore, collectionMainEvents.path);
  const q = query(ref, where("id", "in", thresholdedMainEventIds));
  return onSnapshot(q, (snapshot) => {
    onMainEvents(snapshotToMainEvents(snapshot))
  });
}


// export const fetchPreviousMainEvent =  async (clubId : string) => {
//   const ms = await fetchCompletedMainEvents(clubId, 1);
//   if(ms.length == 0){
//     return undefined;
//   }
//   if(ms.length > 1){
//     throw Error("Something wrong with query");
//   }
//   return ms;
// }

export const fetchNextMainEvent = async (clubId: string) => {
  const firestore = getFirestore();
  const ref = collection(firestore, collectionClubs.subCollection(clubId, collectionMainEvents).path);
  const hasStarted = where("startTime", ">", new Date().toISOString());

  const orderByTime = orderBy("starTime", "asc");
  const first = query(ref, limit(1), hasStarted, orderByTime);
  const s = await getDocs(first);
  const ms = snapshotToMainEvents(s);
  if(ms.length == 0){
    return undefined;
  }
  if(ms.length > 1){
    throw Error("Something wrong with query");
  }
  return ms;
}
