import React, { ReactNode } from 'react'
import { useClubPortalState } from '../state/useClubPortalState';
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from 'firebase/auth';


export default function Layout(props: {children: ReactNode}) {
    const {user } = useClubPortalState();
    const navigate = useNavigate();

  return (
    <main className=' w-screen h-screen overflow-x-hidden '>
         <div className="flex flex-row justify-between items-center p-8">
      <header className="flex-col flex space-y-2 hover:cursor-pointer" onClick={() => {
        navigate("/");
      }} >
        <div className='flex flex-row items-center'>
        <img className='' width={200} src='/logo.png'/>
        </div>
      </header>
      {user!= undefined && <button className='btn ' onClick={() => {
        signOut(getAuth());
      }}>Logga ut</button>}
    </div>
    {props.children}
    </main>
  )
}
