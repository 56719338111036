import { User } from "firebase/auth";
import { ClubUserType } from "../model/lottery";


const clubUserTypeFromString = (str: string) => {
    switch(str){
        case "ADMIN":
            return ClubUserType.ADMIN;
        case "USER":
            return ClubUserType.USER;
            case "ENTRANCE":
                return ClubUserType.ENTRANCE;
        default:
            throw Error();
    }
}

export const parseClubRoles = async (user:  User) => {
   const token =  await  user.getIdTokenResult();
   let clubRoles : {[clubId: string] : ClubUserType} = {};
   for(const clubId of Object.keys(token.claims.clubRoles as {[clubId: string] : string})){

    clubRoles[clubId] = clubUserTypeFromString((token.claims.clubRoles as {[clubId: string] : string})[clubId]! as string);
   } 
   return clubRoles;
}