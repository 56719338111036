import React from 'react'
import { Club, MainEvent, MainEventType } from '../model/lottery'

export default function MainEventHeader(props: {scale: number,mainEvent: MainEvent, club: Club}) {

    if(props.mainEvent.mainEventType == MainEventType.GENERIC){
        return <div className='flex flex-col items-center'>
            <img width={120 * props.scale} alt={props.mainEvent.genericName} src={props.mainEvent.genericLogoUrl}/>
            <span className='text-xs font-bold'>{props.mainEvent.genericName}</span>
        </div>
    }else if(props.mainEvent.mainEventType == MainEventType.GAME){
        return (
            <div className='flex flex-row items-center space-x-3'>
                {!["qFAlpRAyxaycotBQMrlp", "kXv56jBXbDQn2t17Km8n"].includes(props.mainEvent.id) && <img width={80 * props.scale} alt={props.club.name!} src={props.club.logoUrl}/>}
                {["qFAlpRAyxaycotBQMrlp", "kXv56jBXbDQn2t17Km8n"].includes(props.mainEvent.id) && <img width={80 *1.2} alt={"Finland"} src={"https://firebasestorage.googleapis.com/v0/b/clubmate-app-data/o/extra_opponent_logos%2Ffinland.png?alt=media&token=fa25eb82-a5cf-4b67-b78e-595ca1d3cd42&_gl=1*aim24a*_ga*MTU3MTU1NzAyOC4xNjU2NDUzNDcy*_ga_CW55HF8NVT*MTY5OTI4MjE2MS40MTQuMS4xNjk5MjgzNTM1LjExLjAuMA.."}/>}

                <span className='text-lg font-bold'>VS</span>
                <img width={80 * props.scale} alt={props.mainEvent.opponentClub?.name!} src={props.mainEvent.opponentClub?.logoUrl!}/>
            </div>
          )
    }else{
        throw Error();
    }

 
}
