import React, { useState } from 'react'
import { Club, ClubSettings, EntranceTicketClass, EntranceTicketClassCount, IssuedEntranceTicket, IssuedEntranceTicketBatch, MainEvent } from '../../model/lottery'
import EntranceTicketFront from './EntranceTicketFront'
import EntranceTicketBack from './EntranceTicketBack'
import { EntranceTicketDesignOptions } from './EntranceTicketDesignOptions'
import MainEventSelectionButton from '../MainEventSelectionButton'
import moment from 'moment'
import { useClubPortalState } from '../../state/useClubPortalState'
import PrintEntranceTicketsButton from './PrintEntranceTicketsButton'
import { createEntranceTicketBatch, deleteIssuedEntranceTicketBatch } from '../../api/entranceTicketsApi'
import IssueEntranceTicketBatchButton from './IssueEntranceTicketBatchButton'
import MainEventHeader from '../MainEventHeader'
import {MdDeleteForever} from "react-icons/md";
import OneSidedEntranceTicketContent from './OnesidedEntranceTicketContent'


const IssuedEntranceTicketBatchListItem = (props: {isSelected: boolean, onDelete: () => void, onClick: () => void,issuedEntranceTicketBatch: IssuedEntranceTicketBatch, mainEvent: MainEvent, club: Club}) => {
  return <li className='flex flex-row w-full justify-between bg-gray-300 p-3 my-2 rounded-lg'>
     {props.mainEvent != undefined &&  <div className='flex flex-col items-center'>
      <span className='text-xs'>Evenemang</span>
      <MainEventHeader scale={0.4} club={props.club} mainEvent={props.mainEvent} />
      </div>}
      <div className='flex flex-col items-center'>
        <span className='text-xs'>Skapad</span>
        <div className='flex flex-row items-center space-x-2'>
        <span className=' font-bold text-[0.5rem]'>{moment(props.issuedEntranceTicketBatch.issuedTimestamp).format("YYYY-MM-DD ")}</span>
        <span className=' text-[0.5rem]'>{moment(props.issuedEntranceTicketBatch.issuedTimestamp).format("HH:mm")}</span>
        
        </div>
      </div>
      <div className='flex flex-col items-center'>
        <span className='text-xs'>Antalt</span>
        <span className='font-bold'>{props.issuedEntranceTicketBatch.issuedEntranceTickets.length}st</span>
      </div>
      <div className='flex flex-row space-x-2 items-center'>
      <button onClick={props.onClick} className={'btn ' + (props.isSelected ? "btn-success": "btn-outline")}>{props.isSelected ? "Vald" : "Välj"}</button>
      <button className='btn btn-square' onClick={() => {
        props.onDelete()
      }} > <MdDeleteForever className='text-2xl text-error'/>
      </button>
      </div>

  </li>
}

const exampleEntranceTicket = IssuedEntranceTicket.fromPartial({
  entranceTicketClass : EntranceTicketClass.fromPartial({
    name: "Exempel"
  }),
  entranceTicketId: "CLUBMATE_ROCKS"
})


export default function HandleIssuedEntranceTicketBatches(props: {selectedMainEvent? : MainEvent}) {
    const {issuedEntranceTicketBatches, mainEvents, club, showMessage} =useClubPortalState();
    const [designOptions, setDesignOptions] = useState<EntranceTicketDesignOptions>({
      oneSided : true
    });
    const [selectedBatch, setSelectedBatch] = useState(undefined as undefined | IssuedEntranceTicketBatch);

    const [deleteSelectedBatch, setDeleteSelectedBatch] = useState(undefined as undefined | IssuedEntranceTicketBatch);

    const handleDelete = (batch :IssuedEntranceTicketBatch) => {
      deleteIssuedEntranceTicketBatch(club?.id!, batch.id).then(() => {
        showMessage({message : "Biljetterna har raderats", type : "info"})
      }).catch(c => {
        showMessage({message : "Något gick fel försök igen", type : "error"})
      }).finally(() => {
        setDeleteSelectedBatch(undefined);
      });
    }
    const filterTickets = (iet: IssuedEntranceTicketBatch) => ((props.selectedMainEvent == undefined || props.selectedMainEvent.id == iet.entranceValidity?.mainEventId) && !iet.isDeleted)

    return <section className='flex flex-col items-center space-y-5'>
      {deleteSelectedBatch != undefined && <div className='modal modal-open '>
        
        <div className='modal-box space-y-5'>
          <h3 className='font-bold'>Säker på att du bill radera?</h3>
          <p className='text-xs'>Om du raderar biljetterna kommer de som ej blivit skannade bli ogiltiga</p>
          <div className='flex flex-row space-x-2 justify-end'>
          <button onClick={() => handleDelete(deleteSelectedBatch)} className='btn btn-outline '>Ja</button>
          <button onClick={() => setDeleteSelectedBatch(undefined)} className='btn'>avbryt</button>
          </div>
        </div>
        </div>}
    <div className='flex flex-row items-center space-x-5'>
      <div className='flex flex-col items-center'>
      <ul className='overflow-y-auto w-[800px] h-[400px] bg-white '>
         {issuedEntranceTicketBatches?.filter(filterTickets).length == 0 && <p className='text-xs text-center'>{props.selectedMainEvent == undefined ? "Välj ett evenemang för att kunna skriva ut biljetter" : "Du måste skapa biljetter innan du kan skriva ut dem"}</p>}
         {issuedEntranceTicketBatches?.filter(filterTickets)!.map((b) => <IssuedEntranceTicketBatchListItem onDelete={() => setDeleteSelectedBatch(b)} onClick={() => {setSelectedBatch(b)}} isSelected={selectedBatch?.id == b.id} club={club!} issuedEntranceTicketBatch={b} mainEvent={mainEvents!.find(m => m.id == b.entranceValidity?.mainEventId)!}/>)}
      </ul>
      </div>

      {selectedBatch != undefined &&   
      <div className='flex flex-col items-center'>

      
      <div className='flex flex-col justify-center items-center w-full'>
      <EntranceTicketDesignOptions onDesignOptionChanged={ (d) => {setDesignOptions(d)}} designOptions={designOptions}/>


      {designOptions.oneSided && 
      
      <div className='w-[7cm] h-[9.9cm] border'>
      <OneSidedEntranceTicketContent
       mainEvent={mainEvents?.find(m  => m.id ==selectedBatch?.entranceValidity?.mainEventId)!} club={club!} issuedEntranceTicket={exampleEntranceTicket}/>
       </div>
      }

{!designOptions.oneSided && 
<>
      <EntranceTicketBack club={club!} issuedEntranceTicket={exampleEntranceTicket} />
      <div className='h-2'/>
      <EntranceTicketFront  club={club!} mainEvent={mainEvents?.find(m  => m.id ==selectedBatch?.entranceValidity?.mainEventId)!} />
      </>
      }
      </div>
      </div>}
     {selectedBatch != undefined &&  <div className='fixed right-10 bottom-2'>   <PrintEntranceTicketsButton entranceTicketDesignOptions={designOptions} club={club!} issuedEntranceTicketBatch={selectedBatch!} mainEvent={mainEvents?.find(m => m.id == selectedBatch?.entranceValidity?.mainEventId!)!}/></div>}
    </div>

    </section>


}
