import moment from 'moment'
import React from 'react'
import { Club, MainEvent, SupporterTransaction } from '../../model/lottery'
import MainEventHeader from '../MainEventHeader'
import TwoSidedEntranceTicketWrapper from './TwoSidedEntranceTicketWrapper'




export default function EntranceTicketFront(props: { club: Club, mainEvent: MainEvent}) {
  return (
    <TwoSidedEntranceTicketWrapper>
      
      <div className='flex flex-col  h-full items-center justify-between'>

      <span className='font-bold text-black text-2xl'>ENTRÉBILJETT</span>
      <MainEventHeader  scale={1} club={props.club} mainEvent={props.mainEvent!}/>
          
          <div className='p-2 flex flex-row  w-full justify-between'>
          <div className='flex flex-col items-center'>
            <span className='text-xs'>Datum</span>
            <span className='font-bold'>{moment(props.mainEvent.eventTime).format("YYYY-MM-DD")}</span>
            </div>
            <div className='flex flex-col items-center'>
            <span className='text-xs'>Tid</span>
            <span className='font-bold'>{moment(props.mainEvent.eventTime).format("HH:mm")}</span>
            </div>
       
          </div>
      </div>
    </TwoSidedEntranceTicketWrapper>
  )
}
