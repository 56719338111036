import moment from 'moment'
import React from 'react'
import { AiTwotoneCalendar } from 'react-icons/ai'
import { Club, MainEvent } from '../../model/lottery'
import MainEventHeader from '../MainEventHeader'
import { getMainEventText } from '../MainEventList'

export default function MainEventListContent(props: {mainEvent: MainEvent, club: Club}) {
  return <div className='flex flex-row  items-center justify-between w-full space-x-4'>
        <div className='flex flex-col items-start space-y-1'>
        <span className='text-sm'>{getMainEventText(props.club, props.mainEvent,)}</span>
        <div className='flex space-x-2 items-center'>
            <AiTwotoneCalendar className=''/>
        <span className='font-bold text-xs'>{moment(props.mainEvent.eventTime).format("YYYY-MM-DD ")}</span>
        <span className='text-[0.5rem] '>{moment(props.mainEvent.eventTime).format("HH:mm")}</span>
         </div>
        </div>
        <MainEventHeader club={props.club} mainEvent={props.mainEvent} scale={0.4}/> 
    </div>
  
}
