import React from 'react'
import { generateFirebaseId } from '../../common/firebase_api/tools'
import { Club, IssuedEntranceTicket, MainEvent } from '../../model/lottery'
import EntranceTicketBack from './EntranceTicketBack'
import OneSidedEntranceTicket from './OneSidedEntranceTicket'

export default function OneSidedEntranceTicketA4Page(props: {club: Club, issuedEntranceTickets: IssuedEntranceTicket[], mainEvent: MainEvent}) {
  return (
    <div className='grid grid-cols-3 '>
       {props.issuedEntranceTickets.map(t => <OneSidedEntranceTicket mainEvent={props.mainEvent}   club={props.club} issuedEntranceTicket={t}/>)}
    </div>
  )
}


