import React, { useRef } from 'react'
import ReactToPrint from "react-to-print";
import { getBackgroundWithContrastedTextStyle } from '../../common/tools/clubColors';
import {BsFillPrinterFill} from "react-icons/bs";
import { Club, EntranceTicketClassCount, IssuedEntranceTicketBatch, MainEvent } from '../../model/lottery';
import EntranceTicketA4FrontPage from './EntranceTicketA4FrontPage';
import EntranceTicketBackA4Page from './EntranceTicketBackA4Page';
import { EntranceTicketDesignOptions } from './EntranceTicketDesignOptions';
import OneSidedEntranceTicketA4Page from './OneSidedEntranceTicketA4Page';
import TwoSidedEntranceTicketA4Pages from './TwoSidedEntranceTicketA4Pages';


export default function PrintEntranceTicketsButton(props: {mainEvent: MainEvent,club: Club, issuedEntranceTicketBatch: IssuedEntranceTicketBatch, entranceTicketDesignOptions: EntranceTicketDesignOptions }) {
  const ref = useRef(null);
  return<> <ReactToPrint trigger={() => {
    return <button  className='btn btn-lg animate-pulse btn-primary flex flex-col items-center space-y-1 relative' 
  >
    <BsFillPrinterFill className='text-lg' />
    <span>Skriv ut </span>
  </button>

   }} content={() => ref.current} pageStyle={`
   @media print {
    @page {
      margin: 0; 
    }
  }`} />
  
   <div className='hidden'>
    <div className='w-[100vw] h-[100vh]' ref={ref}>
      {!props.entranceTicketDesignOptions.oneSided &&
      <>
      <TwoSidedEntranceTicketA4Pages club={props.club} issuedEntranceTickets={props.issuedEntranceTicketBatch.issuedEntranceTickets} mainEvent={props.mainEvent}/>
      </> }
      {props.entranceTicketDesignOptions.oneSided &&
      <>
       <OneSidedEntranceTicketA4Page mainEvent={props.mainEvent} club={props.club} issuedEntranceTickets={props.issuedEntranceTicketBatch.issuedEntranceTickets}/>
      </> }
       

    </div>
  </div>
  </>;
}
