/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "";

export enum ClubCategory {
  FOOTBALL = "FOOTBALL",
  HOCKEY = "HOCKEY",
  FLOOR_BALL = "FLOOR_BALL",
  HAND_BALL = "HAND_BALL",
  HORSE_RIDING = "HORSE_RIDING",
  BANDY = "BANDY",
  BADMINTON = "BADMINTON",
  BASKETBALL = "BASKETBALL",
  SPEEDWAY = "SPEEDWAY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function clubCategoryFromJSON(object: any): ClubCategory {
  switch (object) {
    case 0:
    case "FOOTBALL":
      return ClubCategory.FOOTBALL;
    case 1:
    case "HOCKEY":
      return ClubCategory.HOCKEY;
    case 2:
    case "FLOOR_BALL":
      return ClubCategory.FLOOR_BALL;
    case 3:
    case "HAND_BALL":
      return ClubCategory.HAND_BALL;
    case 4:
    case "HORSE_RIDING":
      return ClubCategory.HORSE_RIDING;
    case 5:
    case "BANDY":
      return ClubCategory.BANDY;
    case 6:
    case "BADMINTON":
      return ClubCategory.BADMINTON;
    case 7:
    case "BASKETBALL":
      return ClubCategory.BASKETBALL;
    case 8:
    case "SPEEDWAY":
      return ClubCategory.SPEEDWAY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClubCategory.UNRECOGNIZED;
  }
}

export function clubCategoryToJSON(object: ClubCategory): string {
  switch (object) {
    case ClubCategory.FOOTBALL:
      return "FOOTBALL";
    case ClubCategory.HOCKEY:
      return "HOCKEY";
    case ClubCategory.FLOOR_BALL:
      return "FLOOR_BALL";
    case ClubCategory.HAND_BALL:
      return "HAND_BALL";
    case ClubCategory.HORSE_RIDING:
      return "HORSE_RIDING";
    case ClubCategory.BANDY:
      return "BANDY";
    case ClubCategory.BADMINTON:
      return "BADMINTON";
    case ClubCategory.BASKETBALL:
      return "BASKETBALL";
    case ClubCategory.SPEEDWAY:
      return "SPEEDWAY";
    case ClubCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function clubCategoryToNumber(object: ClubCategory): number {
  switch (object) {
    case ClubCategory.FOOTBALL:
      return 0;
    case ClubCategory.HOCKEY:
      return 1;
    case ClubCategory.FLOOR_BALL:
      return 2;
    case ClubCategory.HAND_BALL:
      return 3;
    case ClubCategory.HORSE_RIDING:
      return 4;
    case ClubCategory.BANDY:
      return 5;
    case ClubCategory.BADMINTON:
      return 6;
    case ClubCategory.BASKETBALL:
      return 7;
    case ClubCategory.SPEEDWAY:
      return 8;
    case ClubCategory.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum LotteryTicketTokenType {
  DIGIT = "DIGIT",
  LETTER = "LETTER",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function lotteryTicketTokenTypeFromJSON(object: any): LotteryTicketTokenType {
  switch (object) {
    case 0:
    case "DIGIT":
      return LotteryTicketTokenType.DIGIT;
    case 1:
    case "LETTER":
      return LotteryTicketTokenType.LETTER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LotteryTicketTokenType.UNRECOGNIZED;
  }
}

export function lotteryTicketTokenTypeToJSON(object: LotteryTicketTokenType): string {
  switch (object) {
    case LotteryTicketTokenType.DIGIT:
      return "DIGIT";
    case LotteryTicketTokenType.LETTER:
      return "LETTER";
    case LotteryTicketTokenType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function lotteryTicketTokenTypeToNumber(object: LotteryTicketTokenType): number {
  switch (object) {
    case LotteryTicketTokenType.DIGIT:
      return 0;
    case LotteryTicketTokenType.LETTER:
      return 1;
    case LotteryTicketTokenType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum EntranceAgreementType {
  ENTRANCE_BASIC = "ENTRANCE_BASIC",
  ENTRANCE_PREMIUM = "ENTRANCE_PREMIUM",
  ENTRANCE_NONE = "ENTRANCE_NONE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function entranceAgreementTypeFromJSON(object: any): EntranceAgreementType {
  switch (object) {
    case 0:
    case "ENTRANCE_BASIC":
      return EntranceAgreementType.ENTRANCE_BASIC;
    case 1:
    case "ENTRANCE_PREMIUM":
      return EntranceAgreementType.ENTRANCE_PREMIUM;
    case 3:
    case "ENTRANCE_NONE":
      return EntranceAgreementType.ENTRANCE_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EntranceAgreementType.UNRECOGNIZED;
  }
}

export function entranceAgreementTypeToJSON(object: EntranceAgreementType): string {
  switch (object) {
    case EntranceAgreementType.ENTRANCE_BASIC:
      return "ENTRANCE_BASIC";
    case EntranceAgreementType.ENTRANCE_PREMIUM:
      return "ENTRANCE_PREMIUM";
    case EntranceAgreementType.ENTRANCE_NONE:
      return "ENTRANCE_NONE";
    case EntranceAgreementType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function entranceAgreementTypeToNumber(object: EntranceAgreementType): number {
  switch (object) {
    case EntranceAgreementType.ENTRANCE_BASIC:
      return 0;
    case EntranceAgreementType.ENTRANCE_PREMIUM:
      return 1;
    case EntranceAgreementType.ENTRANCE_NONE:
      return 3;
    case EntranceAgreementType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum LotteryAgreementType {
  LOTTERY_BASIC = "LOTTERY_BASIC",
  LOTTERY_NONE = "LOTTERY_NONE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function lotteryAgreementTypeFromJSON(object: any): LotteryAgreementType {
  switch (object) {
    case 0:
    case "LOTTERY_BASIC":
      return LotteryAgreementType.LOTTERY_BASIC;
    case 1:
    case "LOTTERY_NONE":
      return LotteryAgreementType.LOTTERY_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LotteryAgreementType.UNRECOGNIZED;
  }
}

export function lotteryAgreementTypeToJSON(object: LotteryAgreementType): string {
  switch (object) {
    case LotteryAgreementType.LOTTERY_BASIC:
      return "LOTTERY_BASIC";
    case LotteryAgreementType.LOTTERY_NONE:
      return "LOTTERY_NONE";
    case LotteryAgreementType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function lotteryAgreementTypeToNumber(object: LotteryAgreementType): number {
  switch (object) {
    case LotteryAgreementType.LOTTERY_BASIC:
      return 0;
    case LotteryAgreementType.LOTTERY_NONE:
      return 1;
    case LotteryAgreementType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum ClubUserType {
  ADMIN = "ADMIN",
  USER = "USER",
  ENTRANCE = "ENTRANCE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function clubUserTypeFromJSON(object: any): ClubUserType {
  switch (object) {
    case 0:
    case "ADMIN":
      return ClubUserType.ADMIN;
    case 1:
    case "USER":
      return ClubUserType.USER;
    case 2:
    case "ENTRANCE":
      return ClubUserType.ENTRANCE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClubUserType.UNRECOGNIZED;
  }
}

export function clubUserTypeToJSON(object: ClubUserType): string {
  switch (object) {
    case ClubUserType.ADMIN:
      return "ADMIN";
    case ClubUserType.USER:
      return "USER";
    case ClubUserType.ENTRANCE:
      return "ENTRANCE";
    case ClubUserType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function clubUserTypeToNumber(object: ClubUserType): number {
  switch (object) {
    case ClubUserType.ADMIN:
      return 0;
    case ClubUserType.USER:
      return 1;
    case ClubUserType.ENTRANCE:
      return 2;
    case ClubUserType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum PaymentPayee {
  CLUBMATE = "CLUBMATE",
  CLUB = "CLUB",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function paymentPayeeFromJSON(object: any): PaymentPayee {
  switch (object) {
    case 0:
    case "CLUBMATE":
      return PaymentPayee.CLUBMATE;
    case 1:
    case "CLUB":
      return PaymentPayee.CLUB;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PaymentPayee.UNRECOGNIZED;
  }
}

export function paymentPayeeToJSON(object: PaymentPayee): string {
  switch (object) {
    case PaymentPayee.CLUBMATE:
      return "CLUBMATE";
    case PaymentPayee.CLUB:
      return "CLUB";
    case PaymentPayee.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function paymentPayeeToNumber(object: PaymentPayee): number {
  switch (object) {
    case PaymentPayee.CLUBMATE:
      return 0;
    case PaymentPayee.CLUB:
      return 1;
    case PaymentPayee.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum TransactionSource {
  SUPPORTER_WEBSITE = "SUPPORTER_WEBSITE",
  CLUB_APP = "CLUB_APP",
  CLUB_PORTAL = "CLUB_PORTAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function transactionSourceFromJSON(object: any): TransactionSource {
  switch (object) {
    case 0:
    case "SUPPORTER_WEBSITE":
      return TransactionSource.SUPPORTER_WEBSITE;
    case 1:
    case "CLUB_APP":
      return TransactionSource.CLUB_APP;
    case 2:
    case "CLUB_PORTAL":
      return TransactionSource.CLUB_PORTAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TransactionSource.UNRECOGNIZED;
  }
}

export function transactionSourceToJSON(object: TransactionSource): string {
  switch (object) {
    case TransactionSource.SUPPORTER_WEBSITE:
      return "SUPPORTER_WEBSITE";
    case TransactionSource.CLUB_APP:
      return "CLUB_APP";
    case TransactionSource.CLUB_PORTAL:
      return "CLUB_PORTAL";
    case TransactionSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function transactionSourceToNumber(object: TransactionSource): number {
  switch (object) {
    case TransactionSource.SUPPORTER_WEBSITE:
      return 0;
    case TransactionSource.CLUB_APP:
      return 1;
    case TransactionSource.CLUB_PORTAL:
      return 2;
    case TransactionSource.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum PaymentOption {
  UNKNOWN = "UNKNOWN",
  SWISH = "SWISH",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function paymentOptionFromJSON(object: any): PaymentOption {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return PaymentOption.UNKNOWN;
    case 1:
    case "SWISH":
      return PaymentOption.SWISH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PaymentOption.UNRECOGNIZED;
  }
}

export function paymentOptionToJSON(object: PaymentOption): string {
  switch (object) {
    case PaymentOption.UNKNOWN:
      return "UNKNOWN";
    case PaymentOption.SWISH:
      return "SWISH";
    case PaymentOption.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function paymentOptionToNumber(object: PaymentOption): number {
  switch (object) {
    case PaymentOption.UNKNOWN:
      return 0;
    case PaymentOption.SWISH:
      return 1;
    case PaymentOption.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum ClubImageType {
  SPONSOR = "SPONSOR",
  PRIZE = "PRIZE",
  COUPON_IMAGE = "COUPON_IMAGE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function clubImageTypeFromJSON(object: any): ClubImageType {
  switch (object) {
    case 0:
    case "SPONSOR":
      return ClubImageType.SPONSOR;
    case 1:
    case "PRIZE":
      return ClubImageType.PRIZE;
    case 3:
    case "COUPON_IMAGE":
      return ClubImageType.COUPON_IMAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClubImageType.UNRECOGNIZED;
  }
}

export function clubImageTypeToJSON(object: ClubImageType): string {
  switch (object) {
    case ClubImageType.SPONSOR:
      return "SPONSOR";
    case ClubImageType.PRIZE:
      return "PRIZE";
    case ClubImageType.COUPON_IMAGE:
      return "COUPON_IMAGE";
    case ClubImageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function clubImageTypeToNumber(object: ClubImageType): number {
  switch (object) {
    case ClubImageType.SPONSOR:
      return 0;
    case ClubImageType.PRIZE:
      return 1;
    case ClubImageType.COUPON_IMAGE:
      return 3;
    case ClubImageType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum StakeLimitPolicy {
  STOP_SELLING = "STOP_SELLING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function stakeLimitPolicyFromJSON(object: any): StakeLimitPolicy {
  switch (object) {
    case 0:
    case "STOP_SELLING":
      return StakeLimitPolicy.STOP_SELLING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StakeLimitPolicy.UNRECOGNIZED;
  }
}

export function stakeLimitPolicyToJSON(object: StakeLimitPolicy): string {
  switch (object) {
    case StakeLimitPolicy.STOP_SELLING:
      return "STOP_SELLING";
    case StakeLimitPolicy.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function stakeLimitPolicyToNumber(object: StakeLimitPolicy): number {
  switch (object) {
    case StakeLimitPolicy.STOP_SELLING:
      return 0;
    case StakeLimitPolicy.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The channel that results in a transaction */
export enum MarketingChannel {
  NONE = "NONE",
  CHANNEL_FACEBOOK = "CHANNEL_FACEBOOK",
  CHANNEL_INSTAGRAM = "CHANNEL_INSTAGRAM",
  CHANNEL_EMAIL_SUBSCRIPTION = "CHANNEL_EMAIL_SUBSCRIPTION",
  CHANNEL_EMAIL_MARKETING = "CHANNEL_EMAIL_MARKETING",
  CHANNEL_STREAM = "CHANNEL_STREAM",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function marketingChannelFromJSON(object: any): MarketingChannel {
  switch (object) {
    case 0:
    case "NONE":
      return MarketingChannel.NONE;
    case 1:
    case "CHANNEL_FACEBOOK":
      return MarketingChannel.CHANNEL_FACEBOOK;
    case 2:
    case "CHANNEL_INSTAGRAM":
      return MarketingChannel.CHANNEL_INSTAGRAM;
    case 3:
    case "CHANNEL_EMAIL_SUBSCRIPTION":
      return MarketingChannel.CHANNEL_EMAIL_SUBSCRIPTION;
    case 4:
    case "CHANNEL_EMAIL_MARKETING":
      return MarketingChannel.CHANNEL_EMAIL_MARKETING;
    case 5:
    case "CHANNEL_STREAM":
      return MarketingChannel.CHANNEL_STREAM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MarketingChannel.UNRECOGNIZED;
  }
}

export function marketingChannelToJSON(object: MarketingChannel): string {
  switch (object) {
    case MarketingChannel.NONE:
      return "NONE";
    case MarketingChannel.CHANNEL_FACEBOOK:
      return "CHANNEL_FACEBOOK";
    case MarketingChannel.CHANNEL_INSTAGRAM:
      return "CHANNEL_INSTAGRAM";
    case MarketingChannel.CHANNEL_EMAIL_SUBSCRIPTION:
      return "CHANNEL_EMAIL_SUBSCRIPTION";
    case MarketingChannel.CHANNEL_EMAIL_MARKETING:
      return "CHANNEL_EMAIL_MARKETING";
    case MarketingChannel.CHANNEL_STREAM:
      return "CHANNEL_STREAM";
    case MarketingChannel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function marketingChannelToNumber(object: MarketingChannel): number {
  switch (object) {
    case MarketingChannel.NONE:
      return 0;
    case MarketingChannel.CHANNEL_FACEBOOK:
      return 1;
    case MarketingChannel.CHANNEL_INSTAGRAM:
      return 2;
    case MarketingChannel.CHANNEL_EMAIL_SUBSCRIPTION:
      return 3;
    case MarketingChannel.CHANNEL_EMAIL_MARKETING:
      return 4;
    case MarketingChannel.CHANNEL_STREAM:
      return 5;
    case MarketingChannel.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** Useful information about a supporter transaction, used for analytics purposes */
export enum SupporterTransactionTag {
  ON_SITE = "ON_SITE",
  MOBILE = "MOBILE",
  DESKTOP = "DESKTOP",
  REMOTE = "REMOTE",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function supporterTransactionTagFromJSON(object: any): SupporterTransactionTag {
  switch (object) {
    case 0:
    case "ON_SITE":
      return SupporterTransactionTag.ON_SITE;
    case 1:
    case "MOBILE":
      return SupporterTransactionTag.MOBILE;
    case 2:
    case "DESKTOP":
      return SupporterTransactionTag.DESKTOP;
    case 3:
    case "REMOTE":
      return SupporterTransactionTag.REMOTE;
    case 4:
    case "SOCIAL_MEDIA":
      return SupporterTransactionTag.SOCIAL_MEDIA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SupporterTransactionTag.UNRECOGNIZED;
  }
}

export function supporterTransactionTagToJSON(object: SupporterTransactionTag): string {
  switch (object) {
    case SupporterTransactionTag.ON_SITE:
      return "ON_SITE";
    case SupporterTransactionTag.MOBILE:
      return "MOBILE";
    case SupporterTransactionTag.DESKTOP:
      return "DESKTOP";
    case SupporterTransactionTag.REMOTE:
      return "REMOTE";
    case SupporterTransactionTag.SOCIAL_MEDIA:
      return "SOCIAL_MEDIA";
    case SupporterTransactionTag.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function supporterTransactionTagToNumber(object: SupporterTransactionTag): number {
  switch (object) {
    case SupporterTransactionTag.ON_SITE:
      return 0;
    case SupporterTransactionTag.MOBILE:
      return 1;
    case SupporterTransactionTag.DESKTOP:
      return 2;
    case SupporterTransactionTag.REMOTE:
      return 3;
    case SupporterTransactionTag.SOCIAL_MEDIA:
      return 4;
    case SupporterTransactionTag.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum MainEventCouponType {
  ONE_PER_LOTTERY_TICKET = "ONE_PER_LOTTERY_TICKET",
  ONE_PER_ENTRANCE_TICKET = "ONE_PER_ENTRANCE_TICKET",
  ONE_PER_LOTTERY_TRANSACTION = "ONE_PER_LOTTERY_TRANSACTION",
  ONE_PER_ENTRANCE_TRANSACTION = "ONE_PER_ENTRANCE_TRANSACTION",
  ONE_PER_TRANSACTION = "ONE_PER_TRANSACTION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function mainEventCouponTypeFromJSON(object: any): MainEventCouponType {
  switch (object) {
    case 0:
    case "ONE_PER_LOTTERY_TICKET":
      return MainEventCouponType.ONE_PER_LOTTERY_TICKET;
    case 1:
    case "ONE_PER_ENTRANCE_TICKET":
      return MainEventCouponType.ONE_PER_ENTRANCE_TICKET;
    case 2:
    case "ONE_PER_LOTTERY_TRANSACTION":
      return MainEventCouponType.ONE_PER_LOTTERY_TRANSACTION;
    case 3:
    case "ONE_PER_ENTRANCE_TRANSACTION":
      return MainEventCouponType.ONE_PER_ENTRANCE_TRANSACTION;
    case 4:
    case "ONE_PER_TRANSACTION":
      return MainEventCouponType.ONE_PER_TRANSACTION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MainEventCouponType.UNRECOGNIZED;
  }
}

export function mainEventCouponTypeToJSON(object: MainEventCouponType): string {
  switch (object) {
    case MainEventCouponType.ONE_PER_LOTTERY_TICKET:
      return "ONE_PER_LOTTERY_TICKET";
    case MainEventCouponType.ONE_PER_ENTRANCE_TICKET:
      return "ONE_PER_ENTRANCE_TICKET";
    case MainEventCouponType.ONE_PER_LOTTERY_TRANSACTION:
      return "ONE_PER_LOTTERY_TRANSACTION";
    case MainEventCouponType.ONE_PER_ENTRANCE_TRANSACTION:
      return "ONE_PER_ENTRANCE_TRANSACTION";
    case MainEventCouponType.ONE_PER_TRANSACTION:
      return "ONE_PER_TRANSACTION";
    case MainEventCouponType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function mainEventCouponTypeToNumber(object: MainEventCouponType): number {
  switch (object) {
    case MainEventCouponType.ONE_PER_LOTTERY_TICKET:
      return 0;
    case MainEventCouponType.ONE_PER_ENTRANCE_TICKET:
      return 1;
    case MainEventCouponType.ONE_PER_LOTTERY_TRANSACTION:
      return 2;
    case MainEventCouponType.ONE_PER_ENTRANCE_TRANSACTION:
      return 3;
    case MainEventCouponType.ONE_PER_TRANSACTION:
      return 4;
    case MainEventCouponType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum MainEventType {
  GAME = "GAME",
  GENERIC = "GENERIC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function mainEventTypeFromJSON(object: any): MainEventType {
  switch (object) {
    case 0:
    case "GAME":
      return MainEventType.GAME;
    case 1:
    case "GENERIC":
      return MainEventType.GENERIC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MainEventType.UNRECOGNIZED;
  }
}

export function mainEventTypeToJSON(object: MainEventType): string {
  switch (object) {
    case MainEventType.GAME:
      return "GAME";
    case MainEventType.GENERIC:
      return "GENERIC";
    case MainEventType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function mainEventTypeToNumber(object: MainEventType): number {
  switch (object) {
    case MainEventType.GAME:
      return 0;
    case MainEventType.GENERIC:
      return 1;
    case MainEventType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum EmailProvider {
  SENDGRID = "SENDGRID",
  GOOGLE = "GOOGLE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function emailProviderFromJSON(object: any): EmailProvider {
  switch (object) {
    case 0:
    case "SENDGRID":
      return EmailProvider.SENDGRID;
    case 1:
    case "GOOGLE":
      return EmailProvider.GOOGLE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EmailProvider.UNRECOGNIZED;
  }
}

export function emailProviderToJSON(object: EmailProvider): string {
  switch (object) {
    case EmailProvider.SENDGRID:
      return "SENDGRID";
    case EmailProvider.GOOGLE:
      return "GOOGLE";
    case EmailProvider.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function emailProviderToNumber(object: EmailProvider): number {
  switch (object) {
    case EmailProvider.SENDGRID:
      return 0;
    case EmailProvider.GOOGLE:
      return 1;
    case EmailProvider.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum ClubmateNotificationType {
  SILENT = "SILENT",
  FORCE_CONFIRMATION = "FORCE_CONFIRMATION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function clubmateNotificationTypeFromJSON(object: any): ClubmateNotificationType {
  switch (object) {
    case 0:
    case "SILENT":
      return ClubmateNotificationType.SILENT;
    case 1:
    case "FORCE_CONFIRMATION":
      return ClubmateNotificationType.FORCE_CONFIRMATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClubmateNotificationType.UNRECOGNIZED;
  }
}

export function clubmateNotificationTypeToJSON(object: ClubmateNotificationType): string {
  switch (object) {
    case ClubmateNotificationType.SILENT:
      return "SILENT";
    case ClubmateNotificationType.FORCE_CONFIRMATION:
      return "FORCE_CONFIRMATION";
    case ClubmateNotificationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function clubmateNotificationTypeToNumber(object: ClubmateNotificationType): number {
  switch (object) {
    case ClubmateNotificationType.SILENT:
      return 0;
    case ClubmateNotificationType.FORCE_CONFIRMATION:
      return 1;
    case ClubmateNotificationType.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** stream */
export enum StreamCommandType {
  RAFFLE = "RAFFLE",
  SHOW_QR_CODE = "SHOW_QR_CODE",
  HIDE = "HIDE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function streamCommandTypeFromJSON(object: any): StreamCommandType {
  switch (object) {
    case 0:
    case "RAFFLE":
      return StreamCommandType.RAFFLE;
    case 1:
    case "SHOW_QR_CODE":
      return StreamCommandType.SHOW_QR_CODE;
    case 2:
    case "HIDE":
      return StreamCommandType.HIDE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StreamCommandType.UNRECOGNIZED;
  }
}

export function streamCommandTypeToJSON(object: StreamCommandType): string {
  switch (object) {
    case StreamCommandType.RAFFLE:
      return "RAFFLE";
    case StreamCommandType.SHOW_QR_CODE:
      return "SHOW_QR_CODE";
    case StreamCommandType.HIDE:
      return "HIDE";
    case StreamCommandType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function streamCommandTypeToNumber(object: StreamCommandType): number {
  switch (object) {
    case StreamCommandType.RAFFLE:
      return 0;
    case StreamCommandType.SHOW_QR_CODE:
      return 1;
    case StreamCommandType.HIDE:
      return 2;
    case StreamCommandType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface SupporterAppMessages {
  showSwishTechnicalIssuesBanner: boolean;
}

export interface LotteryTicketToken {
  tokens: string[];
  tokenType: LotteryTicketTokenType;
}

export interface CustomLotteryTicketComposition {
  lotteryTicketTokens: LotteryTicketToken[];
  isProgression: boolean;
}

export interface EntranceArrivalCounter {
  entranceTicketClassCounts: EntranceTicketClassCount[];
  mainEventId: string;
  clubId: string;
}

export interface ClubAgreement {
  /** variable */
  entranceFactorFee: number;
  lotteryFactorFee: number;
  entranceFactorFeeDiscount: number;
  lotteryFactorFeeDiscount: number;
  customLotteryTicketsFactorFee: number;
  customLotteryTicketsFactorFeeDiscount: number;
  /** fixed */
  transactionFee: number;
  transactionFeeDiscount: number;
  createdTimestamp:
    | Date
    | undefined;
  /** if club uses clubmate physical lottery tickets */
  hasPhysicalLotteryTickets: boolean;
  clubId: string;
  subtractFeesFromStake: boolean;
  /** if club uses their own lottery ticket solution */
  customPhysicalLotteryTicketComposition: CustomLotteryTicketComposition | undefined;
  entranceAgreementType: EntranceAgreementType;
  lotteryAgreementType: LotteryAgreementType;
}

export interface County {
  id: string;
  name: string;
  geoJsonString: string;
}

export interface LotteryComplianceInformation {
  registrationNumber: string;
  lotteryContactPerson: string;
  lotteryContactPersonPhoneNumber: string;
  lotteryInspector: string;
  lotteryInspectorPhoneNumber: string;
  permitInformation: string;
  additionalInformation: string;
  countiesWithPermits: County[];
}

/** contains information of a club that should be shown to a supporter i.e no settings etc */
export interface Club {
  id: string;
  name: string;
  clubCategories: ClubCategory[];
  logoUrl: string;
  email: string;
  phoneNumber: string;
  abbreviation: string;
  location: Coordinates | undefined;
  address: Address | undefined;
  swishNumber: string;
  websiteUrl: string;
  twitterCardUrl: string;
  hideOnClubsPage: boolean;
  hasSmallLogo: boolean;
  arenaName: string;
  orgNumber: string;
  primaryColor: string;
  secondaryColor: string;
  primaryContent: string;
  secondaryContent: string;
}

export interface WebsiteSettings {
  complianceInformation: LotteryComplianceInformation | undefined;
  desktopBackgroundImageUrl: string;
  mobileBackgroundImageUrl: string;
  clubId: string;
}

export interface Coordinates {
  lat: number;
  long: number;
}

export interface Address {
  city: string;
  streetName: string;
  streetNumber: string;
  postalCode: string;
}

/** a class of ticket eg. senior, student etc */
export interface EntranceTicketClass {
  id: string;
  name: string;
  price: number;
  hiddenOnWebsite: boolean;
}

export interface IssuedEntranceTicket {
  entranceTicketId: string;
  entranceTicketClass: EntranceTicketClass | undefined;
  mainEventUsageCount: number;
}

export interface EntranceValidity {
  start: Date | undefined;
  end: Date | undefined;
  mainEventId: string;
  maxMainEventUsageCount: number;
}

export interface UsedSeasonTickets {
  clubId: string;
  mainEventId: string;
  usageCounts: { [key: string]: number };
}

export interface UsedSeasonTickets_UsageCountsEntry {
  key: string;
  value: number;
}

export interface IssuedEntranceTicketBatch {
  id: string;
  issuedTimestamp: Date | undefined;
  issuedEntranceTickets: IssuedEntranceTicket[];
  entranceValidity: EntranceValidity | undefined;
  isDeleted: boolean;
}

export interface EmailList {
  createdTimestamp: Date | undefined;
  id: string;
  emails: string[];
  name: string;
}

/** When a supporter buys a ticket this class contains the class and how many ticket of that class is bought */
export interface EntranceTicketClassCount {
  numberOfTickets: number;
  entranceTicketClass: EntranceTicketClass | undefined;
}

/** Settings which affect default events etc, private for each club */
export interface ClubSettings {
  clubId: string;
  lotteryEvent: LotteryEvent | undefined;
  entranceEvent: EntranceEvent | undefined;
  daysBeforeSubEventsOpen: number;
  sponsors: Sponsor[];
  selectedSponsorIds: string[];
  clubUsers: ClubUser[];
  /** users that have not registered but is added to a club, email -> userType */
  pendingClubUsers: { [key: string]: string };
  isSponsorsVisibleMainEventCheckout: boolean;
  clubImages: ClubImage[];
  claimItemPrizeInstructions: string;
  coupons: MainEventCoupon[];
  campaigns: Campaign[];
}

export interface ClubSettings_PendingClubUsersEntry {
  key: string;
  value: string;
}

export interface ClubUser {
  uid: string;
  userType: ClubUserType;
  email: string;
}

export interface AddClubUserRequest {
  email: string;
  userType: ClubUserType;
  clubId: string;
}

export interface RemoveClubUserRequest {
  clubId: string;
  email: string;
  isPending: boolean;
}

export interface ClubImage {
  id: string;
  imageUrl: string;
  name: string;
  type: ClubImageType;
}

export interface Prize {
  stakeShare: number;
  itemValue: number;
  itemName: string;
  imageUrl: string;
  description: string;
  /** is written to this prize when the lottery is Completed */
  lotteryTicketWinner: LotteryTicketWinner | undefined;
  id: string;
  claimItemPrizeInstructions: string;
}

export interface StopMainEventPayload {
  clubId: string;
  mainEventId: string;
}

export interface RaffleLotteryRequestPayload {
  mainEventId: string;
  clubId: string;
}

export interface ConfirmOtpPayload {
  phoneNumber: string;
  otp: string;
}

export interface RequestOtpPayload {
  phoneNumber: string;
}

export interface UnpaidTransaction {
  entranceTickets: EntranceTicketClassCount[];
  lotteryTicketCount: number;
  mainEventId: string;
  clubId: string;
  purchaseTimestamp: Date | undefined;
  paidAmount: number;
  supporterUid: string;
  paymentOption: PaymentOption;
  transactionId: string;
  userAgent: string;
  source: TransactionSource;
  swishRequestUrl: string;
  tags: SupporterTransactionTag[];
  marketingChannel: MarketingChannel;
  instructionUUID: string;
  payee: PaymentPayee;
  physicalTicketNumber: string;
  /** denomalize for swish message in swish api */
  clubName: string;
  position: Coordinates | undefined;
  supporterEmail: string;
  campaignId: string;
}

export interface SupporterTransaction {
  paidAmount: number;
  entranceTickets: EntranceTicketClassCount[];
  lotteryTicketNumbers: string[];
  transactionId: string;
  mainEventId: string;
  paymentOption: PaymentOption;
  purchaseTimestamp: Date | undefined;
  supporterUid: string;
  phoneNumber: string;
  source: TransactionSource;
  /** duplicative but needed for quering purposes */
  isLotteryTicketTransaction: boolean;
  clubId: string;
  payee: PaymentPayee;
  swishPaymentId: string;
  position: Coordinates | undefined;
  isCustomLotteryTickets: boolean;
  /** Represent the email address that a confirmation email was sent to, when the purchase was made */
  emailSentTo: string;
  issuedEntranceTicketId: string;
  /** NOTE THIS ARRAY CONTAINS ONE ID PER SCANNED TICKET, I.E IF THERE IS TWO TICKETS WITH ID "X" THAT IS SCANNED, => ["X", "X"] */
  scannedEntranceTicketClassIds: string[];
  scannedTimestamp: Date | undefined;
  marketingChannel: MarketingChannel;
  tags: SupporterTransactionTag[];
  campaignId: string;
  coupons: MainEventCoupon[];
  isSupporterShop: boolean;
}

export interface CreateCustomTokenWithPhoneNumberPayload {
  phoneNumber: string;
}

export interface Supporter {
  uid: string;
  supporterTransactions: SupporterTransaction[];
  phoneNumber: string;
  email: string;
  preferEmail: boolean;
  clubIdSubscriptions: string[];
  couponUsageCounts: { [key: string]: number };
}

export interface Supporter_CouponUsageCountsEntry {
  key: string;
  value: number;
}

export interface Sponsor {
  id: string;
  name: string;
  logoUrl: string;
  websiteUrl: string;
}

export interface EventMessage {
  imageUrl: string;
  title: string;
  text: string;
}

export interface MainEventCoupon {
  /** template */
  name: string;
  description: string;
  imageUrl: string;
  type: MainEventCouponType;
  id: string;
  /** 0 means unlimited */
  maxUsageCount: number;
  expirationDate: Date | undefined;
  minimumProductCount: number;
}

export interface Campaign {
  id: string;
  name: string;
}

export interface MainEvent {
  entranceEvent: EntranceEvent | undefined;
  lotteryEvent: LotteryEvent | undefined;
  id: string;
  opponentClub: OpponentClub | undefined;
  genericName: string;
  genericLogoUrl: string;
  mainEventType: MainEventType;
  sponsors: Sponsor[];
  eventTime: Date | undefined;
  startTime: Date | undefined;
  endTime: Date | undefined;
  hasEnded: boolean;
  teamTags: string[];
  isSponsorsVisibleMainEventCheckout: boolean;
  createdTimestamp:
    | Date
    | undefined;
  /** repeated Campaign campaigns = 20; */
  coupons: MainEventCoupon[];
}

export interface EntranceEvent {
  entranceTickets: EntranceTicketClass[];
  freeText: string;
  endTime: Date | undefined;
  hasEnded: boolean;
}

export interface LotteryEvent {
  lotteryPrices: { [key: number]: number };
  prizes: Prize[];
  belowMinimumStakePolicy: StakeLimitPolicy;
  endTime: Date | undefined;
  hasEnded: boolean;
  stakeValue: number;
  hiddenPriceQuantities: number[];
  roundPrizeMoney: boolean;
  freeText: string;
}

export interface LotteryEvent_LotteryPricesEntry {
  key: number;
  value: number;
}

export interface LotteryTicketWinner {
  transactionSource: TransactionSource;
  lotteryTicketNumber: string;
  smsSent: boolean;
  transactionId: string;
  /** is used to keep track of how much money has been paid out to the winner of this prize if paidPrizeMoney == prize.stakeShare * stakeValue all of the owed money has been paid */
  emailSent: boolean;
  paidPrizeMoney: number;
  paidTimestamp: Date | undefined;
}

export interface OpponentClub {
  name: string;
  logoUrl: string;
  clubCategory: ClubCategory;
}

export interface SendWinnerSmsPayload {
  clubId: string;
  mainEventId: string;
  prizeIds: string[];
}

export interface TransactionResult {
  customToken: string;
  supporterTransaction: SupporterTransaction | undefined;
  paymentStatus: string;
  errorCode: string;
}

export interface PayoutRequest {
  ssn: string;
  phoneNumber: string;
  mainEventId: string;
  clubId: string;
  transactionId: string;
  prizeId: string;
  donatePrizeMoney: boolean;
}

export interface Payout {
  mainEventId: string;
  clubId: string;
  ssn: string;
  phoneNumber: string;
  paymentReference: string;
  amount: string;
  paidTimestamp: Date | undefined;
  trasnactionId: string;
  supporterUid: string;
  /** NOTE THIS WAS ADDED APPROX 2023-01-15 TO ACCOMODATE MULTIPLE PRIZES */
  prizeId: string;
}

export interface PaymentRequestResponse {
  transactionId: string;
  swishToken: string;
}

export interface SendSupporterTransactionConfirmationPayload {
  email: string;
  phoneNumber: string;
  transactionId: string;
  mainEventId: string;
  clubId: string;
}

export interface CountSubscribersRequestPayload {
  clubId: string;
}

export interface CountSubscribersResponsePayload {
  count: number;
}

export interface Email {
  recipient: string;
  subject: string;
  html: string;
  id: string;
  emailProvider: EmailProvider;
  timestamp: Date | undefined;
  retryCount: number;
}

export interface SendEntranceTicketInviteEmailRequestPayload {
  email: string;
  mainEventId: string;
  entranceTickets: EntranceTicketClassCount[];
  clubId: string;
}

export interface IssueSupporterTransactionRequestPayload {
  clubId: string;
  maineventId: string;
  entranceTickets: EntranceTicketClassCount[];
}

export interface ClubFeedback {
  clubId: string;
  uid: string;
  feedback: string;
  timestamp: Date | undefined;
}

export interface ClubNotificationMessage {
  title: string;
  text: string;
  imageUrl: string;
}

export interface ClubNotification {
  type: ClubmateNotificationType;
  messages: ClubNotificationMessage[];
  userTargets: ClubUserType[];
  lotteryAgreementTypes: LotteryAgreementType[];
  entranceAgreementTypes: EntranceAgreementType[];
  id: string;
  timestamp: Date | undefined;
}

export interface AllClubNotifications {
  notifications: ClubNotification[];
}

/** apple pass */
export interface CreateApplePassPayload {
  transactionId: string;
  clubId: string;
  mainEventId: string;
}

export interface StreamCommand {
  issueTimestamp: Date | undefined;
  mainEventId: string;
  type: StreamCommandType;
  clubId: string;
}

export interface InterestedClub {
  email: string;
  phoneNumber: string;
  clubName: string;
}

export interface OpponentClubLogos {
  opponentClubs: OpponentClub[];
}

export interface AllCounties {
  counties: County[];
}

function createBaseSupporterAppMessages(): SupporterAppMessages {
  return { showSwishTechnicalIssuesBanner: false };
}

export const SupporterAppMessages = {
  encode(message: SupporterAppMessages, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.showSwishTechnicalIssuesBanner === true) {
      writer.uint32(8).bool(message.showSwishTechnicalIssuesBanner);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SupporterAppMessages {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSupporterAppMessages();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.showSwishTechnicalIssuesBanner = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SupporterAppMessages {
    return {
      showSwishTechnicalIssuesBanner: isSet(object.showSwishTechnicalIssuesBanner)
        ? Boolean(object.showSwishTechnicalIssuesBanner)
        : false,
    };
  },

  toJSON(message: SupporterAppMessages): unknown {
    const obj: any = {};
    message.showSwishTechnicalIssuesBanner !== undefined &&
      (obj.showSwishTechnicalIssuesBanner = message.showSwishTechnicalIssuesBanner);
    return obj;
  },

  create<I extends Exact<DeepPartial<SupporterAppMessages>, I>>(base?: I): SupporterAppMessages {
    return SupporterAppMessages.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SupporterAppMessages>, I>>(object: I): SupporterAppMessages {
    const message = createBaseSupporterAppMessages();
    message.showSwishTechnicalIssuesBanner = object.showSwishTechnicalIssuesBanner ?? false;
    return message;
  },
};

function createBaseLotteryTicketToken(): LotteryTicketToken {
  return { tokens: [], tokenType: LotteryTicketTokenType.DIGIT };
}

export const LotteryTicketToken = {
  encode(message: LotteryTicketToken, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tokens) {
      writer.uint32(10).string(v!);
    }
    if (message.tokenType !== LotteryTicketTokenType.DIGIT) {
      writer.uint32(16).int32(lotteryTicketTokenTypeToNumber(message.tokenType));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LotteryTicketToken {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLotteryTicketToken();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokens.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.tokenType = lotteryTicketTokenTypeFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LotteryTicketToken {
    return {
      tokens: Array.isArray(object?.tokens) ? object.tokens.map((e: any) => String(e)) : [],
      tokenType: isSet(object.tokenType)
        ? lotteryTicketTokenTypeFromJSON(object.tokenType)
        : LotteryTicketTokenType.DIGIT,
    };
  },

  toJSON(message: LotteryTicketToken): unknown {
    const obj: any = {};
    if (message.tokens) {
      obj.tokens = message.tokens.map((e) => e);
    } else {
      obj.tokens = [];
    }
    message.tokenType !== undefined && (obj.tokenType = lotteryTicketTokenTypeToJSON(message.tokenType));
    return obj;
  },

  create<I extends Exact<DeepPartial<LotteryTicketToken>, I>>(base?: I): LotteryTicketToken {
    return LotteryTicketToken.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LotteryTicketToken>, I>>(object: I): LotteryTicketToken {
    const message = createBaseLotteryTicketToken();
    message.tokens = object.tokens?.map((e) => e) || [];
    message.tokenType = object.tokenType ?? LotteryTicketTokenType.DIGIT;
    return message;
  },
};

function createBaseCustomLotteryTicketComposition(): CustomLotteryTicketComposition {
  return { lotteryTicketTokens: [], isProgression: false };
}

export const CustomLotteryTicketComposition = {
  encode(message: CustomLotteryTicketComposition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.lotteryTicketTokens) {
      LotteryTicketToken.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.isProgression === true) {
      writer.uint32(16).bool(message.isProgression);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomLotteryTicketComposition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomLotteryTicketComposition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lotteryTicketTokens.push(LotteryTicketToken.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isProgression = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomLotteryTicketComposition {
    return {
      lotteryTicketTokens: Array.isArray(object?.lotteryTicketTokens)
        ? object.lotteryTicketTokens.map((e: any) => LotteryTicketToken.fromJSON(e))
        : [],
      isProgression: isSet(object.isProgression) ? Boolean(object.isProgression) : false,
    };
  },

  toJSON(message: CustomLotteryTicketComposition): unknown {
    const obj: any = {};
    if (message.lotteryTicketTokens) {
      obj.lotteryTicketTokens = message.lotteryTicketTokens.map((e) => e ? LotteryTicketToken.toJSON(e) : undefined);
    } else {
      obj.lotteryTicketTokens = [];
    }
    message.isProgression !== undefined && (obj.isProgression = message.isProgression);
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomLotteryTicketComposition>, I>>(base?: I): CustomLotteryTicketComposition {
    return CustomLotteryTicketComposition.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CustomLotteryTicketComposition>, I>>(
    object: I,
  ): CustomLotteryTicketComposition {
    const message = createBaseCustomLotteryTicketComposition();
    message.lotteryTicketTokens = object.lotteryTicketTokens?.map((e) => LotteryTicketToken.fromPartial(e)) || [];
    message.isProgression = object.isProgression ?? false;
    return message;
  },
};

function createBaseEntranceArrivalCounter(): EntranceArrivalCounter {
  return { entranceTicketClassCounts: [], mainEventId: "", clubId: "" };
}

export const EntranceArrivalCounter = {
  encode(message: EntranceArrivalCounter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.entranceTicketClassCounts) {
      EntranceTicketClassCount.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.mainEventId !== "") {
      writer.uint32(18).string(message.mainEventId);
    }
    if (message.clubId !== "") {
      writer.uint32(26).string(message.clubId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntranceArrivalCounter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntranceArrivalCounter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entranceTicketClassCounts.push(EntranceTicketClassCount.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clubId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntranceArrivalCounter {
    return {
      entranceTicketClassCounts: Array.isArray(object?.entranceTicketClassCounts)
        ? object.entranceTicketClassCounts.map((e: any) => EntranceTicketClassCount.fromJSON(e))
        : [],
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
    };
  },

  toJSON(message: EntranceArrivalCounter): unknown {
    const obj: any = {};
    if (message.entranceTicketClassCounts) {
      obj.entranceTicketClassCounts = message.entranceTicketClassCounts.map((e) =>
        e ? EntranceTicketClassCount.toJSON(e) : undefined
      );
    } else {
      obj.entranceTicketClassCounts = [];
    }
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    message.clubId !== undefined && (obj.clubId = message.clubId);
    return obj;
  },

  create<I extends Exact<DeepPartial<EntranceArrivalCounter>, I>>(base?: I): EntranceArrivalCounter {
    return EntranceArrivalCounter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EntranceArrivalCounter>, I>>(object: I): EntranceArrivalCounter {
    const message = createBaseEntranceArrivalCounter();
    message.entranceTicketClassCounts =
      object.entranceTicketClassCounts?.map((e) => EntranceTicketClassCount.fromPartial(e)) || [];
    message.mainEventId = object.mainEventId ?? "";
    message.clubId = object.clubId ?? "";
    return message;
  },
};

function createBaseClubAgreement(): ClubAgreement {
  return {
    entranceFactorFee: 0,
    lotteryFactorFee: 0,
    entranceFactorFeeDiscount: 0,
    lotteryFactorFeeDiscount: 0,
    customLotteryTicketsFactorFee: 0,
    customLotteryTicketsFactorFeeDiscount: 0,
    transactionFee: 0,
    transactionFeeDiscount: 0,
    createdTimestamp: undefined,
    hasPhysicalLotteryTickets: false,
    clubId: "",
    subtractFeesFromStake: false,
    customPhysicalLotteryTicketComposition: undefined,
    entranceAgreementType: EntranceAgreementType.ENTRANCE_BASIC,
    lotteryAgreementType: LotteryAgreementType.LOTTERY_BASIC,
  };
}

export const ClubAgreement = {
  encode(message: ClubAgreement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entranceFactorFee !== 0) {
      writer.uint32(9).double(message.entranceFactorFee);
    }
    if (message.lotteryFactorFee !== 0) {
      writer.uint32(17).double(message.lotteryFactorFee);
    }
    if (message.entranceFactorFeeDiscount !== 0) {
      writer.uint32(25).double(message.entranceFactorFeeDiscount);
    }
    if (message.lotteryFactorFeeDiscount !== 0) {
      writer.uint32(33).double(message.lotteryFactorFeeDiscount);
    }
    if (message.customLotteryTicketsFactorFee !== 0) {
      writer.uint32(113).double(message.customLotteryTicketsFactorFee);
    }
    if (message.customLotteryTicketsFactorFeeDiscount !== 0) {
      writer.uint32(121).double(message.customLotteryTicketsFactorFeeDiscount);
    }
    if (message.transactionFee !== 0) {
      writer.uint32(41).double(message.transactionFee);
    }
    if (message.transactionFeeDiscount !== 0) {
      writer.uint32(49).double(message.transactionFeeDiscount);
    }
    if (message.createdTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.createdTimestamp), writer.uint32(58).fork()).ldelim();
    }
    if (message.hasPhysicalLotteryTickets === true) {
      writer.uint32(64).bool(message.hasPhysicalLotteryTickets);
    }
    if (message.clubId !== "") {
      writer.uint32(74).string(message.clubId);
    }
    if (message.subtractFeesFromStake === true) {
      writer.uint32(80).bool(message.subtractFeesFromStake);
    }
    if (message.customPhysicalLotteryTicketComposition !== undefined) {
      CustomLotteryTicketComposition.encode(message.customPhysicalLotteryTicketComposition, writer.uint32(90).fork())
        .ldelim();
    }
    if (message.entranceAgreementType !== EntranceAgreementType.ENTRANCE_BASIC) {
      writer.uint32(96).int32(entranceAgreementTypeToNumber(message.entranceAgreementType));
    }
    if (message.lotteryAgreementType !== LotteryAgreementType.LOTTERY_BASIC) {
      writer.uint32(104).int32(lotteryAgreementTypeToNumber(message.lotteryAgreementType));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClubAgreement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClubAgreement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.entranceFactorFee = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.lotteryFactorFee = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.entranceFactorFeeDiscount = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.lotteryFactorFeeDiscount = reader.double();
          continue;
        case 14:
          if (tag !== 113) {
            break;
          }

          message.customLotteryTicketsFactorFee = reader.double();
          continue;
        case 15:
          if (tag !== 121) {
            break;
          }

          message.customLotteryTicketsFactorFeeDiscount = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.transactionFee = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.transactionFeeDiscount = reader.double();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.hasPhysicalLotteryTickets = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.subtractFeesFromStake = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.customPhysicalLotteryTicketComposition = CustomLotteryTicketComposition.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.entranceAgreementType = entranceAgreementTypeFromJSON(reader.int32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.lotteryAgreementType = lotteryAgreementTypeFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClubAgreement {
    return {
      entranceFactorFee: isSet(object.entranceFactorFee) ? Number(object.entranceFactorFee) : 0,
      lotteryFactorFee: isSet(object.lotteryFactorFee) ? Number(object.lotteryFactorFee) : 0,
      entranceFactorFeeDiscount: isSet(object.entranceFactorFeeDiscount) ? Number(object.entranceFactorFeeDiscount) : 0,
      lotteryFactorFeeDiscount: isSet(object.lotteryFactorFeeDiscount) ? Number(object.lotteryFactorFeeDiscount) : 0,
      customLotteryTicketsFactorFee: isSet(object.customLotteryTicketsFactorFee)
        ? Number(object.customLotteryTicketsFactorFee)
        : 0,
      customLotteryTicketsFactorFeeDiscount: isSet(object.customLotteryTicketsFactorFeeDiscount)
        ? Number(object.customLotteryTicketsFactorFeeDiscount)
        : 0,
      transactionFee: isSet(object.transactionFee) ? Number(object.transactionFee) : 0,
      transactionFeeDiscount: isSet(object.transactionFeeDiscount) ? Number(object.transactionFeeDiscount) : 0,
      createdTimestamp: isSet(object.createdTimestamp) ? fromJsonTimestamp(object.createdTimestamp) : undefined,
      hasPhysicalLotteryTickets: isSet(object.hasPhysicalLotteryTickets)
        ? Boolean(object.hasPhysicalLotteryTickets)
        : false,
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      subtractFeesFromStake: isSet(object.subtractFeesFromStake) ? Boolean(object.subtractFeesFromStake) : false,
      customPhysicalLotteryTicketComposition: isSet(object.customPhysicalLotteryTicketComposition)
        ? CustomLotteryTicketComposition.fromJSON(object.customPhysicalLotteryTicketComposition)
        : undefined,
      entranceAgreementType: isSet(object.entranceAgreementType)
        ? entranceAgreementTypeFromJSON(object.entranceAgreementType)
        : EntranceAgreementType.ENTRANCE_BASIC,
      lotteryAgreementType: isSet(object.lotteryAgreementType)
        ? lotteryAgreementTypeFromJSON(object.lotteryAgreementType)
        : LotteryAgreementType.LOTTERY_BASIC,
    };
  },

  toJSON(message: ClubAgreement): unknown {
    const obj: any = {};
    message.entranceFactorFee !== undefined && (obj.entranceFactorFee = message.entranceFactorFee);
    message.lotteryFactorFee !== undefined && (obj.lotteryFactorFee = message.lotteryFactorFee);
    message.entranceFactorFeeDiscount !== undefined &&
      (obj.entranceFactorFeeDiscount = message.entranceFactorFeeDiscount);
    message.lotteryFactorFeeDiscount !== undefined && (obj.lotteryFactorFeeDiscount = message.lotteryFactorFeeDiscount);
    message.customLotteryTicketsFactorFee !== undefined &&
      (obj.customLotteryTicketsFactorFee = message.customLotteryTicketsFactorFee);
    message.customLotteryTicketsFactorFeeDiscount !== undefined &&
      (obj.customLotteryTicketsFactorFeeDiscount = message.customLotteryTicketsFactorFeeDiscount);
    message.transactionFee !== undefined && (obj.transactionFee = message.transactionFee);
    message.transactionFeeDiscount !== undefined && (obj.transactionFeeDiscount = message.transactionFeeDiscount);
    message.createdTimestamp !== undefined && (obj.createdTimestamp = message.createdTimestamp.toISOString());
    message.hasPhysicalLotteryTickets !== undefined &&
      (obj.hasPhysicalLotteryTickets = message.hasPhysicalLotteryTickets);
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.subtractFeesFromStake !== undefined && (obj.subtractFeesFromStake = message.subtractFeesFromStake);
    message.customPhysicalLotteryTicketComposition !== undefined &&
      (obj.customPhysicalLotteryTicketComposition = message.customPhysicalLotteryTicketComposition
        ? CustomLotteryTicketComposition.toJSON(message.customPhysicalLotteryTicketComposition)
        : undefined);
    message.entranceAgreementType !== undefined &&
      (obj.entranceAgreementType = entranceAgreementTypeToJSON(message.entranceAgreementType));
    message.lotteryAgreementType !== undefined &&
      (obj.lotteryAgreementType = lotteryAgreementTypeToJSON(message.lotteryAgreementType));
    return obj;
  },

  create<I extends Exact<DeepPartial<ClubAgreement>, I>>(base?: I): ClubAgreement {
    return ClubAgreement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClubAgreement>, I>>(object: I): ClubAgreement {
    const message = createBaseClubAgreement();
    message.entranceFactorFee = object.entranceFactorFee ?? 0;
    message.lotteryFactorFee = object.lotteryFactorFee ?? 0;
    message.entranceFactorFeeDiscount = object.entranceFactorFeeDiscount ?? 0;
    message.lotteryFactorFeeDiscount = object.lotteryFactorFeeDiscount ?? 0;
    message.customLotteryTicketsFactorFee = object.customLotteryTicketsFactorFee ?? 0;
    message.customLotteryTicketsFactorFeeDiscount = object.customLotteryTicketsFactorFeeDiscount ?? 0;
    message.transactionFee = object.transactionFee ?? 0;
    message.transactionFeeDiscount = object.transactionFeeDiscount ?? 0;
    message.createdTimestamp = object.createdTimestamp ?? undefined;
    message.hasPhysicalLotteryTickets = object.hasPhysicalLotteryTickets ?? false;
    message.clubId = object.clubId ?? "";
    message.subtractFeesFromStake = object.subtractFeesFromStake ?? false;
    message.customPhysicalLotteryTicketComposition =
      (object.customPhysicalLotteryTicketComposition !== undefined &&
          object.customPhysicalLotteryTicketComposition !== null)
        ? CustomLotteryTicketComposition.fromPartial(object.customPhysicalLotteryTicketComposition)
        : undefined;
    message.entranceAgreementType = object.entranceAgreementType ?? EntranceAgreementType.ENTRANCE_BASIC;
    message.lotteryAgreementType = object.lotteryAgreementType ?? LotteryAgreementType.LOTTERY_BASIC;
    return message;
  },
};

function createBaseCounty(): County {
  return { id: "", name: "", geoJsonString: "" };
}

export const County = {
  encode(message: County, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.geoJsonString !== "") {
      writer.uint32(26).string(message.geoJsonString);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): County {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCounty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.geoJsonString = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): County {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      geoJsonString: isSet(object.geoJsonString) ? String(object.geoJsonString) : "",
    };
  },

  toJSON(message: County): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.geoJsonString !== undefined && (obj.geoJsonString = message.geoJsonString);
    return obj;
  },

  create<I extends Exact<DeepPartial<County>, I>>(base?: I): County {
    return County.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<County>, I>>(object: I): County {
    const message = createBaseCounty();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.geoJsonString = object.geoJsonString ?? "";
    return message;
  },
};

function createBaseLotteryComplianceInformation(): LotteryComplianceInformation {
  return {
    registrationNumber: "",
    lotteryContactPerson: "",
    lotteryContactPersonPhoneNumber: "",
    lotteryInspector: "",
    lotteryInspectorPhoneNumber: "",
    permitInformation: "",
    additionalInformation: "",
    countiesWithPermits: [],
  };
}

export const LotteryComplianceInformation = {
  encode(message: LotteryComplianceInformation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.registrationNumber !== "") {
      writer.uint32(10).string(message.registrationNumber);
    }
    if (message.lotteryContactPerson !== "") {
      writer.uint32(18).string(message.lotteryContactPerson);
    }
    if (message.lotteryContactPersonPhoneNumber !== "") {
      writer.uint32(26).string(message.lotteryContactPersonPhoneNumber);
    }
    if (message.lotteryInspector !== "") {
      writer.uint32(34).string(message.lotteryInspector);
    }
    if (message.lotteryInspectorPhoneNumber !== "") {
      writer.uint32(42).string(message.lotteryInspectorPhoneNumber);
    }
    if (message.permitInformation !== "") {
      writer.uint32(50).string(message.permitInformation);
    }
    if (message.additionalInformation !== "") {
      writer.uint32(58).string(message.additionalInformation);
    }
    for (const v of message.countiesWithPermits) {
      County.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LotteryComplianceInformation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLotteryComplianceInformation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.registrationNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lotteryContactPerson = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lotteryContactPersonPhoneNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lotteryInspector = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lotteryInspectorPhoneNumber = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.permitInformation = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.additionalInformation = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.countiesWithPermits.push(County.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LotteryComplianceInformation {
    return {
      registrationNumber: isSet(object.registrationNumber) ? String(object.registrationNumber) : "",
      lotteryContactPerson: isSet(object.lotteryContactPerson) ? String(object.lotteryContactPerson) : "",
      lotteryContactPersonPhoneNumber: isSet(object.lotteryContactPersonPhoneNumber)
        ? String(object.lotteryContactPersonPhoneNumber)
        : "",
      lotteryInspector: isSet(object.lotteryInspector) ? String(object.lotteryInspector) : "",
      lotteryInspectorPhoneNumber: isSet(object.lotteryInspectorPhoneNumber)
        ? String(object.lotteryInspectorPhoneNumber)
        : "",
      permitInformation: isSet(object.permitInformation) ? String(object.permitInformation) : "",
      additionalInformation: isSet(object.additionalInformation) ? String(object.additionalInformation) : "",
      countiesWithPermits: Array.isArray(object?.countiesWithPermits)
        ? object.countiesWithPermits.map((e: any) => County.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LotteryComplianceInformation): unknown {
    const obj: any = {};
    message.registrationNumber !== undefined && (obj.registrationNumber = message.registrationNumber);
    message.lotteryContactPerson !== undefined && (obj.lotteryContactPerson = message.lotteryContactPerson);
    message.lotteryContactPersonPhoneNumber !== undefined &&
      (obj.lotteryContactPersonPhoneNumber = message.lotteryContactPersonPhoneNumber);
    message.lotteryInspector !== undefined && (obj.lotteryInspector = message.lotteryInspector);
    message.lotteryInspectorPhoneNumber !== undefined &&
      (obj.lotteryInspectorPhoneNumber = message.lotteryInspectorPhoneNumber);
    message.permitInformation !== undefined && (obj.permitInformation = message.permitInformation);
    message.additionalInformation !== undefined && (obj.additionalInformation = message.additionalInformation);
    if (message.countiesWithPermits) {
      obj.countiesWithPermits = message.countiesWithPermits.map((e) => e ? County.toJSON(e) : undefined);
    } else {
      obj.countiesWithPermits = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LotteryComplianceInformation>, I>>(base?: I): LotteryComplianceInformation {
    return LotteryComplianceInformation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LotteryComplianceInformation>, I>>(object: I): LotteryComplianceInformation {
    const message = createBaseLotteryComplianceInformation();
    message.registrationNumber = object.registrationNumber ?? "";
    message.lotteryContactPerson = object.lotteryContactPerson ?? "";
    message.lotteryContactPersonPhoneNumber = object.lotteryContactPersonPhoneNumber ?? "";
    message.lotteryInspector = object.lotteryInspector ?? "";
    message.lotteryInspectorPhoneNumber = object.lotteryInspectorPhoneNumber ?? "";
    message.permitInformation = object.permitInformation ?? "";
    message.additionalInformation = object.additionalInformation ?? "";
    message.countiesWithPermits = object.countiesWithPermits?.map((e) => County.fromPartial(e)) || [];
    return message;
  },
};

function createBaseClub(): Club {
  return {
    id: "",
    name: "",
    clubCategories: [],
    logoUrl: "",
    email: "",
    phoneNumber: "",
    abbreviation: "",
    location: undefined,
    address: undefined,
    swishNumber: "",
    websiteUrl: "",
    twitterCardUrl: "",
    hideOnClubsPage: false,
    hasSmallLogo: false,
    arenaName: "",
    orgNumber: "",
    primaryColor: "",
    secondaryColor: "",
    primaryContent: "",
    secondaryContent: "",
  };
}

export const Club = {
  encode(message: Club, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    writer.uint32(26).fork();
    for (const v of message.clubCategories) {
      writer.int32(clubCategoryToNumber(v));
    }
    writer.ldelim();
    if (message.logoUrl !== "") {
      writer.uint32(34).string(message.logoUrl);
    }
    if (message.email !== "") {
      writer.uint32(42).string(message.email);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(50).string(message.phoneNumber);
    }
    if (message.abbreviation !== "") {
      writer.uint32(58).string(message.abbreviation);
    }
    if (message.location !== undefined) {
      Coordinates.encode(message.location, writer.uint32(66).fork()).ldelim();
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(74).fork()).ldelim();
    }
    if (message.swishNumber !== "") {
      writer.uint32(82).string(message.swishNumber);
    }
    if (message.websiteUrl !== "") {
      writer.uint32(90).string(message.websiteUrl);
    }
    if (message.twitterCardUrl !== "") {
      writer.uint32(98).string(message.twitterCardUrl);
    }
    if (message.hideOnClubsPage === true) {
      writer.uint32(104).bool(message.hideOnClubsPage);
    }
    if (message.hasSmallLogo === true) {
      writer.uint32(112).bool(message.hasSmallLogo);
    }
    if (message.arenaName !== "") {
      writer.uint32(122).string(message.arenaName);
    }
    if (message.orgNumber !== "") {
      writer.uint32(130).string(message.orgNumber);
    }
    if (message.primaryColor !== "") {
      writer.uint32(138).string(message.primaryColor);
    }
    if (message.secondaryColor !== "") {
      writer.uint32(146).string(message.secondaryColor);
    }
    if (message.primaryContent !== "") {
      writer.uint32(154).string(message.primaryContent);
    }
    if (message.secondaryContent !== "") {
      writer.uint32(162).string(message.secondaryContent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Club {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClub();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.clubCategories.push(clubCategoryFromJSON(reader.int32()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clubCategories.push(clubCategoryFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.abbreviation = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.location = Coordinates.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.swishNumber = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.websiteUrl = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.twitterCardUrl = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.hideOnClubsPage = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.hasSmallLogo = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.arenaName = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.orgNumber = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.primaryColor = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.secondaryColor = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.primaryContent = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.secondaryContent = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Club {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      clubCategories: Array.isArray(object?.clubCategories)
        ? object.clubCategories.map((e: any) => clubCategoryFromJSON(e))
        : [],
      logoUrl: isSet(object.logoUrl) ? String(object.logoUrl) : "",
      email: isSet(object.email) ? String(object.email) : "",
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
      abbreviation: isSet(object.abbreviation) ? String(object.abbreviation) : "",
      location: isSet(object.location) ? Coordinates.fromJSON(object.location) : undefined,
      address: isSet(object.address) ? Address.fromJSON(object.address) : undefined,
      swishNumber: isSet(object.swishNumber) ? String(object.swishNumber) : "",
      websiteUrl: isSet(object.websiteUrl) ? String(object.websiteUrl) : "",
      twitterCardUrl: isSet(object.twitterCardUrl) ? String(object.twitterCardUrl) : "",
      hideOnClubsPage: isSet(object.hideOnClubsPage) ? Boolean(object.hideOnClubsPage) : false,
      hasSmallLogo: isSet(object.hasSmallLogo) ? Boolean(object.hasSmallLogo) : false,
      arenaName: isSet(object.arenaName) ? String(object.arenaName) : "",
      orgNumber: isSet(object.orgNumber) ? String(object.orgNumber) : "",
      primaryColor: isSet(object.primaryColor) ? String(object.primaryColor) : "",
      secondaryColor: isSet(object.secondaryColor) ? String(object.secondaryColor) : "",
      primaryContent: isSet(object.primaryContent) ? String(object.primaryContent) : "",
      secondaryContent: isSet(object.secondaryContent) ? String(object.secondaryContent) : "",
    };
  },

  toJSON(message: Club): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    if (message.clubCategories) {
      obj.clubCategories = message.clubCategories.map((e) => clubCategoryToJSON(e));
    } else {
      obj.clubCategories = [];
    }
    message.logoUrl !== undefined && (obj.logoUrl = message.logoUrl);
    message.email !== undefined && (obj.email = message.email);
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    message.abbreviation !== undefined && (obj.abbreviation = message.abbreviation);
    message.location !== undefined &&
      (obj.location = message.location ? Coordinates.toJSON(message.location) : undefined);
    message.address !== undefined && (obj.address = message.address ? Address.toJSON(message.address) : undefined);
    message.swishNumber !== undefined && (obj.swishNumber = message.swishNumber);
    message.websiteUrl !== undefined && (obj.websiteUrl = message.websiteUrl);
    message.twitterCardUrl !== undefined && (obj.twitterCardUrl = message.twitterCardUrl);
    message.hideOnClubsPage !== undefined && (obj.hideOnClubsPage = message.hideOnClubsPage);
    message.hasSmallLogo !== undefined && (obj.hasSmallLogo = message.hasSmallLogo);
    message.arenaName !== undefined && (obj.arenaName = message.arenaName);
    message.orgNumber !== undefined && (obj.orgNumber = message.orgNumber);
    message.primaryColor !== undefined && (obj.primaryColor = message.primaryColor);
    message.secondaryColor !== undefined && (obj.secondaryColor = message.secondaryColor);
    message.primaryContent !== undefined && (obj.primaryContent = message.primaryContent);
    message.secondaryContent !== undefined && (obj.secondaryContent = message.secondaryContent);
    return obj;
  },

  create<I extends Exact<DeepPartial<Club>, I>>(base?: I): Club {
    return Club.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Club>, I>>(object: I): Club {
    const message = createBaseClub();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.clubCategories = object.clubCategories?.map((e) => e) || [];
    message.logoUrl = object.logoUrl ?? "";
    message.email = object.email ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.abbreviation = object.abbreviation ?? "";
    message.location = (object.location !== undefined && object.location !== null)
      ? Coordinates.fromPartial(object.location)
      : undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? Address.fromPartial(object.address)
      : undefined;
    message.swishNumber = object.swishNumber ?? "";
    message.websiteUrl = object.websiteUrl ?? "";
    message.twitterCardUrl = object.twitterCardUrl ?? "";
    message.hideOnClubsPage = object.hideOnClubsPage ?? false;
    message.hasSmallLogo = object.hasSmallLogo ?? false;
    message.arenaName = object.arenaName ?? "";
    message.orgNumber = object.orgNumber ?? "";
    message.primaryColor = object.primaryColor ?? "";
    message.secondaryColor = object.secondaryColor ?? "";
    message.primaryContent = object.primaryContent ?? "";
    message.secondaryContent = object.secondaryContent ?? "";
    return message;
  },
};

function createBaseWebsiteSettings(): WebsiteSettings {
  return { complianceInformation: undefined, desktopBackgroundImageUrl: "", mobileBackgroundImageUrl: "", clubId: "" };
}

export const WebsiteSettings = {
  encode(message: WebsiteSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.complianceInformation !== undefined) {
      LotteryComplianceInformation.encode(message.complianceInformation, writer.uint32(26).fork()).ldelim();
    }
    if (message.desktopBackgroundImageUrl !== "") {
      writer.uint32(34).string(message.desktopBackgroundImageUrl);
    }
    if (message.mobileBackgroundImageUrl !== "") {
      writer.uint32(42).string(message.mobileBackgroundImageUrl);
    }
    if (message.clubId !== "") {
      writer.uint32(50).string(message.clubId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebsiteSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebsiteSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.complianceInformation = LotteryComplianceInformation.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.desktopBackgroundImageUrl = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.mobileBackgroundImageUrl = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.clubId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebsiteSettings {
    return {
      complianceInformation: isSet(object.complianceInformation)
        ? LotteryComplianceInformation.fromJSON(object.complianceInformation)
        : undefined,
      desktopBackgroundImageUrl: isSet(object.desktopBackgroundImageUrl)
        ? String(object.desktopBackgroundImageUrl)
        : "",
      mobileBackgroundImageUrl: isSet(object.mobileBackgroundImageUrl) ? String(object.mobileBackgroundImageUrl) : "",
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
    };
  },

  toJSON(message: WebsiteSettings): unknown {
    const obj: any = {};
    message.complianceInformation !== undefined && (obj.complianceInformation = message.complianceInformation
      ? LotteryComplianceInformation.toJSON(message.complianceInformation)
      : undefined);
    message.desktopBackgroundImageUrl !== undefined &&
      (obj.desktopBackgroundImageUrl = message.desktopBackgroundImageUrl);
    message.mobileBackgroundImageUrl !== undefined && (obj.mobileBackgroundImageUrl = message.mobileBackgroundImageUrl);
    message.clubId !== undefined && (obj.clubId = message.clubId);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebsiteSettings>, I>>(base?: I): WebsiteSettings {
    return WebsiteSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebsiteSettings>, I>>(object: I): WebsiteSettings {
    const message = createBaseWebsiteSettings();
    message.complianceInformation =
      (object.complianceInformation !== undefined && object.complianceInformation !== null)
        ? LotteryComplianceInformation.fromPartial(object.complianceInformation)
        : undefined;
    message.desktopBackgroundImageUrl = object.desktopBackgroundImageUrl ?? "";
    message.mobileBackgroundImageUrl = object.mobileBackgroundImageUrl ?? "";
    message.clubId = object.clubId ?? "";
    return message;
  },
};

function createBaseCoordinates(): Coordinates {
  return { lat: 0, long: 0 };
}

export const Coordinates = {
  encode(message: Coordinates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lat !== 0) {
      writer.uint32(9).double(message.lat);
    }
    if (message.long !== 0) {
      writer.uint32(17).double(message.long);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Coordinates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoordinates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.lat = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.long = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Coordinates {
    return { lat: isSet(object.lat) ? Number(object.lat) : 0, long: isSet(object.long) ? Number(object.long) : 0 };
  },

  toJSON(message: Coordinates): unknown {
    const obj: any = {};
    message.lat !== undefined && (obj.lat = message.lat);
    message.long !== undefined && (obj.long = message.long);
    return obj;
  },

  create<I extends Exact<DeepPartial<Coordinates>, I>>(base?: I): Coordinates {
    return Coordinates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Coordinates>, I>>(object: I): Coordinates {
    const message = createBaseCoordinates();
    message.lat = object.lat ?? 0;
    message.long = object.long ?? 0;
    return message;
  },
};

function createBaseAddress(): Address {
  return { city: "", streetName: "", streetNumber: "", postalCode: "" };
}

export const Address = {
  encode(message: Address, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.city !== "") {
      writer.uint32(10).string(message.city);
    }
    if (message.streetName !== "") {
      writer.uint32(18).string(message.streetName);
    }
    if (message.streetNumber !== "") {
      writer.uint32(26).string(message.streetNumber);
    }
    if (message.postalCode !== "") {
      writer.uint32(34).string(message.postalCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Address {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.city = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.streetName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.streetNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.postalCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Address {
    return {
      city: isSet(object.city) ? String(object.city) : "",
      streetName: isSet(object.streetName) ? String(object.streetName) : "",
      streetNumber: isSet(object.streetNumber) ? String(object.streetNumber) : "",
      postalCode: isSet(object.postalCode) ? String(object.postalCode) : "",
    };
  },

  toJSON(message: Address): unknown {
    const obj: any = {};
    message.city !== undefined && (obj.city = message.city);
    message.streetName !== undefined && (obj.streetName = message.streetName);
    message.streetNumber !== undefined && (obj.streetNumber = message.streetNumber);
    message.postalCode !== undefined && (obj.postalCode = message.postalCode);
    return obj;
  },

  create<I extends Exact<DeepPartial<Address>, I>>(base?: I): Address {
    return Address.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Address>, I>>(object: I): Address {
    const message = createBaseAddress();
    message.city = object.city ?? "";
    message.streetName = object.streetName ?? "";
    message.streetNumber = object.streetNumber ?? "";
    message.postalCode = object.postalCode ?? "";
    return message;
  },
};

function createBaseEntranceTicketClass(): EntranceTicketClass {
  return { id: "", name: "", price: 0, hiddenOnWebsite: false };
}

export const EntranceTicketClass = {
  encode(message: EntranceTicketClass, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.price !== 0) {
      writer.uint32(25).double(message.price);
    }
    if (message.hiddenOnWebsite === true) {
      writer.uint32(32).bool(message.hiddenOnWebsite);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntranceTicketClass {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntranceTicketClass();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.price = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hiddenOnWebsite = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntranceTicketClass {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      price: isSet(object.price) ? Number(object.price) : 0,
      hiddenOnWebsite: isSet(object.hiddenOnWebsite) ? Boolean(object.hiddenOnWebsite) : false,
    };
  },

  toJSON(message: EntranceTicketClass): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.price !== undefined && (obj.price = message.price);
    message.hiddenOnWebsite !== undefined && (obj.hiddenOnWebsite = message.hiddenOnWebsite);
    return obj;
  },

  create<I extends Exact<DeepPartial<EntranceTicketClass>, I>>(base?: I): EntranceTicketClass {
    return EntranceTicketClass.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EntranceTicketClass>, I>>(object: I): EntranceTicketClass {
    const message = createBaseEntranceTicketClass();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.price = object.price ?? 0;
    message.hiddenOnWebsite = object.hiddenOnWebsite ?? false;
    return message;
  },
};

function createBaseIssuedEntranceTicket(): IssuedEntranceTicket {
  return { entranceTicketId: "", entranceTicketClass: undefined, mainEventUsageCount: 0 };
}

export const IssuedEntranceTicket = {
  encode(message: IssuedEntranceTicket, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entranceTicketId !== "") {
      writer.uint32(10).string(message.entranceTicketId);
    }
    if (message.entranceTicketClass !== undefined) {
      EntranceTicketClass.encode(message.entranceTicketClass, writer.uint32(18).fork()).ldelim();
    }
    if (message.mainEventUsageCount !== 0) {
      writer.uint32(24).int32(message.mainEventUsageCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssuedEntranceTicket {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssuedEntranceTicket();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entranceTicketId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entranceTicketClass = EntranceTicketClass.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.mainEventUsageCount = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssuedEntranceTicket {
    return {
      entranceTicketId: isSet(object.entranceTicketId) ? String(object.entranceTicketId) : "",
      entranceTicketClass: isSet(object.entranceTicketClass)
        ? EntranceTicketClass.fromJSON(object.entranceTicketClass)
        : undefined,
      mainEventUsageCount: isSet(object.mainEventUsageCount) ? Number(object.mainEventUsageCount) : 0,
    };
  },

  toJSON(message: IssuedEntranceTicket): unknown {
    const obj: any = {};
    message.entranceTicketId !== undefined && (obj.entranceTicketId = message.entranceTicketId);
    message.entranceTicketClass !== undefined && (obj.entranceTicketClass = message.entranceTicketClass
      ? EntranceTicketClass.toJSON(message.entranceTicketClass)
      : undefined);
    message.mainEventUsageCount !== undefined && (obj.mainEventUsageCount = Math.round(message.mainEventUsageCount));
    return obj;
  },

  create<I extends Exact<DeepPartial<IssuedEntranceTicket>, I>>(base?: I): IssuedEntranceTicket {
    return IssuedEntranceTicket.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssuedEntranceTicket>, I>>(object: I): IssuedEntranceTicket {
    const message = createBaseIssuedEntranceTicket();
    message.entranceTicketId = object.entranceTicketId ?? "";
    message.entranceTicketClass = (object.entranceTicketClass !== undefined && object.entranceTicketClass !== null)
      ? EntranceTicketClass.fromPartial(object.entranceTicketClass)
      : undefined;
    message.mainEventUsageCount = object.mainEventUsageCount ?? 0;
    return message;
  },
};

function createBaseEntranceValidity(): EntranceValidity {
  return { start: undefined, end: undefined, mainEventId: "", maxMainEventUsageCount: 0 };
}

export const EntranceValidity = {
  encode(message: EntranceValidity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.start !== undefined) {
      Timestamp.encode(toTimestamp(message.start), writer.uint32(10).fork()).ldelim();
    }
    if (message.end !== undefined) {
      Timestamp.encode(toTimestamp(message.end), writer.uint32(18).fork()).ldelim();
    }
    if (message.mainEventId !== "") {
      writer.uint32(26).string(message.mainEventId);
    }
    if (message.maxMainEventUsageCount !== 0) {
      writer.uint32(32).int32(message.maxMainEventUsageCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntranceValidity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntranceValidity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.start = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.end = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxMainEventUsageCount = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntranceValidity {
    return {
      start: isSet(object.start) ? fromJsonTimestamp(object.start) : undefined,
      end: isSet(object.end) ? fromJsonTimestamp(object.end) : undefined,
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      maxMainEventUsageCount: isSet(object.maxMainEventUsageCount) ? Number(object.maxMainEventUsageCount) : 0,
    };
  },

  toJSON(message: EntranceValidity): unknown {
    const obj: any = {};
    message.start !== undefined && (obj.start = message.start.toISOString());
    message.end !== undefined && (obj.end = message.end.toISOString());
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    message.maxMainEventUsageCount !== undefined &&
      (obj.maxMainEventUsageCount = Math.round(message.maxMainEventUsageCount));
    return obj;
  },

  create<I extends Exact<DeepPartial<EntranceValidity>, I>>(base?: I): EntranceValidity {
    return EntranceValidity.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EntranceValidity>, I>>(object: I): EntranceValidity {
    const message = createBaseEntranceValidity();
    message.start = object.start ?? undefined;
    message.end = object.end ?? undefined;
    message.mainEventId = object.mainEventId ?? "";
    message.maxMainEventUsageCount = object.maxMainEventUsageCount ?? 0;
    return message;
  },
};

function createBaseUsedSeasonTickets(): UsedSeasonTickets {
  return { clubId: "", mainEventId: "", usageCounts: {} };
}

export const UsedSeasonTickets = {
  encode(message: UsedSeasonTickets, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clubId !== "") {
      writer.uint32(10).string(message.clubId);
    }
    if (message.mainEventId !== "") {
      writer.uint32(18).string(message.mainEventId);
    }
    Object.entries(message.usageCounts).forEach(([key, value]) => {
      UsedSeasonTickets_UsageCountsEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UsedSeasonTickets {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUsedSeasonTickets();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = UsedSeasonTickets_UsageCountsEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.usageCounts[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UsedSeasonTickets {
    return {
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      usageCounts: isObject(object.usageCounts)
        ? Object.entries(object.usageCounts).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: UsedSeasonTickets): unknown {
    const obj: any = {};
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    obj.usageCounts = {};
    if (message.usageCounts) {
      Object.entries(message.usageCounts).forEach(([k, v]) => {
        obj.usageCounts[k] = Math.round(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UsedSeasonTickets>, I>>(base?: I): UsedSeasonTickets {
    return UsedSeasonTickets.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UsedSeasonTickets>, I>>(object: I): UsedSeasonTickets {
    const message = createBaseUsedSeasonTickets();
    message.clubId = object.clubId ?? "";
    message.mainEventId = object.mainEventId ?? "";
    message.usageCounts = Object.entries(object.usageCounts ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Number(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUsedSeasonTickets_UsageCountsEntry(): UsedSeasonTickets_UsageCountsEntry {
  return { key: "", value: 0 };
}

export const UsedSeasonTickets_UsageCountsEntry = {
  encode(message: UsedSeasonTickets_UsageCountsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UsedSeasonTickets_UsageCountsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUsedSeasonTickets_UsageCountsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UsedSeasonTickets_UsageCountsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: UsedSeasonTickets_UsageCountsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = Math.round(message.value));
    return obj;
  },

  create<I extends Exact<DeepPartial<UsedSeasonTickets_UsageCountsEntry>, I>>(
    base?: I,
  ): UsedSeasonTickets_UsageCountsEntry {
    return UsedSeasonTickets_UsageCountsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UsedSeasonTickets_UsageCountsEntry>, I>>(
    object: I,
  ): UsedSeasonTickets_UsageCountsEntry {
    const message = createBaseUsedSeasonTickets_UsageCountsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseIssuedEntranceTicketBatch(): IssuedEntranceTicketBatch {
  return {
    id: "",
    issuedTimestamp: undefined,
    issuedEntranceTickets: [],
    entranceValidity: undefined,
    isDeleted: false,
  };
}

export const IssuedEntranceTicketBatch = {
  encode(message: IssuedEntranceTicketBatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.issuedTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.issuedTimestamp), writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.issuedEntranceTickets) {
      IssuedEntranceTicket.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.entranceValidity !== undefined) {
      EntranceValidity.encode(message.entranceValidity, writer.uint32(34).fork()).ldelim();
    }
    if (message.isDeleted === true) {
      writer.uint32(40).bool(message.isDeleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssuedEntranceTicketBatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssuedEntranceTicketBatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.issuedTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.issuedEntranceTickets.push(IssuedEntranceTicket.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.entranceValidity = EntranceValidity.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isDeleted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssuedEntranceTicketBatch {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      issuedTimestamp: isSet(object.issuedTimestamp) ? fromJsonTimestamp(object.issuedTimestamp) : undefined,
      issuedEntranceTickets: Array.isArray(object?.issuedEntranceTickets)
        ? object.issuedEntranceTickets.map((e: any) => IssuedEntranceTicket.fromJSON(e))
        : [],
      entranceValidity: isSet(object.entranceValidity) ? EntranceValidity.fromJSON(object.entranceValidity) : undefined,
      isDeleted: isSet(object.isDeleted) ? Boolean(object.isDeleted) : false,
    };
  },

  toJSON(message: IssuedEntranceTicketBatch): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.issuedTimestamp !== undefined && (obj.issuedTimestamp = message.issuedTimestamp.toISOString());
    if (message.issuedEntranceTickets) {
      obj.issuedEntranceTickets = message.issuedEntranceTickets.map((e) =>
        e ? IssuedEntranceTicket.toJSON(e) : undefined
      );
    } else {
      obj.issuedEntranceTickets = [];
    }
    message.entranceValidity !== undefined &&
      (obj.entranceValidity = message.entranceValidity ? EntranceValidity.toJSON(message.entranceValidity) : undefined);
    message.isDeleted !== undefined && (obj.isDeleted = message.isDeleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<IssuedEntranceTicketBatch>, I>>(base?: I): IssuedEntranceTicketBatch {
    return IssuedEntranceTicketBatch.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssuedEntranceTicketBatch>, I>>(object: I): IssuedEntranceTicketBatch {
    const message = createBaseIssuedEntranceTicketBatch();
    message.id = object.id ?? "";
    message.issuedTimestamp = object.issuedTimestamp ?? undefined;
    message.issuedEntranceTickets = object.issuedEntranceTickets?.map((e) => IssuedEntranceTicket.fromPartial(e)) || [];
    message.entranceValidity = (object.entranceValidity !== undefined && object.entranceValidity !== null)
      ? EntranceValidity.fromPartial(object.entranceValidity)
      : undefined;
    message.isDeleted = object.isDeleted ?? false;
    return message;
  },
};

function createBaseEmailList(): EmailList {
  return { createdTimestamp: undefined, id: "", emails: [], name: "" };
}

export const EmailList = {
  encode(message: EmailList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createdTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.createdTimestamp), writer.uint32(10).fork()).ldelim();
    }
    if (message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    for (const v of message.emails) {
      writer.uint32(26).string(v!);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.createdTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.emails.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmailList {
    return {
      createdTimestamp: isSet(object.createdTimestamp) ? fromJsonTimestamp(object.createdTimestamp) : undefined,
      id: isSet(object.id) ? String(object.id) : "",
      emails: Array.isArray(object?.emails) ? object.emails.map((e: any) => String(e)) : [],
      name: isSet(object.name) ? String(object.name) : "",
    };
  },

  toJSON(message: EmailList): unknown {
    const obj: any = {};
    message.createdTimestamp !== undefined && (obj.createdTimestamp = message.createdTimestamp.toISOString());
    message.id !== undefined && (obj.id = message.id);
    if (message.emails) {
      obj.emails = message.emails.map((e) => e);
    } else {
      obj.emails = [];
    }
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<EmailList>, I>>(base?: I): EmailList {
    return EmailList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EmailList>, I>>(object: I): EmailList {
    const message = createBaseEmailList();
    message.createdTimestamp = object.createdTimestamp ?? undefined;
    message.id = object.id ?? "";
    message.emails = object.emails?.map((e) => e) || [];
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseEntranceTicketClassCount(): EntranceTicketClassCount {
  return { numberOfTickets: 0, entranceTicketClass: undefined };
}

export const EntranceTicketClassCount = {
  encode(message: EntranceTicketClassCount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.numberOfTickets !== 0) {
      writer.uint32(8).int32(message.numberOfTickets);
    }
    if (message.entranceTicketClass !== undefined) {
      EntranceTicketClass.encode(message.entranceTicketClass, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntranceTicketClassCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntranceTicketClassCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.numberOfTickets = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entranceTicketClass = EntranceTicketClass.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntranceTicketClassCount {
    return {
      numberOfTickets: isSet(object.numberOfTickets) ? Number(object.numberOfTickets) : 0,
      entranceTicketClass: isSet(object.entranceTicketClass)
        ? EntranceTicketClass.fromJSON(object.entranceTicketClass)
        : undefined,
    };
  },

  toJSON(message: EntranceTicketClassCount): unknown {
    const obj: any = {};
    message.numberOfTickets !== undefined && (obj.numberOfTickets = Math.round(message.numberOfTickets));
    message.entranceTicketClass !== undefined && (obj.entranceTicketClass = message.entranceTicketClass
      ? EntranceTicketClass.toJSON(message.entranceTicketClass)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<EntranceTicketClassCount>, I>>(base?: I): EntranceTicketClassCount {
    return EntranceTicketClassCount.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EntranceTicketClassCount>, I>>(object: I): EntranceTicketClassCount {
    const message = createBaseEntranceTicketClassCount();
    message.numberOfTickets = object.numberOfTickets ?? 0;
    message.entranceTicketClass = (object.entranceTicketClass !== undefined && object.entranceTicketClass !== null)
      ? EntranceTicketClass.fromPartial(object.entranceTicketClass)
      : undefined;
    return message;
  },
};

function createBaseClubSettings(): ClubSettings {
  return {
    clubId: "",
    lotteryEvent: undefined,
    entranceEvent: undefined,
    daysBeforeSubEventsOpen: 0,
    sponsors: [],
    selectedSponsorIds: [],
    clubUsers: [],
    pendingClubUsers: {},
    isSponsorsVisibleMainEventCheckout: false,
    clubImages: [],
    claimItemPrizeInstructions: "",
    coupons: [],
    campaigns: [],
  };
}

export const ClubSettings = {
  encode(message: ClubSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clubId !== "") {
      writer.uint32(10).string(message.clubId);
    }
    if (message.lotteryEvent !== undefined) {
      LotteryEvent.encode(message.lotteryEvent, writer.uint32(18).fork()).ldelim();
    }
    if (message.entranceEvent !== undefined) {
      EntranceEvent.encode(message.entranceEvent, writer.uint32(26).fork()).ldelim();
    }
    if (message.daysBeforeSubEventsOpen !== 0) {
      writer.uint32(32).int32(message.daysBeforeSubEventsOpen);
    }
    for (const v of message.sponsors) {
      Sponsor.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.selectedSponsorIds) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.clubUsers) {
      ClubUser.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    Object.entries(message.pendingClubUsers).forEach(([key, value]) => {
      ClubSettings_PendingClubUsersEntry.encode({ key: key as any, value }, writer.uint32(74).fork()).ldelim();
    });
    if (message.isSponsorsVisibleMainEventCheckout === true) {
      writer.uint32(80).bool(message.isSponsorsVisibleMainEventCheckout);
    }
    for (const v of message.clubImages) {
      ClubImage.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.claimItemPrizeInstructions !== "") {
      writer.uint32(98).string(message.claimItemPrizeInstructions);
    }
    for (const v of message.coupons) {
      MainEventCoupon.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.campaigns) {
      Campaign.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClubSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClubSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lotteryEvent = LotteryEvent.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.entranceEvent = EntranceEvent.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.daysBeforeSubEventsOpen = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sponsors.push(Sponsor.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.selectedSponsorIds.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.clubUsers.push(ClubUser.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          const entry9 = ClubSettings_PendingClubUsersEntry.decode(reader, reader.uint32());
          if (entry9.value !== undefined) {
            message.pendingClubUsers[entry9.key] = entry9.value;
          }
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isSponsorsVisibleMainEventCheckout = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.clubImages.push(ClubImage.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.claimItemPrizeInstructions = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.coupons.push(MainEventCoupon.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.campaigns.push(Campaign.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClubSettings {
    return {
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      lotteryEvent: isSet(object.lotteryEvent) ? LotteryEvent.fromJSON(object.lotteryEvent) : undefined,
      entranceEvent: isSet(object.entranceEvent) ? EntranceEvent.fromJSON(object.entranceEvent) : undefined,
      daysBeforeSubEventsOpen: isSet(object.daysBeforeSubEventsOpen) ? Number(object.daysBeforeSubEventsOpen) : 0,
      sponsors: Array.isArray(object?.sponsors) ? object.sponsors.map((e: any) => Sponsor.fromJSON(e)) : [],
      selectedSponsorIds: Array.isArray(object?.selectedSponsorIds)
        ? object.selectedSponsorIds.map((e: any) => String(e))
        : [],
      clubUsers: Array.isArray(object?.clubUsers) ? object.clubUsers.map((e: any) => ClubUser.fromJSON(e)) : [],
      pendingClubUsers: isObject(object.pendingClubUsers)
        ? Object.entries(object.pendingClubUsers).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      isSponsorsVisibleMainEventCheckout: isSet(object.isSponsorsVisibleMainEventCheckout)
        ? Boolean(object.isSponsorsVisibleMainEventCheckout)
        : false,
      clubImages: Array.isArray(object?.clubImages) ? object.clubImages.map((e: any) => ClubImage.fromJSON(e)) : [],
      claimItemPrizeInstructions: isSet(object.claimItemPrizeInstructions)
        ? String(object.claimItemPrizeInstructions)
        : "",
      coupons: Array.isArray(object?.coupons) ? object.coupons.map((e: any) => MainEventCoupon.fromJSON(e)) : [],
      campaigns: Array.isArray(object?.campaigns) ? object.campaigns.map((e: any) => Campaign.fromJSON(e)) : [],
    };
  },

  toJSON(message: ClubSettings): unknown {
    const obj: any = {};
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.lotteryEvent !== undefined &&
      (obj.lotteryEvent = message.lotteryEvent ? LotteryEvent.toJSON(message.lotteryEvent) : undefined);
    message.entranceEvent !== undefined &&
      (obj.entranceEvent = message.entranceEvent ? EntranceEvent.toJSON(message.entranceEvent) : undefined);
    message.daysBeforeSubEventsOpen !== undefined &&
      (obj.daysBeforeSubEventsOpen = Math.round(message.daysBeforeSubEventsOpen));
    if (message.sponsors) {
      obj.sponsors = message.sponsors.map((e) => e ? Sponsor.toJSON(e) : undefined);
    } else {
      obj.sponsors = [];
    }
    if (message.selectedSponsorIds) {
      obj.selectedSponsorIds = message.selectedSponsorIds.map((e) => e);
    } else {
      obj.selectedSponsorIds = [];
    }
    if (message.clubUsers) {
      obj.clubUsers = message.clubUsers.map((e) => e ? ClubUser.toJSON(e) : undefined);
    } else {
      obj.clubUsers = [];
    }
    obj.pendingClubUsers = {};
    if (message.pendingClubUsers) {
      Object.entries(message.pendingClubUsers).forEach(([k, v]) => {
        obj.pendingClubUsers[k] = v;
      });
    }
    message.isSponsorsVisibleMainEventCheckout !== undefined &&
      (obj.isSponsorsVisibleMainEventCheckout = message.isSponsorsVisibleMainEventCheckout);
    if (message.clubImages) {
      obj.clubImages = message.clubImages.map((e) => e ? ClubImage.toJSON(e) : undefined);
    } else {
      obj.clubImages = [];
    }
    message.claimItemPrizeInstructions !== undefined &&
      (obj.claimItemPrizeInstructions = message.claimItemPrizeInstructions);
    if (message.coupons) {
      obj.coupons = message.coupons.map((e) => e ? MainEventCoupon.toJSON(e) : undefined);
    } else {
      obj.coupons = [];
    }
    if (message.campaigns) {
      obj.campaigns = message.campaigns.map((e) => e ? Campaign.toJSON(e) : undefined);
    } else {
      obj.campaigns = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClubSettings>, I>>(base?: I): ClubSettings {
    return ClubSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClubSettings>, I>>(object: I): ClubSettings {
    const message = createBaseClubSettings();
    message.clubId = object.clubId ?? "";
    message.lotteryEvent = (object.lotteryEvent !== undefined && object.lotteryEvent !== null)
      ? LotteryEvent.fromPartial(object.lotteryEvent)
      : undefined;
    message.entranceEvent = (object.entranceEvent !== undefined && object.entranceEvent !== null)
      ? EntranceEvent.fromPartial(object.entranceEvent)
      : undefined;
    message.daysBeforeSubEventsOpen = object.daysBeforeSubEventsOpen ?? 0;
    message.sponsors = object.sponsors?.map((e) => Sponsor.fromPartial(e)) || [];
    message.selectedSponsorIds = object.selectedSponsorIds?.map((e) => e) || [];
    message.clubUsers = object.clubUsers?.map((e) => ClubUser.fromPartial(e)) || [];
    message.pendingClubUsers = Object.entries(object.pendingClubUsers ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.isSponsorsVisibleMainEventCheckout = object.isSponsorsVisibleMainEventCheckout ?? false;
    message.clubImages = object.clubImages?.map((e) => ClubImage.fromPartial(e)) || [];
    message.claimItemPrizeInstructions = object.claimItemPrizeInstructions ?? "";
    message.coupons = object.coupons?.map((e) => MainEventCoupon.fromPartial(e)) || [];
    message.campaigns = object.campaigns?.map((e) => Campaign.fromPartial(e)) || [];
    return message;
  },
};

function createBaseClubSettings_PendingClubUsersEntry(): ClubSettings_PendingClubUsersEntry {
  return { key: "", value: "" };
}

export const ClubSettings_PendingClubUsersEntry = {
  encode(message: ClubSettings_PendingClubUsersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClubSettings_PendingClubUsersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClubSettings_PendingClubUsersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClubSettings_PendingClubUsersEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ClubSettings_PendingClubUsersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ClubSettings_PendingClubUsersEntry>, I>>(
    base?: I,
  ): ClubSettings_PendingClubUsersEntry {
    return ClubSettings_PendingClubUsersEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClubSettings_PendingClubUsersEntry>, I>>(
    object: I,
  ): ClubSettings_PendingClubUsersEntry {
    const message = createBaseClubSettings_PendingClubUsersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseClubUser(): ClubUser {
  return { uid: "", userType: ClubUserType.ADMIN, email: "" };
}

export const ClubUser = {
  encode(message: ClubUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uid !== "") {
      writer.uint32(10).string(message.uid);
    }
    if (message.userType !== ClubUserType.ADMIN) {
      writer.uint32(16).int32(clubUserTypeToNumber(message.userType));
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClubUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClubUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uid = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userType = clubUserTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClubUser {
    return {
      uid: isSet(object.uid) ? String(object.uid) : "",
      userType: isSet(object.userType) ? clubUserTypeFromJSON(object.userType) : ClubUserType.ADMIN,
      email: isSet(object.email) ? String(object.email) : "",
    };
  },

  toJSON(message: ClubUser): unknown {
    const obj: any = {};
    message.uid !== undefined && (obj.uid = message.uid);
    message.userType !== undefined && (obj.userType = clubUserTypeToJSON(message.userType));
    message.email !== undefined && (obj.email = message.email);
    return obj;
  },

  create<I extends Exact<DeepPartial<ClubUser>, I>>(base?: I): ClubUser {
    return ClubUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClubUser>, I>>(object: I): ClubUser {
    const message = createBaseClubUser();
    message.uid = object.uid ?? "";
    message.userType = object.userType ?? ClubUserType.ADMIN;
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseAddClubUserRequest(): AddClubUserRequest {
  return { email: "", userType: ClubUserType.ADMIN, clubId: "" };
}

export const AddClubUserRequest = {
  encode(message: AddClubUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.userType !== ClubUserType.ADMIN) {
      writer.uint32(16).int32(clubUserTypeToNumber(message.userType));
    }
    if (message.clubId !== "") {
      writer.uint32(26).string(message.clubId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddClubUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddClubUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userType = clubUserTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clubId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddClubUserRequest {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      userType: isSet(object.userType) ? clubUserTypeFromJSON(object.userType) : ClubUserType.ADMIN,
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
    };
  },

  toJSON(message: AddClubUserRequest): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.userType !== undefined && (obj.userType = clubUserTypeToJSON(message.userType));
    message.clubId !== undefined && (obj.clubId = message.clubId);
    return obj;
  },

  create<I extends Exact<DeepPartial<AddClubUserRequest>, I>>(base?: I): AddClubUserRequest {
    return AddClubUserRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AddClubUserRequest>, I>>(object: I): AddClubUserRequest {
    const message = createBaseAddClubUserRequest();
    message.email = object.email ?? "";
    message.userType = object.userType ?? ClubUserType.ADMIN;
    message.clubId = object.clubId ?? "";
    return message;
  },
};

function createBaseRemoveClubUserRequest(): RemoveClubUserRequest {
  return { clubId: "", email: "", isPending: false };
}

export const RemoveClubUserRequest = {
  encode(message: RemoveClubUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clubId !== "") {
      writer.uint32(10).string(message.clubId);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.isPending === true) {
      writer.uint32(24).bool(message.isPending);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveClubUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveClubUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isPending = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveClubUserRequest {
    return {
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      email: isSet(object.email) ? String(object.email) : "",
      isPending: isSet(object.isPending) ? Boolean(object.isPending) : false,
    };
  },

  toJSON(message: RemoveClubUserRequest): unknown {
    const obj: any = {};
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.email !== undefined && (obj.email = message.email);
    message.isPending !== undefined && (obj.isPending = message.isPending);
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveClubUserRequest>, I>>(base?: I): RemoveClubUserRequest {
    return RemoveClubUserRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RemoveClubUserRequest>, I>>(object: I): RemoveClubUserRequest {
    const message = createBaseRemoveClubUserRequest();
    message.clubId = object.clubId ?? "";
    message.email = object.email ?? "";
    message.isPending = object.isPending ?? false;
    return message;
  },
};

function createBaseClubImage(): ClubImage {
  return { id: "", imageUrl: "", name: "", type: ClubImageType.SPONSOR };
}

export const ClubImage = {
  encode(message: ClubImage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.imageUrl !== "") {
      writer.uint32(18).string(message.imageUrl);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.type !== ClubImageType.SPONSOR) {
      writer.uint32(32).int32(clubImageTypeToNumber(message.type));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClubImage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClubImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.type = clubImageTypeFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClubImage {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
      name: isSet(object.name) ? String(object.name) : "",
      type: isSet(object.type) ? clubImageTypeFromJSON(object.type) : ClubImageType.SPONSOR,
    };
  },

  toJSON(message: ClubImage): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = clubImageTypeToJSON(message.type));
    return obj;
  },

  create<I extends Exact<DeepPartial<ClubImage>, I>>(base?: I): ClubImage {
    return ClubImage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClubImage>, I>>(object: I): ClubImage {
    const message = createBaseClubImage();
    message.id = object.id ?? "";
    message.imageUrl = object.imageUrl ?? "";
    message.name = object.name ?? "";
    message.type = object.type ?? ClubImageType.SPONSOR;
    return message;
  },
};

function createBasePrize(): Prize {
  return {
    stakeShare: 0,
    itemValue: 0,
    itemName: "",
    imageUrl: "",
    description: "",
    lotteryTicketWinner: undefined,
    id: "",
    claimItemPrizeInstructions: "",
  };
}

export const Prize = {
  encode(message: Prize, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.stakeShare !== 0) {
      writer.uint32(9).double(message.stakeShare);
    }
    if (message.itemValue !== 0) {
      writer.uint32(17).double(message.itemValue);
    }
    if (message.itemName !== "") {
      writer.uint32(26).string(message.itemName);
    }
    if (message.imageUrl !== "") {
      writer.uint32(34).string(message.imageUrl);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.lotteryTicketWinner !== undefined) {
      LotteryTicketWinner.encode(message.lotteryTicketWinner, writer.uint32(50).fork()).ldelim();
    }
    if (message.id !== "") {
      writer.uint32(58).string(message.id);
    }
    if (message.claimItemPrizeInstructions !== "") {
      writer.uint32(66).string(message.claimItemPrizeInstructions);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Prize {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrize();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.stakeShare = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.itemValue = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.itemName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lotteryTicketWinner = LotteryTicketWinner.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.id = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.claimItemPrizeInstructions = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Prize {
    return {
      stakeShare: isSet(object.stakeShare) ? Number(object.stakeShare) : 0,
      itemValue: isSet(object.itemValue) ? Number(object.itemValue) : 0,
      itemName: isSet(object.itemName) ? String(object.itemName) : "",
      imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
      description: isSet(object.description) ? String(object.description) : "",
      lotteryTicketWinner: isSet(object.lotteryTicketWinner)
        ? LotteryTicketWinner.fromJSON(object.lotteryTicketWinner)
        : undefined,
      id: isSet(object.id) ? String(object.id) : "",
      claimItemPrizeInstructions: isSet(object.claimItemPrizeInstructions)
        ? String(object.claimItemPrizeInstructions)
        : "",
    };
  },

  toJSON(message: Prize): unknown {
    const obj: any = {};
    message.stakeShare !== undefined && (obj.stakeShare = message.stakeShare);
    message.itemValue !== undefined && (obj.itemValue = message.itemValue);
    message.itemName !== undefined && (obj.itemName = message.itemName);
    message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
    message.description !== undefined && (obj.description = message.description);
    message.lotteryTicketWinner !== undefined && (obj.lotteryTicketWinner = message.lotteryTicketWinner
      ? LotteryTicketWinner.toJSON(message.lotteryTicketWinner)
      : undefined);
    message.id !== undefined && (obj.id = message.id);
    message.claimItemPrizeInstructions !== undefined &&
      (obj.claimItemPrizeInstructions = message.claimItemPrizeInstructions);
    return obj;
  },

  create<I extends Exact<DeepPartial<Prize>, I>>(base?: I): Prize {
    return Prize.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Prize>, I>>(object: I): Prize {
    const message = createBasePrize();
    message.stakeShare = object.stakeShare ?? 0;
    message.itemValue = object.itemValue ?? 0;
    message.itemName = object.itemName ?? "";
    message.imageUrl = object.imageUrl ?? "";
    message.description = object.description ?? "";
    message.lotteryTicketWinner = (object.lotteryTicketWinner !== undefined && object.lotteryTicketWinner !== null)
      ? LotteryTicketWinner.fromPartial(object.lotteryTicketWinner)
      : undefined;
    message.id = object.id ?? "";
    message.claimItemPrizeInstructions = object.claimItemPrizeInstructions ?? "";
    return message;
  },
};

function createBaseStopMainEventPayload(): StopMainEventPayload {
  return { clubId: "", mainEventId: "" };
}

export const StopMainEventPayload = {
  encode(message: StopMainEventPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clubId !== "") {
      writer.uint32(10).string(message.clubId);
    }
    if (message.mainEventId !== "") {
      writer.uint32(18).string(message.mainEventId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StopMainEventPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopMainEventPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StopMainEventPayload {
    return {
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
    };
  },

  toJSON(message: StopMainEventPayload): unknown {
    const obj: any = {};
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    return obj;
  },

  create<I extends Exact<DeepPartial<StopMainEventPayload>, I>>(base?: I): StopMainEventPayload {
    return StopMainEventPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StopMainEventPayload>, I>>(object: I): StopMainEventPayload {
    const message = createBaseStopMainEventPayload();
    message.clubId = object.clubId ?? "";
    message.mainEventId = object.mainEventId ?? "";
    return message;
  },
};

function createBaseRaffleLotteryRequestPayload(): RaffleLotteryRequestPayload {
  return { mainEventId: "", clubId: "" };
}

export const RaffleLotteryRequestPayload = {
  encode(message: RaffleLotteryRequestPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mainEventId !== "") {
      writer.uint32(10).string(message.mainEventId);
    }
    if (message.clubId !== "") {
      writer.uint32(18).string(message.clubId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RaffleLotteryRequestPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRaffleLotteryRequestPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clubId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RaffleLotteryRequestPayload {
    return {
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
    };
  },

  toJSON(message: RaffleLotteryRequestPayload): unknown {
    const obj: any = {};
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    message.clubId !== undefined && (obj.clubId = message.clubId);
    return obj;
  },

  create<I extends Exact<DeepPartial<RaffleLotteryRequestPayload>, I>>(base?: I): RaffleLotteryRequestPayload {
    return RaffleLotteryRequestPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RaffleLotteryRequestPayload>, I>>(object: I): RaffleLotteryRequestPayload {
    const message = createBaseRaffleLotteryRequestPayload();
    message.mainEventId = object.mainEventId ?? "";
    message.clubId = object.clubId ?? "";
    return message;
  },
};

function createBaseConfirmOtpPayload(): ConfirmOtpPayload {
  return { phoneNumber: "", otp: "" };
}

export const ConfirmOtpPayload = {
  encode(message: ConfirmOtpPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phoneNumber !== "") {
      writer.uint32(10).string(message.phoneNumber);
    }
    if (message.otp !== "") {
      writer.uint32(18).string(message.otp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfirmOtpPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfirmOtpPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.otp = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfirmOtpPayload {
    return {
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
      otp: isSet(object.otp) ? String(object.otp) : "",
    };
  },

  toJSON(message: ConfirmOtpPayload): unknown {
    const obj: any = {};
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    message.otp !== undefined && (obj.otp = message.otp);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfirmOtpPayload>, I>>(base?: I): ConfirmOtpPayload {
    return ConfirmOtpPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConfirmOtpPayload>, I>>(object: I): ConfirmOtpPayload {
    const message = createBaseConfirmOtpPayload();
    message.phoneNumber = object.phoneNumber ?? "";
    message.otp = object.otp ?? "";
    return message;
  },
};

function createBaseRequestOtpPayload(): RequestOtpPayload {
  return { phoneNumber: "" };
}

export const RequestOtpPayload = {
  encode(message: RequestOtpPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phoneNumber !== "") {
      writer.uint32(10).string(message.phoneNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequestOtpPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestOtpPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RequestOtpPayload {
    return { phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "" };
  },

  toJSON(message: RequestOtpPayload): unknown {
    const obj: any = {};
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    return obj;
  },

  create<I extends Exact<DeepPartial<RequestOtpPayload>, I>>(base?: I): RequestOtpPayload {
    return RequestOtpPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RequestOtpPayload>, I>>(object: I): RequestOtpPayload {
    const message = createBaseRequestOtpPayload();
    message.phoneNumber = object.phoneNumber ?? "";
    return message;
  },
};

function createBaseUnpaidTransaction(): UnpaidTransaction {
  return {
    entranceTickets: [],
    lotteryTicketCount: 0,
    mainEventId: "",
    clubId: "",
    purchaseTimestamp: undefined,
    paidAmount: 0,
    supporterUid: "",
    paymentOption: PaymentOption.UNKNOWN,
    transactionId: "",
    userAgent: "",
    source: TransactionSource.SUPPORTER_WEBSITE,
    swishRequestUrl: "",
    tags: [],
    marketingChannel: MarketingChannel.NONE,
    instructionUUID: "",
    payee: PaymentPayee.CLUBMATE,
    physicalTicketNumber: "",
    clubName: "",
    position: undefined,
    supporterEmail: "",
    campaignId: "",
  };
}

export const UnpaidTransaction = {
  encode(message: UnpaidTransaction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.entranceTickets) {
      EntranceTicketClassCount.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.lotteryTicketCount !== 0) {
      writer.uint32(16).int32(message.lotteryTicketCount);
    }
    if (message.mainEventId !== "") {
      writer.uint32(26).string(message.mainEventId);
    }
    if (message.clubId !== "") {
      writer.uint32(34).string(message.clubId);
    }
    if (message.purchaseTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.purchaseTimestamp), writer.uint32(50).fork()).ldelim();
    }
    if (message.paidAmount !== 0) {
      writer.uint32(57).double(message.paidAmount);
    }
    if (message.supporterUid !== "") {
      writer.uint32(82).string(message.supporterUid);
    }
    if (message.paymentOption !== PaymentOption.UNKNOWN) {
      writer.uint32(88).int32(paymentOptionToNumber(message.paymentOption));
    }
    if (message.transactionId !== "") {
      writer.uint32(98).string(message.transactionId);
    }
    if (message.userAgent !== "") {
      writer.uint32(106).string(message.userAgent);
    }
    if (message.source !== TransactionSource.SUPPORTER_WEBSITE) {
      writer.uint32(112).int32(transactionSourceToNumber(message.source));
    }
    if (message.swishRequestUrl !== "") {
      writer.uint32(122).string(message.swishRequestUrl);
    }
    writer.uint32(130).fork();
    for (const v of message.tags) {
      writer.int32(supporterTransactionTagToNumber(v));
    }
    writer.ldelim();
    if (message.marketingChannel !== MarketingChannel.NONE) {
      writer.uint32(136).int32(marketingChannelToNumber(message.marketingChannel));
    }
    if (message.instructionUUID !== "") {
      writer.uint32(146).string(message.instructionUUID);
    }
    if (message.payee !== PaymentPayee.CLUBMATE) {
      writer.uint32(152).int32(paymentPayeeToNumber(message.payee));
    }
    if (message.physicalTicketNumber !== "") {
      writer.uint32(162).string(message.physicalTicketNumber);
    }
    if (message.clubName !== "") {
      writer.uint32(170).string(message.clubName);
    }
    if (message.position !== undefined) {
      Coordinates.encode(message.position, writer.uint32(178).fork()).ldelim();
    }
    if (message.supporterEmail !== "") {
      writer.uint32(186).string(message.supporterEmail);
    }
    if (message.campaignId !== "") {
      writer.uint32(194).string(message.campaignId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnpaidTransaction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnpaidTransaction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entranceTickets.push(EntranceTicketClassCount.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.lotteryTicketCount = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.purchaseTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.paidAmount = reader.double();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.supporterUid = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.paymentOption = paymentOptionFromJSON(reader.int32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.userAgent = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.source = transactionSourceFromJSON(reader.int32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.swishRequestUrl = reader.string();
          continue;
        case 16:
          if (tag === 128) {
            message.tags.push(supporterTransactionTagFromJSON(reader.int32()));

            continue;
          }

          if (tag === 130) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tags.push(supporterTransactionTagFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.marketingChannel = marketingChannelFromJSON(reader.int32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.instructionUUID = reader.string();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.payee = paymentPayeeFromJSON(reader.int32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.physicalTicketNumber = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.clubName = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.position = Coordinates.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.supporterEmail = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.campaignId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnpaidTransaction {
    return {
      entranceTickets: Array.isArray(object?.entranceTickets)
        ? object.entranceTickets.map((e: any) => EntranceTicketClassCount.fromJSON(e))
        : [],
      lotteryTicketCount: isSet(object.lotteryTicketCount) ? Number(object.lotteryTicketCount) : 0,
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      purchaseTimestamp: isSet(object.purchaseTimestamp) ? fromJsonTimestamp(object.purchaseTimestamp) : undefined,
      paidAmount: isSet(object.paidAmount) ? Number(object.paidAmount) : 0,
      supporterUid: isSet(object.supporterUid) ? String(object.supporterUid) : "",
      paymentOption: isSet(object.paymentOption) ? paymentOptionFromJSON(object.paymentOption) : PaymentOption.UNKNOWN,
      transactionId: isSet(object.transactionId) ? String(object.transactionId) : "",
      userAgent: isSet(object.userAgent) ? String(object.userAgent) : "",
      source: isSet(object.source) ? transactionSourceFromJSON(object.source) : TransactionSource.SUPPORTER_WEBSITE,
      swishRequestUrl: isSet(object.swishRequestUrl) ? String(object.swishRequestUrl) : "",
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => supporterTransactionTagFromJSON(e)) : [],
      marketingChannel: isSet(object.marketingChannel)
        ? marketingChannelFromJSON(object.marketingChannel)
        : MarketingChannel.NONE,
      instructionUUID: isSet(object.instructionUUID) ? String(object.instructionUUID) : "",
      payee: isSet(object.payee) ? paymentPayeeFromJSON(object.payee) : PaymentPayee.CLUBMATE,
      physicalTicketNumber: isSet(object.physicalTicketNumber) ? String(object.physicalTicketNumber) : "",
      clubName: isSet(object.clubName) ? String(object.clubName) : "",
      position: isSet(object.position) ? Coordinates.fromJSON(object.position) : undefined,
      supporterEmail: isSet(object.supporterEmail) ? String(object.supporterEmail) : "",
      campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
    };
  },

  toJSON(message: UnpaidTransaction): unknown {
    const obj: any = {};
    if (message.entranceTickets) {
      obj.entranceTickets = message.entranceTickets.map((e) => e ? EntranceTicketClassCount.toJSON(e) : undefined);
    } else {
      obj.entranceTickets = [];
    }
    message.lotteryTicketCount !== undefined && (obj.lotteryTicketCount = Math.round(message.lotteryTicketCount));
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.purchaseTimestamp !== undefined && (obj.purchaseTimestamp = message.purchaseTimestamp.toISOString());
    message.paidAmount !== undefined && (obj.paidAmount = message.paidAmount);
    message.supporterUid !== undefined && (obj.supporterUid = message.supporterUid);
    message.paymentOption !== undefined && (obj.paymentOption = paymentOptionToJSON(message.paymentOption));
    message.transactionId !== undefined && (obj.transactionId = message.transactionId);
    message.userAgent !== undefined && (obj.userAgent = message.userAgent);
    message.source !== undefined && (obj.source = transactionSourceToJSON(message.source));
    message.swishRequestUrl !== undefined && (obj.swishRequestUrl = message.swishRequestUrl);
    if (message.tags) {
      obj.tags = message.tags.map((e) => supporterTransactionTagToJSON(e));
    } else {
      obj.tags = [];
    }
    message.marketingChannel !== undefined && (obj.marketingChannel = marketingChannelToJSON(message.marketingChannel));
    message.instructionUUID !== undefined && (obj.instructionUUID = message.instructionUUID);
    message.payee !== undefined && (obj.payee = paymentPayeeToJSON(message.payee));
    message.physicalTicketNumber !== undefined && (obj.physicalTicketNumber = message.physicalTicketNumber);
    message.clubName !== undefined && (obj.clubName = message.clubName);
    message.position !== undefined &&
      (obj.position = message.position ? Coordinates.toJSON(message.position) : undefined);
    message.supporterEmail !== undefined && (obj.supporterEmail = message.supporterEmail);
    message.campaignId !== undefined && (obj.campaignId = message.campaignId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UnpaidTransaction>, I>>(base?: I): UnpaidTransaction {
    return UnpaidTransaction.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UnpaidTransaction>, I>>(object: I): UnpaidTransaction {
    const message = createBaseUnpaidTransaction();
    message.entranceTickets = object.entranceTickets?.map((e) => EntranceTicketClassCount.fromPartial(e)) || [];
    message.lotteryTicketCount = object.lotteryTicketCount ?? 0;
    message.mainEventId = object.mainEventId ?? "";
    message.clubId = object.clubId ?? "";
    message.purchaseTimestamp = object.purchaseTimestamp ?? undefined;
    message.paidAmount = object.paidAmount ?? 0;
    message.supporterUid = object.supporterUid ?? "";
    message.paymentOption = object.paymentOption ?? PaymentOption.UNKNOWN;
    message.transactionId = object.transactionId ?? "";
    message.userAgent = object.userAgent ?? "";
    message.source = object.source ?? TransactionSource.SUPPORTER_WEBSITE;
    message.swishRequestUrl = object.swishRequestUrl ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.marketingChannel = object.marketingChannel ?? MarketingChannel.NONE;
    message.instructionUUID = object.instructionUUID ?? "";
    message.payee = object.payee ?? PaymentPayee.CLUBMATE;
    message.physicalTicketNumber = object.physicalTicketNumber ?? "";
    message.clubName = object.clubName ?? "";
    message.position = (object.position !== undefined && object.position !== null)
      ? Coordinates.fromPartial(object.position)
      : undefined;
    message.supporterEmail = object.supporterEmail ?? "";
    message.campaignId = object.campaignId ?? "";
    return message;
  },
};

function createBaseSupporterTransaction(): SupporterTransaction {
  return {
    paidAmount: 0,
    entranceTickets: [],
    lotteryTicketNumbers: [],
    transactionId: "",
    mainEventId: "",
    paymentOption: PaymentOption.UNKNOWN,
    purchaseTimestamp: undefined,
    supporterUid: "",
    phoneNumber: "",
    source: TransactionSource.SUPPORTER_WEBSITE,
    isLotteryTicketTransaction: false,
    clubId: "",
    payee: PaymentPayee.CLUBMATE,
    swishPaymentId: "",
    position: undefined,
    isCustomLotteryTickets: false,
    emailSentTo: "",
    issuedEntranceTicketId: "",
    scannedEntranceTicketClassIds: [],
    scannedTimestamp: undefined,
    marketingChannel: MarketingChannel.NONE,
    tags: [],
    campaignId: "",
    coupons: [],
    isSupporterShop: false,
  };
}

export const SupporterTransaction = {
  encode(message: SupporterTransaction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paidAmount !== 0) {
      writer.uint32(9).double(message.paidAmount);
    }
    for (const v of message.entranceTickets) {
      EntranceTicketClassCount.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.lotteryTicketNumbers) {
      writer.uint32(34).string(v!);
    }
    if (message.transactionId !== "") {
      writer.uint32(42).string(message.transactionId);
    }
    if (message.mainEventId !== "") {
      writer.uint32(50).string(message.mainEventId);
    }
    if (message.paymentOption !== PaymentOption.UNKNOWN) {
      writer.uint32(80).int32(paymentOptionToNumber(message.paymentOption));
    }
    if (message.purchaseTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.purchaseTimestamp), writer.uint32(90).fork()).ldelim();
    }
    if (message.supporterUid !== "") {
      writer.uint32(98).string(message.supporterUid);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(114).string(message.phoneNumber);
    }
    if (message.source !== TransactionSource.SUPPORTER_WEBSITE) {
      writer.uint32(120).int32(transactionSourceToNumber(message.source));
    }
    if (message.isLotteryTicketTransaction === true) {
      writer.uint32(128).bool(message.isLotteryTicketTransaction);
    }
    if (message.clubId !== "") {
      writer.uint32(138).string(message.clubId);
    }
    if (message.payee !== PaymentPayee.CLUBMATE) {
      writer.uint32(144).int32(paymentPayeeToNumber(message.payee));
    }
    if (message.swishPaymentId !== "") {
      writer.uint32(202).string(message.swishPaymentId);
    }
    if (message.position !== undefined) {
      Coordinates.encode(message.position, writer.uint32(210).fork()).ldelim();
    }
    if (message.isCustomLotteryTickets === true) {
      writer.uint32(216).bool(message.isCustomLotteryTickets);
    }
    if (message.emailSentTo !== "") {
      writer.uint32(226).string(message.emailSentTo);
    }
    if (message.issuedEntranceTicketId !== "") {
      writer.uint32(234).string(message.issuedEntranceTicketId);
    }
    for (const v of message.scannedEntranceTicketClassIds) {
      writer.uint32(242).string(v!);
    }
    if (message.scannedTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.scannedTimestamp), writer.uint32(250).fork()).ldelim();
    }
    if (message.marketingChannel !== MarketingChannel.NONE) {
      writer.uint32(264).int32(marketingChannelToNumber(message.marketingChannel));
    }
    writer.uint32(274).fork();
    for (const v of message.tags) {
      writer.int32(supporterTransactionTagToNumber(v));
    }
    writer.ldelim();
    if (message.campaignId !== "") {
      writer.uint32(282).string(message.campaignId);
    }
    for (const v of message.coupons) {
      MainEventCoupon.encode(v!, writer.uint32(290).fork()).ldelim();
    }
    if (message.isSupporterShop === true) {
      writer.uint32(296).bool(message.isSupporterShop);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SupporterTransaction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSupporterTransaction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.paidAmount = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.entranceTickets.push(EntranceTicketClassCount.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lotteryTicketNumbers.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.paymentOption = paymentOptionFromJSON(reader.int32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.purchaseTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.supporterUid = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.source = transactionSourceFromJSON(reader.int32());
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.isLotteryTicketTransaction = reader.bool();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.payee = paymentPayeeFromJSON(reader.int32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.swishPaymentId = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.position = Coordinates.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.isCustomLotteryTickets = reader.bool();
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.emailSentTo = reader.string();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.issuedEntranceTicketId = reader.string();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.scannedEntranceTicketClassIds.push(reader.string());
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.scannedTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.marketingChannel = marketingChannelFromJSON(reader.int32());
          continue;
        case 34:
          if (tag === 272) {
            message.tags.push(supporterTransactionTagFromJSON(reader.int32()));

            continue;
          }

          if (tag === 274) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tags.push(supporterTransactionTagFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.campaignId = reader.string();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.coupons.push(MainEventCoupon.decode(reader, reader.uint32()));
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.isSupporterShop = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SupporterTransaction {
    return {
      paidAmount: isSet(object.paidAmount) ? Number(object.paidAmount) : 0,
      entranceTickets: Array.isArray(object?.entranceTickets)
        ? object.entranceTickets.map((e: any) => EntranceTicketClassCount.fromJSON(e))
        : [],
      lotteryTicketNumbers: Array.isArray(object?.lotteryTicketNumbers)
        ? object.lotteryTicketNumbers.map((e: any) => String(e))
        : [],
      transactionId: isSet(object.transactionId) ? String(object.transactionId) : "",
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      paymentOption: isSet(object.paymentOption) ? paymentOptionFromJSON(object.paymentOption) : PaymentOption.UNKNOWN,
      purchaseTimestamp: isSet(object.purchaseTimestamp) ? fromJsonTimestamp(object.purchaseTimestamp) : undefined,
      supporterUid: isSet(object.supporterUid) ? String(object.supporterUid) : "",
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
      source: isSet(object.source) ? transactionSourceFromJSON(object.source) : TransactionSource.SUPPORTER_WEBSITE,
      isLotteryTicketTransaction: isSet(object.isLotteryTicketTransaction)
        ? Boolean(object.isLotteryTicketTransaction)
        : false,
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      payee: isSet(object.payee) ? paymentPayeeFromJSON(object.payee) : PaymentPayee.CLUBMATE,
      swishPaymentId: isSet(object.swishPaymentId) ? String(object.swishPaymentId) : "",
      position: isSet(object.position) ? Coordinates.fromJSON(object.position) : undefined,
      isCustomLotteryTickets: isSet(object.isCustomLotteryTickets) ? Boolean(object.isCustomLotteryTickets) : false,
      emailSentTo: isSet(object.emailSentTo) ? String(object.emailSentTo) : "",
      issuedEntranceTicketId: isSet(object.issuedEntranceTicketId) ? String(object.issuedEntranceTicketId) : "",
      scannedEntranceTicketClassIds: Array.isArray(object?.scannedEntranceTicketClassIds)
        ? object.scannedEntranceTicketClassIds.map((e: any) => String(e))
        : [],
      scannedTimestamp: isSet(object.scannedTimestamp) ? fromJsonTimestamp(object.scannedTimestamp) : undefined,
      marketingChannel: isSet(object.marketingChannel)
        ? marketingChannelFromJSON(object.marketingChannel)
        : MarketingChannel.NONE,
      tags: Array.isArray(object?.tags)
        ? object.tags.map((e: any) => supporterTransactionTagFromJSON(e))
        : [],
      campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
      coupons: Array.isArray(object?.coupons)
        ? object.coupons.map((e: any) => MainEventCoupon.fromJSON(e))
        : [],
      isSupporterShop: isSet(object.isSupporterShop) ? Boolean(object.isSupporterShop) : false,
    };
  },

  toJSON(message: SupporterTransaction): unknown {
    const obj: any = {};
    message.paidAmount !== undefined && (obj.paidAmount = message.paidAmount);
    if (message.entranceTickets) {
      obj.entranceTickets = message.entranceTickets.map((e) => e ? EntranceTicketClassCount.toJSON(e) : undefined);
    } else {
      obj.entranceTickets = [];
    }
    if (message.lotteryTicketNumbers) {
      obj.lotteryTicketNumbers = message.lotteryTicketNumbers.map((e) => e);
    } else {
      obj.lotteryTicketNumbers = [];
    }
    message.transactionId !== undefined && (obj.transactionId = message.transactionId);
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    message.paymentOption !== undefined && (obj.paymentOption = paymentOptionToJSON(message.paymentOption));
    message.purchaseTimestamp !== undefined && (obj.purchaseTimestamp = message.purchaseTimestamp.toISOString());
    message.supporterUid !== undefined && (obj.supporterUid = message.supporterUid);
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    message.source !== undefined && (obj.source = transactionSourceToJSON(message.source));
    message.isLotteryTicketTransaction !== undefined &&
      (obj.isLotteryTicketTransaction = message.isLotteryTicketTransaction);
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.payee !== undefined && (obj.payee = paymentPayeeToJSON(message.payee));
    message.swishPaymentId !== undefined && (obj.swishPaymentId = message.swishPaymentId);
    message.position !== undefined &&
      (obj.position = message.position ? Coordinates.toJSON(message.position) : undefined);
    message.isCustomLotteryTickets !== undefined && (obj.isCustomLotteryTickets = message.isCustomLotteryTickets);
    message.emailSentTo !== undefined && (obj.emailSentTo = message.emailSentTo);
    message.issuedEntranceTicketId !== undefined && (obj.issuedEntranceTicketId = message.issuedEntranceTicketId);
    if (message.scannedEntranceTicketClassIds) {
      obj.scannedEntranceTicketClassIds = message.scannedEntranceTicketClassIds.map((e) => e);
    } else {
      obj.scannedEntranceTicketClassIds = [];
    }
    message.scannedTimestamp !== undefined && (obj.scannedTimestamp = message.scannedTimestamp.toISOString());
    message.marketingChannel !== undefined && (obj.marketingChannel = marketingChannelToJSON(message.marketingChannel));
    if (message.tags) {
      obj.tags = message.tags.map((e) => supporterTransactionTagToJSON(e));
    } else {
      obj.tags = [];
    }
    message.campaignId !== undefined && (obj.campaignId = message.campaignId);
    if (message.coupons) {
      obj.coupons = message.coupons.map((e) => e ? MainEventCoupon.toJSON(e) : undefined);
    } else {
      obj.coupons = [];
    }
    message.isSupporterShop !== undefined && (obj.isSupporterShop = message.isSupporterShop);
    return obj;
  },

  create<I extends Exact<DeepPartial<SupporterTransaction>, I>>(base?: I): SupporterTransaction {
    return SupporterTransaction.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SupporterTransaction>, I>>(object: I): SupporterTransaction {
    const message = createBaseSupporterTransaction();
    message.paidAmount = object.paidAmount ?? 0;
    message.entranceTickets = object.entranceTickets?.map((e) => EntranceTicketClassCount.fromPartial(e)) || [];
    message.lotteryTicketNumbers = object.lotteryTicketNumbers?.map((e) => e) || [];
    message.transactionId = object.transactionId ?? "";
    message.mainEventId = object.mainEventId ?? "";
    message.paymentOption = object.paymentOption ?? PaymentOption.UNKNOWN;
    message.purchaseTimestamp = object.purchaseTimestamp ?? undefined;
    message.supporterUid = object.supporterUid ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.source = object.source ?? TransactionSource.SUPPORTER_WEBSITE;
    message.isLotteryTicketTransaction = object.isLotteryTicketTransaction ?? false;
    message.clubId = object.clubId ?? "";
    message.payee = object.payee ?? PaymentPayee.CLUBMATE;
    message.swishPaymentId = object.swishPaymentId ?? "";
    message.position = (object.position !== undefined && object.position !== null)
      ? Coordinates.fromPartial(object.position)
      : undefined;
    message.isCustomLotteryTickets = object.isCustomLotteryTickets ?? false;
    message.emailSentTo = object.emailSentTo ?? "";
    message.issuedEntranceTicketId = object.issuedEntranceTicketId ?? "";
    message.scannedEntranceTicketClassIds = object.scannedEntranceTicketClassIds?.map((e) => e) || [];
    message.scannedTimestamp = object.scannedTimestamp ?? undefined;
    message.marketingChannel = object.marketingChannel ?? MarketingChannel.NONE;
    message.tags = object.tags?.map((e) => e) || [];
    message.campaignId = object.campaignId ?? "";
    message.coupons = object.coupons?.map((e) => MainEventCoupon.fromPartial(e)) || [];
    message.isSupporterShop = object.isSupporterShop ?? false;
    return message;
  },
};

function createBaseCreateCustomTokenWithPhoneNumberPayload(): CreateCustomTokenWithPhoneNumberPayload {
  return { phoneNumber: "" };
}

export const CreateCustomTokenWithPhoneNumberPayload = {
  encode(message: CreateCustomTokenWithPhoneNumberPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phoneNumber !== "") {
      writer.uint32(10).string(message.phoneNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCustomTokenWithPhoneNumberPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCustomTokenWithPhoneNumberPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateCustomTokenWithPhoneNumberPayload {
    return { phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "" };
  },

  toJSON(message: CreateCustomTokenWithPhoneNumberPayload): unknown {
    const obj: any = {};
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateCustomTokenWithPhoneNumberPayload>, I>>(
    base?: I,
  ): CreateCustomTokenWithPhoneNumberPayload {
    return CreateCustomTokenWithPhoneNumberPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateCustomTokenWithPhoneNumberPayload>, I>>(
    object: I,
  ): CreateCustomTokenWithPhoneNumberPayload {
    const message = createBaseCreateCustomTokenWithPhoneNumberPayload();
    message.phoneNumber = object.phoneNumber ?? "";
    return message;
  },
};

function createBaseSupporter(): Supporter {
  return {
    uid: "",
    supporterTransactions: [],
    phoneNumber: "",
    email: "",
    preferEmail: false,
    clubIdSubscriptions: [],
    couponUsageCounts: {},
  };
}

export const Supporter = {
  encode(message: Supporter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uid !== "") {
      writer.uint32(10).string(message.uid);
    }
    for (const v of message.supporterTransactions) {
      SupporterTransaction.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.phoneNumber !== "") {
      writer.uint32(26).string(message.phoneNumber);
    }
    if (message.email !== "") {
      writer.uint32(34).string(message.email);
    }
    if (message.preferEmail === true) {
      writer.uint32(40).bool(message.preferEmail);
    }
    for (const v of message.clubIdSubscriptions) {
      writer.uint32(50).string(v!);
    }
    Object.entries(message.couponUsageCounts).forEach(([key, value]) => {
      Supporter_CouponUsageCountsEntry.encode({ key: key as any, value }, writer.uint32(58).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Supporter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSupporter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.supporterTransactions.push(SupporterTransaction.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.email = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.preferEmail = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.clubIdSubscriptions.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = Supporter_CouponUsageCountsEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.couponUsageCounts[entry7.key] = entry7.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Supporter {
    return {
      uid: isSet(object.uid) ? String(object.uid) : "",
      supporterTransactions: Array.isArray(object?.supporterTransactions)
        ? object.supporterTransactions.map((e: any) => SupporterTransaction.fromJSON(e))
        : [],
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
      email: isSet(object.email) ? String(object.email) : "",
      preferEmail: isSet(object.preferEmail) ? Boolean(object.preferEmail) : false,
      clubIdSubscriptions: Array.isArray(object?.clubIdSubscriptions)
        ? object.clubIdSubscriptions.map((e: any) => String(e))
        : [],
      couponUsageCounts: isObject(object.couponUsageCounts)
        ? Object.entries(object.couponUsageCounts).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Supporter): unknown {
    const obj: any = {};
    message.uid !== undefined && (obj.uid = message.uid);
    if (message.supporterTransactions) {
      obj.supporterTransactions = message.supporterTransactions.map((e) =>
        e ? SupporterTransaction.toJSON(e) : undefined
      );
    } else {
      obj.supporterTransactions = [];
    }
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    message.email !== undefined && (obj.email = message.email);
    message.preferEmail !== undefined && (obj.preferEmail = message.preferEmail);
    if (message.clubIdSubscriptions) {
      obj.clubIdSubscriptions = message.clubIdSubscriptions.map((e) => e);
    } else {
      obj.clubIdSubscriptions = [];
    }
    obj.couponUsageCounts = {};
    if (message.couponUsageCounts) {
      Object.entries(message.couponUsageCounts).forEach(([k, v]) => {
        obj.couponUsageCounts[k] = Math.round(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Supporter>, I>>(base?: I): Supporter {
    return Supporter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Supporter>, I>>(object: I): Supporter {
    const message = createBaseSupporter();
    message.uid = object.uid ?? "";
    message.supporterTransactions = object.supporterTransactions?.map((e) => SupporterTransaction.fromPartial(e)) || [];
    message.phoneNumber = object.phoneNumber ?? "";
    message.email = object.email ?? "";
    message.preferEmail = object.preferEmail ?? false;
    message.clubIdSubscriptions = object.clubIdSubscriptions?.map((e) => e) || [];
    message.couponUsageCounts = Object.entries(object.couponUsageCounts ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Number(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseSupporter_CouponUsageCountsEntry(): Supporter_CouponUsageCountsEntry {
  return { key: "", value: 0 };
}

export const Supporter_CouponUsageCountsEntry = {
  encode(message: Supporter_CouponUsageCountsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Supporter_CouponUsageCountsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSupporter_CouponUsageCountsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Supporter_CouponUsageCountsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: Supporter_CouponUsageCountsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = Math.round(message.value));
    return obj;
  },

  create<I extends Exact<DeepPartial<Supporter_CouponUsageCountsEntry>, I>>(
    base?: I,
  ): Supporter_CouponUsageCountsEntry {
    return Supporter_CouponUsageCountsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Supporter_CouponUsageCountsEntry>, I>>(
    object: I,
  ): Supporter_CouponUsageCountsEntry {
    const message = createBaseSupporter_CouponUsageCountsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseSponsor(): Sponsor {
  return { id: "", name: "", logoUrl: "", websiteUrl: "" };
}

export const Sponsor = {
  encode(message: Sponsor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.logoUrl !== "") {
      writer.uint32(26).string(message.logoUrl);
    }
    if (message.websiteUrl !== "") {
      writer.uint32(34).string(message.websiteUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Sponsor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSponsor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.websiteUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Sponsor {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      logoUrl: isSet(object.logoUrl) ? String(object.logoUrl) : "",
      websiteUrl: isSet(object.websiteUrl) ? String(object.websiteUrl) : "",
    };
  },

  toJSON(message: Sponsor): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.logoUrl !== undefined && (obj.logoUrl = message.logoUrl);
    message.websiteUrl !== undefined && (obj.websiteUrl = message.websiteUrl);
    return obj;
  },

  create<I extends Exact<DeepPartial<Sponsor>, I>>(base?: I): Sponsor {
    return Sponsor.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Sponsor>, I>>(object: I): Sponsor {
    const message = createBaseSponsor();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.logoUrl = object.logoUrl ?? "";
    message.websiteUrl = object.websiteUrl ?? "";
    return message;
  },
};

function createBaseEventMessage(): EventMessage {
  return { imageUrl: "", title: "", text: "" };
}

export const EventMessage = {
  encode(message: EventMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageUrl !== "") {
      writer.uint32(10).string(message.imageUrl);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.text !== "") {
      writer.uint32(26).string(message.text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventMessage {
    return {
      imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
      title: isSet(object.title) ? String(object.title) : "",
      text: isSet(object.text) ? String(object.text) : "",
    };
  },

  toJSON(message: EventMessage): unknown {
    const obj: any = {};
    message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
    message.title !== undefined && (obj.title = message.title);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<EventMessage>, I>>(base?: I): EventMessage {
    return EventMessage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EventMessage>, I>>(object: I): EventMessage {
    const message = createBaseEventMessage();
    message.imageUrl = object.imageUrl ?? "";
    message.title = object.title ?? "";
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseMainEventCoupon(): MainEventCoupon {
  return {
    name: "",
    description: "",
    imageUrl: "",
    type: MainEventCouponType.ONE_PER_LOTTERY_TICKET,
    id: "",
    maxUsageCount: 0,
    expirationDate: undefined,
    minimumProductCount: 0,
  };
}

export const MainEventCoupon = {
  encode(message: MainEventCoupon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.imageUrl !== "") {
      writer.uint32(26).string(message.imageUrl);
    }
    if (message.type !== MainEventCouponType.ONE_PER_LOTTERY_TICKET) {
      writer.uint32(32).int32(mainEventCouponTypeToNumber(message.type));
    }
    if (message.id !== "") {
      writer.uint32(42).string(message.id);
    }
    if (message.maxUsageCount !== 0) {
      writer.uint32(48).int32(message.maxUsageCount);
    }
    if (message.expirationDate !== undefined) {
      Timestamp.encode(toTimestamp(message.expirationDate), writer.uint32(98).fork()).ldelim();
    }
    if (message.minimumProductCount !== 0) {
      writer.uint32(104).int32(message.minimumProductCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MainEventCoupon {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMainEventCoupon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.type = mainEventCouponTypeFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.id = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.maxUsageCount = reader.int32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.expirationDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.minimumProductCount = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MainEventCoupon {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
      type: isSet(object.type) ? mainEventCouponTypeFromJSON(object.type) : MainEventCouponType.ONE_PER_LOTTERY_TICKET,
      id: isSet(object.id) ? String(object.id) : "",
      maxUsageCount: isSet(object.maxUsageCount) ? Number(object.maxUsageCount) : 0,
      expirationDate: isSet(object.expirationDate) ? fromJsonTimestamp(object.expirationDate) : undefined,
      minimumProductCount: isSet(object.minimumProductCount) ? Number(object.minimumProductCount) : 0,
    };
  },

  toJSON(message: MainEventCoupon): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
    message.type !== undefined && (obj.type = mainEventCouponTypeToJSON(message.type));
    message.id !== undefined && (obj.id = message.id);
    message.maxUsageCount !== undefined && (obj.maxUsageCount = Math.round(message.maxUsageCount));
    message.expirationDate !== undefined && (obj.expirationDate = message.expirationDate.toISOString());
    message.minimumProductCount !== undefined && (obj.minimumProductCount = Math.round(message.minimumProductCount));
    return obj;
  },

  create<I extends Exact<DeepPartial<MainEventCoupon>, I>>(base?: I): MainEventCoupon {
    return MainEventCoupon.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MainEventCoupon>, I>>(object: I): MainEventCoupon {
    const message = createBaseMainEventCoupon();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.imageUrl = object.imageUrl ?? "";
    message.type = object.type ?? MainEventCouponType.ONE_PER_LOTTERY_TICKET;
    message.id = object.id ?? "";
    message.maxUsageCount = object.maxUsageCount ?? 0;
    message.expirationDate = object.expirationDate ?? undefined;
    message.minimumProductCount = object.minimumProductCount ?? 0;
    return message;
  },
};

function createBaseCampaign(): Campaign {
  return { id: "", name: "" };
}

export const Campaign = {
  encode(message: Campaign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Campaign {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCampaign();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Campaign {
    return { id: isSet(object.id) ? String(object.id) : "", name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: Campaign): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Campaign>, I>>(base?: I): Campaign {
    return Campaign.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Campaign>, I>>(object: I): Campaign {
    const message = createBaseCampaign();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseMainEvent(): MainEvent {
  return {
    entranceEvent: undefined,
    lotteryEvent: undefined,
    id: "",
    opponentClub: undefined,
    genericName: "",
    genericLogoUrl: "",
    mainEventType: MainEventType.GAME,
    sponsors: [],
    eventTime: undefined,
    startTime: undefined,
    endTime: undefined,
    hasEnded: false,
    teamTags: [],
    isSponsorsVisibleMainEventCheckout: false,
    createdTimestamp: undefined,
    coupons: [],
  };
}

export const MainEvent = {
  encode(message: MainEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entranceEvent !== undefined) {
      EntranceEvent.encode(message.entranceEvent, writer.uint32(18).fork()).ldelim();
    }
    if (message.lotteryEvent !== undefined) {
      LotteryEvent.encode(message.lotteryEvent, writer.uint32(26).fork()).ldelim();
    }
    if (message.id !== "") {
      writer.uint32(50).string(message.id);
    }
    if (message.opponentClub !== undefined) {
      OpponentClub.encode(message.opponentClub, writer.uint32(58).fork()).ldelim();
    }
    if (message.genericName !== "") {
      writer.uint32(66).string(message.genericName);
    }
    if (message.genericLogoUrl !== "") {
      writer.uint32(74).string(message.genericLogoUrl);
    }
    if (message.mainEventType !== MainEventType.GAME) {
      writer.uint32(80).int32(mainEventTypeToNumber(message.mainEventType));
    }
    for (const v of message.sponsors) {
      Sponsor.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.eventTime !== undefined) {
      Timestamp.encode(toTimestamp(message.eventTime), writer.uint32(98).fork()).ldelim();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(106).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(114).fork()).ldelim();
    }
    if (message.hasEnded === true) {
      writer.uint32(120).bool(message.hasEnded);
    }
    for (const v of message.teamTags) {
      writer.uint32(130).string(v!);
    }
    if (message.isSponsorsVisibleMainEventCheckout === true) {
      writer.uint32(136).bool(message.isSponsorsVisibleMainEventCheckout);
    }
    if (message.createdTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.createdTimestamp), writer.uint32(146).fork()).ldelim();
    }
    for (const v of message.coupons) {
      MainEventCoupon.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MainEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMainEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entranceEvent = EntranceEvent.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lotteryEvent = LotteryEvent.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.id = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.opponentClub = OpponentClub.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.genericName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.genericLogoUrl = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.mainEventType = mainEventTypeFromJSON(reader.int32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.sponsors.push(Sponsor.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.eventTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.hasEnded = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.teamTags.push(reader.string());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.isSponsorsVisibleMainEventCheckout = reader.bool();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.createdTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.coupons.push(MainEventCoupon.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MainEvent {
    return {
      entranceEvent: isSet(object.entranceEvent) ? EntranceEvent.fromJSON(object.entranceEvent) : undefined,
      lotteryEvent: isSet(object.lotteryEvent) ? LotteryEvent.fromJSON(object.lotteryEvent) : undefined,
      id: isSet(object.id) ? String(object.id) : "",
      opponentClub: isSet(object.opponentClub) ? OpponentClub.fromJSON(object.opponentClub) : undefined,
      genericName: isSet(object.genericName) ? String(object.genericName) : "",
      genericLogoUrl: isSet(object.genericLogoUrl) ? String(object.genericLogoUrl) : "",
      mainEventType: isSet(object.mainEventType) ? mainEventTypeFromJSON(object.mainEventType) : MainEventType.GAME,
      sponsors: Array.isArray(object?.sponsors) ? object.sponsors.map((e: any) => Sponsor.fromJSON(e)) : [],
      eventTime: isSet(object.eventTime) ? fromJsonTimestamp(object.eventTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      hasEnded: isSet(object.hasEnded) ? Boolean(object.hasEnded) : false,
      teamTags: Array.isArray(object?.teamTags) ? object.teamTags.map((e: any) => String(e)) : [],
      isSponsorsVisibleMainEventCheckout: isSet(object.isSponsorsVisibleMainEventCheckout)
        ? Boolean(object.isSponsorsVisibleMainEventCheckout)
        : false,
      createdTimestamp: isSet(object.createdTimestamp) ? fromJsonTimestamp(object.createdTimestamp) : undefined,
      coupons: Array.isArray(object?.coupons) ? object.coupons.map((e: any) => MainEventCoupon.fromJSON(e)) : [],
    };
  },

  toJSON(message: MainEvent): unknown {
    const obj: any = {};
    message.entranceEvent !== undefined &&
      (obj.entranceEvent = message.entranceEvent ? EntranceEvent.toJSON(message.entranceEvent) : undefined);
    message.lotteryEvent !== undefined &&
      (obj.lotteryEvent = message.lotteryEvent ? LotteryEvent.toJSON(message.lotteryEvent) : undefined);
    message.id !== undefined && (obj.id = message.id);
    message.opponentClub !== undefined &&
      (obj.opponentClub = message.opponentClub ? OpponentClub.toJSON(message.opponentClub) : undefined);
    message.genericName !== undefined && (obj.genericName = message.genericName);
    message.genericLogoUrl !== undefined && (obj.genericLogoUrl = message.genericLogoUrl);
    message.mainEventType !== undefined && (obj.mainEventType = mainEventTypeToJSON(message.mainEventType));
    if (message.sponsors) {
      obj.sponsors = message.sponsors.map((e) => e ? Sponsor.toJSON(e) : undefined);
    } else {
      obj.sponsors = [];
    }
    message.eventTime !== undefined && (obj.eventTime = message.eventTime.toISOString());
    message.startTime !== undefined && (obj.startTime = message.startTime.toISOString());
    message.endTime !== undefined && (obj.endTime = message.endTime.toISOString());
    message.hasEnded !== undefined && (obj.hasEnded = message.hasEnded);
    if (message.teamTags) {
      obj.teamTags = message.teamTags.map((e) => e);
    } else {
      obj.teamTags = [];
    }
    message.isSponsorsVisibleMainEventCheckout !== undefined &&
      (obj.isSponsorsVisibleMainEventCheckout = message.isSponsorsVisibleMainEventCheckout);
    message.createdTimestamp !== undefined && (obj.createdTimestamp = message.createdTimestamp.toISOString());
    if (message.coupons) {
      obj.coupons = message.coupons.map((e) => e ? MainEventCoupon.toJSON(e) : undefined);
    } else {
      obj.coupons = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MainEvent>, I>>(base?: I): MainEvent {
    return MainEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MainEvent>, I>>(object: I): MainEvent {
    const message = createBaseMainEvent();
    message.entranceEvent = (object.entranceEvent !== undefined && object.entranceEvent !== null)
      ? EntranceEvent.fromPartial(object.entranceEvent)
      : undefined;
    message.lotteryEvent = (object.lotteryEvent !== undefined && object.lotteryEvent !== null)
      ? LotteryEvent.fromPartial(object.lotteryEvent)
      : undefined;
    message.id = object.id ?? "";
    message.opponentClub = (object.opponentClub !== undefined && object.opponentClub !== null)
      ? OpponentClub.fromPartial(object.opponentClub)
      : undefined;
    message.genericName = object.genericName ?? "";
    message.genericLogoUrl = object.genericLogoUrl ?? "";
    message.mainEventType = object.mainEventType ?? MainEventType.GAME;
    message.sponsors = object.sponsors?.map((e) => Sponsor.fromPartial(e)) || [];
    message.eventTime = object.eventTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.hasEnded = object.hasEnded ?? false;
    message.teamTags = object.teamTags?.map((e) => e) || [];
    message.isSponsorsVisibleMainEventCheckout = object.isSponsorsVisibleMainEventCheckout ?? false;
    message.createdTimestamp = object.createdTimestamp ?? undefined;
    message.coupons = object.coupons?.map((e) => MainEventCoupon.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEntranceEvent(): EntranceEvent {
  return { entranceTickets: [], freeText: "", endTime: undefined, hasEnded: false };
}

export const EntranceEvent = {
  encode(message: EntranceEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.entranceTickets) {
      EntranceTicketClass.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.freeText !== "") {
      writer.uint32(18).string(message.freeText);
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.hasEnded === true) {
      writer.uint32(32).bool(message.hasEnded);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntranceEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntranceEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entranceTickets.push(EntranceTicketClass.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.freeText = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hasEnded = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntranceEvent {
    return {
      entranceTickets: Array.isArray(object?.entranceTickets)
        ? object.entranceTickets.map((e: any) => EntranceTicketClass.fromJSON(e))
        : [],
      freeText: isSet(object.freeText) ? String(object.freeText) : "",
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      hasEnded: isSet(object.hasEnded) ? Boolean(object.hasEnded) : false,
    };
  },

  toJSON(message: EntranceEvent): unknown {
    const obj: any = {};
    if (message.entranceTickets) {
      obj.entranceTickets = message.entranceTickets.map((e) => e ? EntranceTicketClass.toJSON(e) : undefined);
    } else {
      obj.entranceTickets = [];
    }
    message.freeText !== undefined && (obj.freeText = message.freeText);
    message.endTime !== undefined && (obj.endTime = message.endTime.toISOString());
    message.hasEnded !== undefined && (obj.hasEnded = message.hasEnded);
    return obj;
  },

  create<I extends Exact<DeepPartial<EntranceEvent>, I>>(base?: I): EntranceEvent {
    return EntranceEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EntranceEvent>, I>>(object: I): EntranceEvent {
    const message = createBaseEntranceEvent();
    message.entranceTickets = object.entranceTickets?.map((e) => EntranceTicketClass.fromPartial(e)) || [];
    message.freeText = object.freeText ?? "";
    message.endTime = object.endTime ?? undefined;
    message.hasEnded = object.hasEnded ?? false;
    return message;
  },
};

function createBaseLotteryEvent(): LotteryEvent {
  return {
    lotteryPrices: {},
    prizes: [],
    belowMinimumStakePolicy: StakeLimitPolicy.STOP_SELLING,
    endTime: undefined,
    hasEnded: false,
    stakeValue: 0,
    hiddenPriceQuantities: [],
    roundPrizeMoney: false,
    freeText: "",
  };
}

export const LotteryEvent = {
  encode(message: LotteryEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.lotteryPrices).forEach(([key, value]) => {
      LotteryEvent_LotteryPricesEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    for (const v of message.prizes) {
      Prize.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.belowMinimumStakePolicy !== StakeLimitPolicy.STOP_SELLING) {
      writer.uint32(48).int32(stakeLimitPolicyToNumber(message.belowMinimumStakePolicy));
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(58).fork()).ldelim();
    }
    if (message.hasEnded === true) {
      writer.uint32(64).bool(message.hasEnded);
    }
    if (message.stakeValue !== 0) {
      writer.uint32(81).double(message.stakeValue);
    }
    writer.uint32(90).fork();
    for (const v of message.hiddenPriceQuantities) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.roundPrizeMoney === true) {
      writer.uint32(96).bool(message.roundPrizeMoney);
    }
    if (message.freeText !== "") {
      writer.uint32(106).string(message.freeText);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LotteryEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLotteryEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = LotteryEvent_LotteryPricesEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.lotteryPrices[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.prizes.push(Prize.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.belowMinimumStakePolicy = stakeLimitPolicyFromJSON(reader.int32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.hasEnded = reader.bool();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.stakeValue = reader.double();
          continue;
        case 11:
          if (tag === 88) {
            message.hiddenPriceQuantities.push(reader.int32());

            continue;
          }

          if (tag === 90) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.hiddenPriceQuantities.push(reader.int32());
            }

            continue;
          }

          break;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.roundPrizeMoney = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.freeText = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LotteryEvent {
    return {
      lotteryPrices: isObject(object.lotteryPrices)
        ? Object.entries(object.lotteryPrices).reduce<{ [key: number]: number }>((acc, [key, value]) => {
          acc[Number(key)] = Number(value);
          return acc;
        }, {})
        : {},
      prizes: Array.isArray(object?.prizes) ? object.prizes.map((e: any) => Prize.fromJSON(e)) : [],
      belowMinimumStakePolicy: isSet(object.belowMinimumStakePolicy)
        ? stakeLimitPolicyFromJSON(object.belowMinimumStakePolicy)
        : StakeLimitPolicy.STOP_SELLING,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      hasEnded: isSet(object.hasEnded) ? Boolean(object.hasEnded) : false,
      stakeValue: isSet(object.stakeValue) ? Number(object.stakeValue) : 0,
      hiddenPriceQuantities: Array.isArray(object?.hiddenPriceQuantities)
        ? object.hiddenPriceQuantities.map((e: any) => Number(e))
        : [],
      roundPrizeMoney: isSet(object.roundPrizeMoney) ? Boolean(object.roundPrizeMoney) : false,
      freeText: isSet(object.freeText) ? String(object.freeText) : "",
    };
  },

  toJSON(message: LotteryEvent): unknown {
    const obj: any = {};
    obj.lotteryPrices = {};
    if (message.lotteryPrices) {
      Object.entries(message.lotteryPrices).forEach(([k, v]) => {
        obj.lotteryPrices[k] = v;
      });
    }
    if (message.prizes) {
      obj.prizes = message.prizes.map((e) => e ? Prize.toJSON(e) : undefined);
    } else {
      obj.prizes = [];
    }
    message.belowMinimumStakePolicy !== undefined &&
      (obj.belowMinimumStakePolicy = stakeLimitPolicyToJSON(message.belowMinimumStakePolicy));
    message.endTime !== undefined && (obj.endTime = message.endTime.toISOString());
    message.hasEnded !== undefined && (obj.hasEnded = message.hasEnded);
    message.stakeValue !== undefined && (obj.stakeValue = message.stakeValue);
    if (message.hiddenPriceQuantities) {
      obj.hiddenPriceQuantities = message.hiddenPriceQuantities.map((e) => Math.round(e));
    } else {
      obj.hiddenPriceQuantities = [];
    }
    message.roundPrizeMoney !== undefined && (obj.roundPrizeMoney = message.roundPrizeMoney);
    message.freeText !== undefined && (obj.freeText = message.freeText);
    return obj;
  },

  create<I extends Exact<DeepPartial<LotteryEvent>, I>>(base?: I): LotteryEvent {
    return LotteryEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LotteryEvent>, I>>(object: I): LotteryEvent {
    const message = createBaseLotteryEvent();
    message.lotteryPrices = Object.entries(object.lotteryPrices ?? {}).reduce<{ [key: number]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[Number(key)] = Number(value);
        }
        return acc;
      },
      {},
    );
    message.prizes = object.prizes?.map((e) => Prize.fromPartial(e)) || [];
    message.belowMinimumStakePolicy = object.belowMinimumStakePolicy ?? StakeLimitPolicy.STOP_SELLING;
    message.endTime = object.endTime ?? undefined;
    message.hasEnded = object.hasEnded ?? false;
    message.stakeValue = object.stakeValue ?? 0;
    message.hiddenPriceQuantities = object.hiddenPriceQuantities?.map((e) => e) || [];
    message.roundPrizeMoney = object.roundPrizeMoney ?? false;
    message.freeText = object.freeText ?? "";
    return message;
  },
};

function createBaseLotteryEvent_LotteryPricesEntry(): LotteryEvent_LotteryPricesEntry {
  return { key: 0, value: 0 };
}

export const LotteryEvent_LotteryPricesEntry = {
  encode(message: LotteryEvent_LotteryPricesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(17).double(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LotteryEvent_LotteryPricesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLotteryEvent_LotteryPricesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LotteryEvent_LotteryPricesEntry {
    return { key: isSet(object.key) ? Number(object.key) : 0, value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: LotteryEvent_LotteryPricesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = Math.round(message.key));
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<LotteryEvent_LotteryPricesEntry>, I>>(base?: I): LotteryEvent_LotteryPricesEntry {
    return LotteryEvent_LotteryPricesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LotteryEvent_LotteryPricesEntry>, I>>(
    object: I,
  ): LotteryEvent_LotteryPricesEntry {
    const message = createBaseLotteryEvent_LotteryPricesEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseLotteryTicketWinner(): LotteryTicketWinner {
  return {
    transactionSource: TransactionSource.SUPPORTER_WEBSITE,
    lotteryTicketNumber: "",
    smsSent: false,
    transactionId: "",
    emailSent: false,
    paidPrizeMoney: 0,
    paidTimestamp: undefined,
  };
}

export const LotteryTicketWinner = {
  encode(message: LotteryTicketWinner, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionSource !== TransactionSource.SUPPORTER_WEBSITE) {
      writer.uint32(8).int32(transactionSourceToNumber(message.transactionSource));
    }
    if (message.lotteryTicketNumber !== "") {
      writer.uint32(34).string(message.lotteryTicketNumber);
    }
    if (message.smsSent === true) {
      writer.uint32(40).bool(message.smsSent);
    }
    if (message.transactionId !== "") {
      writer.uint32(50).string(message.transactionId);
    }
    if (message.emailSent === true) {
      writer.uint32(56).bool(message.emailSent);
    }
    if (message.paidPrizeMoney !== 0) {
      writer.uint32(64).int32(message.paidPrizeMoney);
    }
    if (message.paidTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.paidTimestamp), writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LotteryTicketWinner {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLotteryTicketWinner();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.transactionSource = transactionSourceFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lotteryTicketNumber = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.smsSent = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.emailSent = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.paidPrizeMoney = reader.int32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.paidTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LotteryTicketWinner {
    return {
      transactionSource: isSet(object.transactionSource)
        ? transactionSourceFromJSON(object.transactionSource)
        : TransactionSource.SUPPORTER_WEBSITE,
      lotteryTicketNumber: isSet(object.lotteryTicketNumber) ? String(object.lotteryTicketNumber) : "",
      smsSent: isSet(object.smsSent) ? Boolean(object.smsSent) : false,
      transactionId: isSet(object.transactionId) ? String(object.transactionId) : "",
      emailSent: isSet(object.emailSent) ? Boolean(object.emailSent) : false,
      paidPrizeMoney: isSet(object.paidPrizeMoney) ? Number(object.paidPrizeMoney) : 0,
      paidTimestamp: isSet(object.paidTimestamp) ? fromJsonTimestamp(object.paidTimestamp) : undefined,
    };
  },

  toJSON(message: LotteryTicketWinner): unknown {
    const obj: any = {};
    message.transactionSource !== undefined &&
      (obj.transactionSource = transactionSourceToJSON(message.transactionSource));
    message.lotteryTicketNumber !== undefined && (obj.lotteryTicketNumber = message.lotteryTicketNumber);
    message.smsSent !== undefined && (obj.smsSent = message.smsSent);
    message.transactionId !== undefined && (obj.transactionId = message.transactionId);
    message.emailSent !== undefined && (obj.emailSent = message.emailSent);
    message.paidPrizeMoney !== undefined && (obj.paidPrizeMoney = Math.round(message.paidPrizeMoney));
    message.paidTimestamp !== undefined && (obj.paidTimestamp = message.paidTimestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<LotteryTicketWinner>, I>>(base?: I): LotteryTicketWinner {
    return LotteryTicketWinner.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LotteryTicketWinner>, I>>(object: I): LotteryTicketWinner {
    const message = createBaseLotteryTicketWinner();
    message.transactionSource = object.transactionSource ?? TransactionSource.SUPPORTER_WEBSITE;
    message.lotteryTicketNumber = object.lotteryTicketNumber ?? "";
    message.smsSent = object.smsSent ?? false;
    message.transactionId = object.transactionId ?? "";
    message.emailSent = object.emailSent ?? false;
    message.paidPrizeMoney = object.paidPrizeMoney ?? 0;
    message.paidTimestamp = object.paidTimestamp ?? undefined;
    return message;
  },
};

function createBaseOpponentClub(): OpponentClub {
  return { name: "", logoUrl: "", clubCategory: ClubCategory.FOOTBALL };
}

export const OpponentClub = {
  encode(message: OpponentClub, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.logoUrl !== "") {
      writer.uint32(26).string(message.logoUrl);
    }
    if (message.clubCategory !== ClubCategory.FOOTBALL) {
      writer.uint32(32).int32(clubCategoryToNumber(message.clubCategory));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OpponentClub {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpponentClub();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.clubCategory = clubCategoryFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpponentClub {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      logoUrl: isSet(object.logoUrl) ? String(object.logoUrl) : "",
      clubCategory: isSet(object.clubCategory) ? clubCategoryFromJSON(object.clubCategory) : ClubCategory.FOOTBALL,
    };
  },

  toJSON(message: OpponentClub): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.logoUrl !== undefined && (obj.logoUrl = message.logoUrl);
    message.clubCategory !== undefined && (obj.clubCategory = clubCategoryToJSON(message.clubCategory));
    return obj;
  },

  create<I extends Exact<DeepPartial<OpponentClub>, I>>(base?: I): OpponentClub {
    return OpponentClub.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OpponentClub>, I>>(object: I): OpponentClub {
    const message = createBaseOpponentClub();
    message.name = object.name ?? "";
    message.logoUrl = object.logoUrl ?? "";
    message.clubCategory = object.clubCategory ?? ClubCategory.FOOTBALL;
    return message;
  },
};

function createBaseSendWinnerSmsPayload(): SendWinnerSmsPayload {
  return { clubId: "", mainEventId: "", prizeIds: [] };
}

export const SendWinnerSmsPayload = {
  encode(message: SendWinnerSmsPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clubId !== "") {
      writer.uint32(10).string(message.clubId);
    }
    if (message.mainEventId !== "") {
      writer.uint32(18).string(message.mainEventId);
    }
    for (const v of message.prizeIds) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendWinnerSmsPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendWinnerSmsPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.prizeIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendWinnerSmsPayload {
    return {
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      prizeIds: Array.isArray(object?.prizeIds) ? object.prizeIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SendWinnerSmsPayload): unknown {
    const obj: any = {};
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    if (message.prizeIds) {
      obj.prizeIds = message.prizeIds.map((e) => e);
    } else {
      obj.prizeIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendWinnerSmsPayload>, I>>(base?: I): SendWinnerSmsPayload {
    return SendWinnerSmsPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SendWinnerSmsPayload>, I>>(object: I): SendWinnerSmsPayload {
    const message = createBaseSendWinnerSmsPayload();
    message.clubId = object.clubId ?? "";
    message.mainEventId = object.mainEventId ?? "";
    message.prizeIds = object.prizeIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseTransactionResult(): TransactionResult {
  return { customToken: "", supporterTransaction: undefined, paymentStatus: "", errorCode: "" };
}

export const TransactionResult = {
  encode(message: TransactionResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customToken !== "") {
      writer.uint32(10).string(message.customToken);
    }
    if (message.supporterTransaction !== undefined) {
      SupporterTransaction.encode(message.supporterTransaction, writer.uint32(18).fork()).ldelim();
    }
    if (message.paymentStatus !== "") {
      writer.uint32(34).string(message.paymentStatus);
    }
    if (message.errorCode !== "") {
      writer.uint32(42).string(message.errorCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.supporterTransaction = SupporterTransaction.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.paymentStatus = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.errorCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionResult {
    return {
      customToken: isSet(object.customToken) ? String(object.customToken) : "",
      supporterTransaction: isSet(object.supporterTransaction)
        ? SupporterTransaction.fromJSON(object.supporterTransaction)
        : undefined,
      paymentStatus: isSet(object.paymentStatus) ? String(object.paymentStatus) : "",
      errorCode: isSet(object.errorCode) ? String(object.errorCode) : "",
    };
  },

  toJSON(message: TransactionResult): unknown {
    const obj: any = {};
    message.customToken !== undefined && (obj.customToken = message.customToken);
    message.supporterTransaction !== undefined && (obj.supporterTransaction = message.supporterTransaction
      ? SupporterTransaction.toJSON(message.supporterTransaction)
      : undefined);
    message.paymentStatus !== undefined && (obj.paymentStatus = message.paymentStatus);
    message.errorCode !== undefined && (obj.errorCode = message.errorCode);
    return obj;
  },

  create<I extends Exact<DeepPartial<TransactionResult>, I>>(base?: I): TransactionResult {
    return TransactionResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TransactionResult>, I>>(object: I): TransactionResult {
    const message = createBaseTransactionResult();
    message.customToken = object.customToken ?? "";
    message.supporterTransaction = (object.supporterTransaction !== undefined && object.supporterTransaction !== null)
      ? SupporterTransaction.fromPartial(object.supporterTransaction)
      : undefined;
    message.paymentStatus = object.paymentStatus ?? "";
    message.errorCode = object.errorCode ?? "";
    return message;
  },
};

function createBasePayoutRequest(): PayoutRequest {
  return {
    ssn: "",
    phoneNumber: "",
    mainEventId: "",
    clubId: "",
    transactionId: "",
    prizeId: "",
    donatePrizeMoney: false,
  };
}

export const PayoutRequest = {
  encode(message: PayoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ssn !== "") {
      writer.uint32(10).string(message.ssn);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(18).string(message.phoneNumber);
    }
    if (message.mainEventId !== "") {
      writer.uint32(26).string(message.mainEventId);
    }
    if (message.clubId !== "") {
      writer.uint32(34).string(message.clubId);
    }
    if (message.transactionId !== "") {
      writer.uint32(42).string(message.transactionId);
    }
    if (message.prizeId !== "") {
      writer.uint32(50).string(message.prizeId);
    }
    if (message.donatePrizeMoney === true) {
      writer.uint32(56).bool(message.donatePrizeMoney);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayoutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ssn = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.prizeId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.donatePrizeMoney = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayoutRequest {
    return {
      ssn: isSet(object.ssn) ? String(object.ssn) : "",
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      transactionId: isSet(object.transactionId) ? String(object.transactionId) : "",
      prizeId: isSet(object.prizeId) ? String(object.prizeId) : "",
      donatePrizeMoney: isSet(object.donatePrizeMoney) ? Boolean(object.donatePrizeMoney) : false,
    };
  },

  toJSON(message: PayoutRequest): unknown {
    const obj: any = {};
    message.ssn !== undefined && (obj.ssn = message.ssn);
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.transactionId !== undefined && (obj.transactionId = message.transactionId);
    message.prizeId !== undefined && (obj.prizeId = message.prizeId);
    message.donatePrizeMoney !== undefined && (obj.donatePrizeMoney = message.donatePrizeMoney);
    return obj;
  },

  create<I extends Exact<DeepPartial<PayoutRequest>, I>>(base?: I): PayoutRequest {
    return PayoutRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PayoutRequest>, I>>(object: I): PayoutRequest {
    const message = createBasePayoutRequest();
    message.ssn = object.ssn ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.mainEventId = object.mainEventId ?? "";
    message.clubId = object.clubId ?? "";
    message.transactionId = object.transactionId ?? "";
    message.prizeId = object.prizeId ?? "";
    message.donatePrizeMoney = object.donatePrizeMoney ?? false;
    return message;
  },
};

function createBasePayout(): Payout {
  return {
    mainEventId: "",
    clubId: "",
    ssn: "",
    phoneNumber: "",
    paymentReference: "",
    amount: "",
    paidTimestamp: undefined,
    trasnactionId: "",
    supporterUid: "",
    prizeId: "",
  };
}

export const Payout = {
  encode(message: Payout, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mainEventId !== "") {
      writer.uint32(10).string(message.mainEventId);
    }
    if (message.clubId !== "") {
      writer.uint32(18).string(message.clubId);
    }
    if (message.ssn !== "") {
      writer.uint32(26).string(message.ssn);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(34).string(message.phoneNumber);
    }
    if (message.paymentReference !== "") {
      writer.uint32(42).string(message.paymentReference);
    }
    if (message.amount !== "") {
      writer.uint32(50).string(message.amount);
    }
    if (message.paidTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.paidTimestamp), writer.uint32(58).fork()).ldelim();
    }
    if (message.trasnactionId !== "") {
      writer.uint32(66).string(message.trasnactionId);
    }
    if (message.supporterUid !== "") {
      writer.uint32(74).string(message.supporterUid);
    }
    if (message.prizeId !== "") {
      writer.uint32(82).string(message.prizeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Payout {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayout();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ssn = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.paymentReference = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.amount = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.paidTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.trasnactionId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.supporterUid = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.prizeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Payout {
    return {
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      ssn: isSet(object.ssn) ? String(object.ssn) : "",
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
      paymentReference: isSet(object.paymentReference) ? String(object.paymentReference) : "",
      amount: isSet(object.amount) ? String(object.amount) : "",
      paidTimestamp: isSet(object.paidTimestamp) ? fromJsonTimestamp(object.paidTimestamp) : undefined,
      trasnactionId: isSet(object.trasnactionId) ? String(object.trasnactionId) : "",
      supporterUid: isSet(object.supporterUid) ? String(object.supporterUid) : "",
      prizeId: isSet(object.prizeId) ? String(object.prizeId) : "",
    };
  },

  toJSON(message: Payout): unknown {
    const obj: any = {};
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.ssn !== undefined && (obj.ssn = message.ssn);
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    message.paymentReference !== undefined && (obj.paymentReference = message.paymentReference);
    message.amount !== undefined && (obj.amount = message.amount);
    message.paidTimestamp !== undefined && (obj.paidTimestamp = message.paidTimestamp.toISOString());
    message.trasnactionId !== undefined && (obj.trasnactionId = message.trasnactionId);
    message.supporterUid !== undefined && (obj.supporterUid = message.supporterUid);
    message.prizeId !== undefined && (obj.prizeId = message.prizeId);
    return obj;
  },

  create<I extends Exact<DeepPartial<Payout>, I>>(base?: I): Payout {
    return Payout.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Payout>, I>>(object: I): Payout {
    const message = createBasePayout();
    message.mainEventId = object.mainEventId ?? "";
    message.clubId = object.clubId ?? "";
    message.ssn = object.ssn ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.paymentReference = object.paymentReference ?? "";
    message.amount = object.amount ?? "";
    message.paidTimestamp = object.paidTimestamp ?? undefined;
    message.trasnactionId = object.trasnactionId ?? "";
    message.supporterUid = object.supporterUid ?? "";
    message.prizeId = object.prizeId ?? "";
    return message;
  },
};

function createBasePaymentRequestResponse(): PaymentRequestResponse {
  return { transactionId: "", swishToken: "" };
}

export const PaymentRequestResponse = {
  encode(message: PaymentRequestResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.swishToken !== "") {
      writer.uint32(18).string(message.swishToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentRequestResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentRequestResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.swishToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentRequestResponse {
    return {
      transactionId: isSet(object.transactionId) ? String(object.transactionId) : "",
      swishToken: isSet(object.swishToken) ? String(object.swishToken) : "",
    };
  },

  toJSON(message: PaymentRequestResponse): unknown {
    const obj: any = {};
    message.transactionId !== undefined && (obj.transactionId = message.transactionId);
    message.swishToken !== undefined && (obj.swishToken = message.swishToken);
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentRequestResponse>, I>>(base?: I): PaymentRequestResponse {
    return PaymentRequestResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PaymentRequestResponse>, I>>(object: I): PaymentRequestResponse {
    const message = createBasePaymentRequestResponse();
    message.transactionId = object.transactionId ?? "";
    message.swishToken = object.swishToken ?? "";
    return message;
  },
};

function createBaseSendSupporterTransactionConfirmationPayload(): SendSupporterTransactionConfirmationPayload {
  return { email: "", phoneNumber: "", transactionId: "", mainEventId: "", clubId: "" };
}

export const SendSupporterTransactionConfirmationPayload = {
  encode(message: SendSupporterTransactionConfirmationPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(18).string(message.phoneNumber);
    }
    if (message.transactionId !== "") {
      writer.uint32(26).string(message.transactionId);
    }
    if (message.mainEventId !== "") {
      writer.uint32(34).string(message.mainEventId);
    }
    if (message.clubId !== "") {
      writer.uint32(42).string(message.clubId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendSupporterTransactionConfirmationPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendSupporterTransactionConfirmationPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.clubId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendSupporterTransactionConfirmationPayload {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
      transactionId: isSet(object.transactionId) ? String(object.transactionId) : "",
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
    };
  },

  toJSON(message: SendSupporterTransactionConfirmationPayload): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    message.transactionId !== undefined && (obj.transactionId = message.transactionId);
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    message.clubId !== undefined && (obj.clubId = message.clubId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SendSupporterTransactionConfirmationPayload>, I>>(
    base?: I,
  ): SendSupporterTransactionConfirmationPayload {
    return SendSupporterTransactionConfirmationPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SendSupporterTransactionConfirmationPayload>, I>>(
    object: I,
  ): SendSupporterTransactionConfirmationPayload {
    const message = createBaseSendSupporterTransactionConfirmationPayload();
    message.email = object.email ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.transactionId = object.transactionId ?? "";
    message.mainEventId = object.mainEventId ?? "";
    message.clubId = object.clubId ?? "";
    return message;
  },
};

function createBaseCountSubscribersRequestPayload(): CountSubscribersRequestPayload {
  return { clubId: "" };
}

export const CountSubscribersRequestPayload = {
  encode(message: CountSubscribersRequestPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clubId !== "") {
      writer.uint32(10).string(message.clubId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountSubscribersRequestPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountSubscribersRequestPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clubId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountSubscribersRequestPayload {
    return { clubId: isSet(object.clubId) ? String(object.clubId) : "" };
  },

  toJSON(message: CountSubscribersRequestPayload): unknown {
    const obj: any = {};
    message.clubId !== undefined && (obj.clubId = message.clubId);
    return obj;
  },

  create<I extends Exact<DeepPartial<CountSubscribersRequestPayload>, I>>(base?: I): CountSubscribersRequestPayload {
    return CountSubscribersRequestPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CountSubscribersRequestPayload>, I>>(
    object: I,
  ): CountSubscribersRequestPayload {
    const message = createBaseCountSubscribersRequestPayload();
    message.clubId = object.clubId ?? "";
    return message;
  },
};

function createBaseCountSubscribersResponsePayload(): CountSubscribersResponsePayload {
  return { count: 0 };
}

export const CountSubscribersResponsePayload = {
  encode(message: CountSubscribersResponsePayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).int32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountSubscribersResponsePayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountSubscribersResponsePayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.count = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountSubscribersResponsePayload {
    return { count: isSet(object.count) ? Number(object.count) : 0 };
  },

  toJSON(message: CountSubscribersResponsePayload): unknown {
    const obj: any = {};
    message.count !== undefined && (obj.count = Math.round(message.count));
    return obj;
  },

  create<I extends Exact<DeepPartial<CountSubscribersResponsePayload>, I>>(base?: I): CountSubscribersResponsePayload {
    return CountSubscribersResponsePayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CountSubscribersResponsePayload>, I>>(
    object: I,
  ): CountSubscribersResponsePayload {
    const message = createBaseCountSubscribersResponsePayload();
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseEmail(): Email {
  return {
    recipient: "",
    subject: "",
    html: "",
    id: "",
    emailProvider: EmailProvider.SENDGRID,
    timestamp: undefined,
    retryCount: 0,
  };
}

export const Email = {
  encode(message: Email, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.recipient !== "") {
      writer.uint32(10).string(message.recipient);
    }
    if (message.subject !== "") {
      writer.uint32(18).string(message.subject);
    }
    if (message.html !== "") {
      writer.uint32(26).string(message.html);
    }
    if (message.id !== "") {
      writer.uint32(34).string(message.id);
    }
    if (message.emailProvider !== EmailProvider.SENDGRID) {
      writer.uint32(40).int32(emailProviderToNumber(message.emailProvider));
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(50).fork()).ldelim();
    }
    if (message.retryCount !== 0) {
      writer.uint32(56).int32(message.retryCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Email {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipient = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subject = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.html = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.id = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.emailProvider = emailProviderFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.retryCount = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Email {
    return {
      recipient: isSet(object.recipient) ? String(object.recipient) : "",
      subject: isSet(object.subject) ? String(object.subject) : "",
      html: isSet(object.html) ? String(object.html) : "",
      id: isSet(object.id) ? String(object.id) : "",
      emailProvider: isSet(object.emailProvider) ? emailProviderFromJSON(object.emailProvider) : EmailProvider.SENDGRID,
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      retryCount: isSet(object.retryCount) ? Number(object.retryCount) : 0,
    };
  },

  toJSON(message: Email): unknown {
    const obj: any = {};
    message.recipient !== undefined && (obj.recipient = message.recipient);
    message.subject !== undefined && (obj.subject = message.subject);
    message.html !== undefined && (obj.html = message.html);
    message.id !== undefined && (obj.id = message.id);
    message.emailProvider !== undefined && (obj.emailProvider = emailProviderToJSON(message.emailProvider));
    message.timestamp !== undefined && (obj.timestamp = message.timestamp.toISOString());
    message.retryCount !== undefined && (obj.retryCount = Math.round(message.retryCount));
    return obj;
  },

  create<I extends Exact<DeepPartial<Email>, I>>(base?: I): Email {
    return Email.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Email>, I>>(object: I): Email {
    const message = createBaseEmail();
    message.recipient = object.recipient ?? "";
    message.subject = object.subject ?? "";
    message.html = object.html ?? "";
    message.id = object.id ?? "";
    message.emailProvider = object.emailProvider ?? EmailProvider.SENDGRID;
    message.timestamp = object.timestamp ?? undefined;
    message.retryCount = object.retryCount ?? 0;
    return message;
  },
};

function createBaseSendEntranceTicketInviteEmailRequestPayload(): SendEntranceTicketInviteEmailRequestPayload {
  return { email: "", mainEventId: "", entranceTickets: [], clubId: "" };
}

export const SendEntranceTicketInviteEmailRequestPayload = {
  encode(message: SendEntranceTicketInviteEmailRequestPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.mainEventId !== "") {
      writer.uint32(18).string(message.mainEventId);
    }
    for (const v of message.entranceTickets) {
      EntranceTicketClassCount.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.clubId !== "") {
      writer.uint32(34).string(message.clubId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendEntranceTicketInviteEmailRequestPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendEntranceTicketInviteEmailRequestPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.entranceTickets.push(EntranceTicketClassCount.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clubId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendEntranceTicketInviteEmailRequestPayload {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      entranceTickets: Array.isArray(object?.entranceTickets)
        ? object.entranceTickets.map((e: any) => EntranceTicketClassCount.fromJSON(e))
        : [],
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
    };
  },

  toJSON(message: SendEntranceTicketInviteEmailRequestPayload): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    if (message.entranceTickets) {
      obj.entranceTickets = message.entranceTickets.map((e) => e ? EntranceTicketClassCount.toJSON(e) : undefined);
    } else {
      obj.entranceTickets = [];
    }
    message.clubId !== undefined && (obj.clubId = message.clubId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SendEntranceTicketInviteEmailRequestPayload>, I>>(
    base?: I,
  ): SendEntranceTicketInviteEmailRequestPayload {
    return SendEntranceTicketInviteEmailRequestPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SendEntranceTicketInviteEmailRequestPayload>, I>>(
    object: I,
  ): SendEntranceTicketInviteEmailRequestPayload {
    const message = createBaseSendEntranceTicketInviteEmailRequestPayload();
    message.email = object.email ?? "";
    message.mainEventId = object.mainEventId ?? "";
    message.entranceTickets = object.entranceTickets?.map((e) => EntranceTicketClassCount.fromPartial(e)) || [];
    message.clubId = object.clubId ?? "";
    return message;
  },
};

function createBaseIssueSupporterTransactionRequestPayload(): IssueSupporterTransactionRequestPayload {
  return { clubId: "", maineventId: "", entranceTickets: [] };
}

export const IssueSupporterTransactionRequestPayload = {
  encode(message: IssueSupporterTransactionRequestPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clubId !== "") {
      writer.uint32(10).string(message.clubId);
    }
    if (message.maineventId !== "") {
      writer.uint32(18).string(message.maineventId);
    }
    for (const v of message.entranceTickets) {
      EntranceTicketClassCount.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueSupporterTransactionRequestPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueSupporterTransactionRequestPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.maineventId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.entranceTickets.push(EntranceTicketClassCount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueSupporterTransactionRequestPayload {
    return {
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      maineventId: isSet(object.maineventId) ? String(object.maineventId) : "",
      entranceTickets: Array.isArray(object?.entranceTickets)
        ? object.entranceTickets.map((e: any) => EntranceTicketClassCount.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IssueSupporterTransactionRequestPayload): unknown {
    const obj: any = {};
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.maineventId !== undefined && (obj.maineventId = message.maineventId);
    if (message.entranceTickets) {
      obj.entranceTickets = message.entranceTickets.map((e) => e ? EntranceTicketClassCount.toJSON(e) : undefined);
    } else {
      obj.entranceTickets = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueSupporterTransactionRequestPayload>, I>>(
    base?: I,
  ): IssueSupporterTransactionRequestPayload {
    return IssueSupporterTransactionRequestPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueSupporterTransactionRequestPayload>, I>>(
    object: I,
  ): IssueSupporterTransactionRequestPayload {
    const message = createBaseIssueSupporterTransactionRequestPayload();
    message.clubId = object.clubId ?? "";
    message.maineventId = object.maineventId ?? "";
    message.entranceTickets = object.entranceTickets?.map((e) => EntranceTicketClassCount.fromPartial(e)) || [];
    return message;
  },
};

function createBaseClubFeedback(): ClubFeedback {
  return { clubId: "", uid: "", feedback: "", timestamp: undefined };
}

export const ClubFeedback = {
  encode(message: ClubFeedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clubId !== "") {
      writer.uint32(10).string(message.clubId);
    }
    if (message.uid !== "") {
      writer.uint32(18).string(message.uid);
    }
    if (message.feedback !== "") {
      writer.uint32(26).string(message.feedback);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClubFeedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClubFeedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.uid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.feedback = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClubFeedback {
    return {
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      uid: isSet(object.uid) ? String(object.uid) : "",
      feedback: isSet(object.feedback) ? String(object.feedback) : "",
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
    };
  },

  toJSON(message: ClubFeedback): unknown {
    const obj: any = {};
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.uid !== undefined && (obj.uid = message.uid);
    message.feedback !== undefined && (obj.feedback = message.feedback);
    message.timestamp !== undefined && (obj.timestamp = message.timestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<ClubFeedback>, I>>(base?: I): ClubFeedback {
    return ClubFeedback.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClubFeedback>, I>>(object: I): ClubFeedback {
    const message = createBaseClubFeedback();
    message.clubId = object.clubId ?? "";
    message.uid = object.uid ?? "";
    message.feedback = object.feedback ?? "";
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

function createBaseClubNotificationMessage(): ClubNotificationMessage {
  return { title: "", text: "", imageUrl: "" };
}

export const ClubNotificationMessage = {
  encode(message: ClubNotificationMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.text !== "") {
      writer.uint32(18).string(message.text);
    }
    if (message.imageUrl !== "") {
      writer.uint32(26).string(message.imageUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClubNotificationMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClubNotificationMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.text = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClubNotificationMessage {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      text: isSet(object.text) ? String(object.text) : "",
      imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
    };
  },

  toJSON(message: ClubNotificationMessage): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.text !== undefined && (obj.text = message.text);
    message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
    return obj;
  },

  create<I extends Exact<DeepPartial<ClubNotificationMessage>, I>>(base?: I): ClubNotificationMessage {
    return ClubNotificationMessage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClubNotificationMessage>, I>>(object: I): ClubNotificationMessage {
    const message = createBaseClubNotificationMessage();
    message.title = object.title ?? "";
    message.text = object.text ?? "";
    message.imageUrl = object.imageUrl ?? "";
    return message;
  },
};

function createBaseClubNotification(): ClubNotification {
  return {
    type: ClubmateNotificationType.SILENT,
    messages: [],
    userTargets: [],
    lotteryAgreementTypes: [],
    entranceAgreementTypes: [],
    id: "",
    timestamp: undefined,
  };
}

export const ClubNotification = {
  encode(message: ClubNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== ClubmateNotificationType.SILENT) {
      writer.uint32(8).int32(clubmateNotificationTypeToNumber(message.type));
    }
    for (const v of message.messages) {
      ClubNotificationMessage.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.userTargets) {
      writer.int32(clubUserTypeToNumber(v));
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.lotteryAgreementTypes) {
      writer.int32(lotteryAgreementTypeToNumber(v));
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.entranceAgreementTypes) {
      writer.int32(entranceAgreementTypeToNumber(v));
    }
    writer.ldelim();
    if (message.id !== "") {
      writer.uint32(50).string(message.id);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClubNotification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClubNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = clubmateNotificationTypeFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.messages.push(ClubNotificationMessage.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag === 24) {
            message.userTargets.push(clubUserTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.userTargets.push(clubUserTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.lotteryAgreementTypes.push(lotteryAgreementTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.lotteryAgreementTypes.push(lotteryAgreementTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 5:
          if (tag === 40) {
            message.entranceAgreementTypes.push(entranceAgreementTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.entranceAgreementTypes.push(entranceAgreementTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.id = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClubNotification {
    return {
      type: isSet(object.type) ? clubmateNotificationTypeFromJSON(object.type) : ClubmateNotificationType.SILENT,
      messages: Array.isArray(object?.messages)
        ? object.messages.map((e: any) => ClubNotificationMessage.fromJSON(e))
        : [],
      userTargets: Array.isArray(object?.userTargets)
        ? object.userTargets.map((e: any) => clubUserTypeFromJSON(e))
        : [],
      lotteryAgreementTypes: Array.isArray(object?.lotteryAgreementTypes)
        ? object.lotteryAgreementTypes.map((e: any) => lotteryAgreementTypeFromJSON(e))
        : [],
      entranceAgreementTypes: Array.isArray(object?.entranceAgreementTypes)
        ? object.entranceAgreementTypes.map((e: any) => entranceAgreementTypeFromJSON(e))
        : [],
      id: isSet(object.id) ? String(object.id) : "",
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
    };
  },

  toJSON(message: ClubNotification): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = clubmateNotificationTypeToJSON(message.type));
    if (message.messages) {
      obj.messages = message.messages.map((e) => e ? ClubNotificationMessage.toJSON(e) : undefined);
    } else {
      obj.messages = [];
    }
    if (message.userTargets) {
      obj.userTargets = message.userTargets.map((e) => clubUserTypeToJSON(e));
    } else {
      obj.userTargets = [];
    }
    if (message.lotteryAgreementTypes) {
      obj.lotteryAgreementTypes = message.lotteryAgreementTypes.map((e) => lotteryAgreementTypeToJSON(e));
    } else {
      obj.lotteryAgreementTypes = [];
    }
    if (message.entranceAgreementTypes) {
      obj.entranceAgreementTypes = message.entranceAgreementTypes.map((e) => entranceAgreementTypeToJSON(e));
    } else {
      obj.entranceAgreementTypes = [];
    }
    message.id !== undefined && (obj.id = message.id);
    message.timestamp !== undefined && (obj.timestamp = message.timestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<ClubNotification>, I>>(base?: I): ClubNotification {
    return ClubNotification.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClubNotification>, I>>(object: I): ClubNotification {
    const message = createBaseClubNotification();
    message.type = object.type ?? ClubmateNotificationType.SILENT;
    message.messages = object.messages?.map((e) => ClubNotificationMessage.fromPartial(e)) || [];
    message.userTargets = object.userTargets?.map((e) => e) || [];
    message.lotteryAgreementTypes = object.lotteryAgreementTypes?.map((e) => e) || [];
    message.entranceAgreementTypes = object.entranceAgreementTypes?.map((e) => e) || [];
    message.id = object.id ?? "";
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

function createBaseAllClubNotifications(): AllClubNotifications {
  return { notifications: [] };
}

export const AllClubNotifications = {
  encode(message: AllClubNotifications, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notifications) {
      ClubNotification.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AllClubNotifications {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAllClubNotifications();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notifications.push(ClubNotification.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AllClubNotifications {
    return {
      notifications: Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => ClubNotification.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AllClubNotifications): unknown {
    const obj: any = {};
    if (message.notifications) {
      obj.notifications = message.notifications.map((e) => e ? ClubNotification.toJSON(e) : undefined);
    } else {
      obj.notifications = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AllClubNotifications>, I>>(base?: I): AllClubNotifications {
    return AllClubNotifications.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AllClubNotifications>, I>>(object: I): AllClubNotifications {
    const message = createBaseAllClubNotifications();
    message.notifications = object.notifications?.map((e) => ClubNotification.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateApplePassPayload(): CreateApplePassPayload {
  return { transactionId: "", clubId: "", mainEventId: "" };
}

export const CreateApplePassPayload = {
  encode(message: CreateApplePassPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.clubId !== "") {
      writer.uint32(18).string(message.clubId);
    }
    if (message.mainEventId !== "") {
      writer.uint32(26).string(message.mainEventId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateApplePassPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateApplePassPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clubId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateApplePassPayload {
    return {
      transactionId: isSet(object.transactionId) ? String(object.transactionId) : "",
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
    };
  },

  toJSON(message: CreateApplePassPayload): unknown {
    const obj: any = {};
    message.transactionId !== undefined && (obj.transactionId = message.transactionId);
    message.clubId !== undefined && (obj.clubId = message.clubId);
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateApplePassPayload>, I>>(base?: I): CreateApplePassPayload {
    return CreateApplePassPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateApplePassPayload>, I>>(object: I): CreateApplePassPayload {
    const message = createBaseCreateApplePassPayload();
    message.transactionId = object.transactionId ?? "";
    message.clubId = object.clubId ?? "";
    message.mainEventId = object.mainEventId ?? "";
    return message;
  },
};

function createBaseStreamCommand(): StreamCommand {
  return { issueTimestamp: undefined, mainEventId: "", type: StreamCommandType.RAFFLE, clubId: "" };
}

export const StreamCommand = {
  encode(message: StreamCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issueTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.issueTimestamp), writer.uint32(10).fork()).ldelim();
    }
    if (message.mainEventId !== "") {
      writer.uint32(18).string(message.mainEventId);
    }
    if (message.type !== StreamCommandType.RAFFLE) {
      writer.uint32(24).int32(streamCommandTypeToNumber(message.type));
    }
    if (message.clubId !== "") {
      writer.uint32(34).string(message.clubId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StreamCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStreamCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.issueTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mainEventId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = streamCommandTypeFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clubId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StreamCommand {
    return {
      issueTimestamp: isSet(object.issueTimestamp) ? fromJsonTimestamp(object.issueTimestamp) : undefined,
      mainEventId: isSet(object.mainEventId) ? String(object.mainEventId) : "",
      type: isSet(object.type) ? streamCommandTypeFromJSON(object.type) : StreamCommandType.RAFFLE,
      clubId: isSet(object.clubId) ? String(object.clubId) : "",
    };
  },

  toJSON(message: StreamCommand): unknown {
    const obj: any = {};
    message.issueTimestamp !== undefined && (obj.issueTimestamp = message.issueTimestamp.toISOString());
    message.mainEventId !== undefined && (obj.mainEventId = message.mainEventId);
    message.type !== undefined && (obj.type = streamCommandTypeToJSON(message.type));
    message.clubId !== undefined && (obj.clubId = message.clubId);
    return obj;
  },

  create<I extends Exact<DeepPartial<StreamCommand>, I>>(base?: I): StreamCommand {
    return StreamCommand.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StreamCommand>, I>>(object: I): StreamCommand {
    const message = createBaseStreamCommand();
    message.issueTimestamp = object.issueTimestamp ?? undefined;
    message.mainEventId = object.mainEventId ?? "";
    message.type = object.type ?? StreamCommandType.RAFFLE;
    message.clubId = object.clubId ?? "";
    return message;
  },
};

function createBaseInterestedClub(): InterestedClub {
  return { email: "", phoneNumber: "", clubName: "" };
}

export const InterestedClub = {
  encode(message: InterestedClub, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(18).string(message.phoneNumber);
    }
    if (message.clubName !== "") {
      writer.uint32(26).string(message.clubName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterestedClub {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterestedClub();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clubName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterestedClub {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
      clubName: isSet(object.clubName) ? String(object.clubName) : "",
    };
  },

  toJSON(message: InterestedClub): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    message.clubName !== undefined && (obj.clubName = message.clubName);
    return obj;
  },

  create<I extends Exact<DeepPartial<InterestedClub>, I>>(base?: I): InterestedClub {
    return InterestedClub.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InterestedClub>, I>>(object: I): InterestedClub {
    const message = createBaseInterestedClub();
    message.email = object.email ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.clubName = object.clubName ?? "";
    return message;
  },
};

function createBaseOpponentClubLogos(): OpponentClubLogos {
  return { opponentClubs: [] };
}

export const OpponentClubLogos = {
  encode(message: OpponentClubLogos, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.opponentClubs) {
      OpponentClub.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OpponentClubLogos {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpponentClubLogos();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.opponentClubs.push(OpponentClub.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpponentClubLogos {
    return {
      opponentClubs: Array.isArray(object?.opponentClubs)
        ? object.opponentClubs.map((e: any) => OpponentClub.fromJSON(e))
        : [],
    };
  },

  toJSON(message: OpponentClubLogos): unknown {
    const obj: any = {};
    if (message.opponentClubs) {
      obj.opponentClubs = message.opponentClubs.map((e) => e ? OpponentClub.toJSON(e) : undefined);
    } else {
      obj.opponentClubs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OpponentClubLogos>, I>>(base?: I): OpponentClubLogos {
    return OpponentClubLogos.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OpponentClubLogos>, I>>(object: I): OpponentClubLogos {
    const message = createBaseOpponentClubLogos();
    message.opponentClubs = object.opponentClubs?.map((e) => OpponentClub.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAllCounties(): AllCounties {
  return { counties: [] };
}

export const AllCounties = {
  encode(message: AllCounties, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.counties) {
      County.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AllCounties {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAllCounties();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.counties.push(County.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AllCounties {
    return { counties: Array.isArray(object?.counties) ? object.counties.map((e: any) => County.fromJSON(e)) : [] };
  },

  toJSON(message: AllCounties): unknown {
    const obj: any = {};
    if (message.counties) {
      obj.counties = message.counties.map((e) => e ? County.toJSON(e) : undefined);
    } else {
      obj.counties = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AllCounties>, I>>(base?: I): AllCounties {
    return AllCounties.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AllCounties>, I>>(object: I): AllCounties {
    const message = createBaseAllCounties();
    message.counties = object.counties?.map((e) => County.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
