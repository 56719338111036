import React from "react";
import logoTop from "../../../public/black_top.png";

export default function LoadingIndicator(props: {color? : string}) {
  //TODO ADD CLUB ETC
  return (
    <div className="flex flex-col justify-center items-center">
      <svg className="w-[37px]"  viewBox="0 0 128 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M63.505 85.8826C69.8007 85.8798 76.0803 86.5893 82.2457 88C91.1179 55.1942 106.686 25.0853 127.747 0H89.2706C71.9177 26.014 59.1842 55.4376 51.7466 86.7082C55.6463 86.1576 59.5736 85.8818 63.505 85.8826Z"
          fill={props.color ?? "white"}
        />
        <path
          d="M43.0153 82.0012C46.9499 66.6271 52.1244 51.6993 58.4729 37.4079C52.6573 24.316 45.8856 11.8027 38.2291 0H0C19.2594 23.5789 33.9184 51.5238 43.0153 82.0012V82.0012Z"
          fill={props.color ?? "white"}
        />
      </svg>

      <svg viewBox="25 25 50 50" className="progressIndicator">
        <circle className="logoSpinner" cx="50" cy="50" r="20"></circle>
      </svg>
    </div>
  );
}
