import { initializeApp, getApp, getApps } from "firebase/app";
import {
  initializeFirestore,
  getFirestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import {
  getAuth,
  connectAuthEmulator,

} from "firebase/auth";

import { getPerformance } from "firebase/performance";


import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


import { functionsRegion } from "../common/firebase_api/cloud_function_names";
import { getAnalytics,   isSupported,
} from "firebase/analytics";
import { isDev, isProd, isStage, isTest } from "../tools/environment";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

function initializeFirebaseProd() {
  
  initializeApp(firebaseConfig);
  initializeFirestore(getApp(), {});
  getFunctions().region = functionsRegion;
}


function initializeFirebaseEmulators() {
  console.log("INITIALIZING EMULATORS ");
  initializeApp(firebaseConfig);
  initializeFirestore(getApp(), {
    experimentalAutoDetectLongPolling: true
  });
  const db = getFirestore();
  
  connectFirestoreEmulator(db, "localhost", (+process.env.REACT_APP_FIRESTORE_EMULATOR_PORT!));
  const functions = getFunctions(getApp());
  functions.region = functionsRegion
  
  connectFunctionsEmulator(functions, "localhost", +process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT!);
  const auth = getAuth();
  connectAuthEmulator(auth, "http://localhost:" + process.env.REACT_APP_AUTH_EMULATOR_PORT!, {disableWarnings : true});
}




export async function initializeFirebase(cypressTest:boolean=false) {

  
  if(getApps().length == 0){
    if(isDev() || isTest() || cypressTest){
      console.log("INITIALIZING EMULATORS");
      initializeFirebaseEmulators();
   }else if(isStage()){
    console.log("INITIALIZING STAGE");
    initializeFirebaseProd();
    if(await isSupported()){
      const analytics = getAnalytics(getApp());
      const perf = getPerformance(getApp());
    }
   }else if(isProd()){
    console.log("INITIALIZING PRODUCTION");
      initializeFirebaseProd();
      if(await isSupported()){
        const analytics = getAnalytics(getApp());
        const perf = getPerformance(getApp());
      }
   }else{
     throw Error("The environment could not be determined");
   }
  }
}