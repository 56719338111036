import React, { useEffect, useState } from 'react'
import { fetchClub, fetchClubs } from '../api/clubApi';
import { Club, ClubUserType } from '../model/lottery'
import { useClubPortalState } from '../state/useClubPortalState';
import LoadingIndicatorModal from './LoadingIndicatorModal';

const ClubRoleListItem= (props: {clubName: string, logoUrl: string, clubUserType: ClubUserType, onclick: () => void}) => {
    return <li onClick={props.onclick} className='p-2 rounded-sm odd:border-primary flex flex-row justify-between w-full hover:cursor-pointer'>
        <div className='flex flex-col items-center'>
        <span>{props.clubName}</span>
        <span className='font-bold text-xs'>{props.clubName}</span>
        </div>
        <img width={60} src={props.logoUrl} alt={props.clubName}/>

    </li>
}

export default function ClubSelection(props: {clubRoles: {[clubId:string] : ClubUserType}, onClubSelected: (club:Club) => void}) {
  const [clubs, setClubs] = useState([] as Club[] | undefined);
  const {showMessage} = useClubPortalState();
  useEffect(() => {
    fetchClubs(Object.keys(props.clubRoles).filter((c => props.clubRoles[c] == ClubUserType.ADMIN))).then((cs) => {
        if(cs.length == 1){
            props.onClubSelected(cs[0]);
        }
        setClubs(cs);
    }).catch(err => showMessage({message: "Något gick fel - kontrollera internetanslutningen",  type:"error"}))
  }, []);
  return (
    <>
    {clubs == undefined && <LoadingIndicatorModal/> }
    {(clubs != undefined && clubs.length == 0)  && <div className='text-5xl'>Du är inte tillagd i någon klubb</div>}
    {(clubs != undefined && clubs.length > 1) && <article className='clubmate-card flex flex-col items-center'>
        <h2>Välj klubb</h2>
        <ul>
            {clubs.map(c => <ClubRoleListItem  onclick={() => props.onClubSelected(c)} clubName={c.name} clubUserType={props.clubRoles[c.id]} logoUrl={c.logoUrl}/>)}
        </ul>
        </article>}
    </>
  )
}
