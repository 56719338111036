import React from 'react'
import { Club, IssuedEntranceTicket, MainEvent } from '../../model/lottery'
import EntranceTicketBack from './EntranceTicketBack'
import EntranceTicketFront from './EntranceTicketFront'
import TwoSidedEntranceTicketWrapper from './TwoSidedEntranceTicketWrapper';


export default function TwoSidedEntranceTicketA4Pages(props: {club: Club, issuedEntranceTickets: IssuedEntranceTicket[], mainEvent: MainEvent}) {
  const pagePairs = Math.ceil(props.issuedEntranceTickets.length / 10);
    return (
      <div className='grid grid-cols-2'>
          {Array.from(Array(pagePairs).keys()).flatMap(p => {
            
            return [... Array.from(Array(10).keys()).map((n) => {
              const realIndex = p * 10 + n;
              if(realIndex < (props.issuedEntranceTickets.length )){
                return <EntranceTicketFront mainEvent={props.mainEvent} club={props.club} />
              }else{
                return <TwoSidedEntranceTicketWrapper children={<></>}/>
              }
           
            }), ...Array.from(Array(10).keys()).map((n) => {
                const realIndex = p * 10 + n;
                if(realIndex < (props.issuedEntranceTickets.length )){
                  return <EntranceTicketBack issuedEntranceTicket={props.issuedEntranceTickets[realIndex]} club={props.club} />
                }else{
                  return <TwoSidedEntranceTicketWrapper children={<></>}/>
                }
                
            
            })]
        
           
          })}
      </div>
    )
  }
  