import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import React, { useState } from 'react'


export default function SignIn() {
 const [userName, setUserName] = useState("");
 const [password, setPassword] = useState("");

  const convertUserName = (textInput: string) => {
    if(textInput.includes("@")){
      return textInput;
    }else{
      return textInput + "@yourclubmate.com";
    }
  }

  return (
    <div className='flex flex-row justify-center w-full mt-[5%] '>
    <div className='article flex flex-col items-center space-y-3 w-[450px] clubmate-card  '>
        <h2 className='text-xl font-bold'>Logga in med ditt Clubmatekonto</h2>
        <div className='flex flex-col items-center space-y-2'>
        <label className="label">
            <span className="label-text">Användarnamn/Email</span>
        </label>
        <input  onChange={(e) => setUserName(e.target.value)} value={userName} className='input' type={"text"}/>
        
        <label className="label">
            <span className="label-text">Lösenord</span>
        </label>
        <input onChange={(e) => setPassword(e.target.value)} value={password} className='input' type={"password"}/>
        </div>
        <button className='btn btn-primary' onClick={() => {
          signInWithEmailAndPassword(getAuth(), convertUserName(userName), password).then(() => {}).catch(() => {
            
          });
        }}>Logga in</button>
    </div>
    </div>
  )
}
