import React, { ReactNode } from 'react'

export type ModuleType = "availible" | "availibleSoon" | "noAccess" 

export default function MenuItem(props: {itemName: string, icon: ReactNode,onClick :() => void, moduleType: ModuleType}) {
  return (
    <button onClick={props.onClick} className="relative">
      <div  className={'border border-primary  shadow-xl rounded-xl padding-5 flex flex-col items-center justify-center w-32 h-32 hover:opacity-50  ' + (props.moduleType != "availible" ? "opacity-20" : "")}>
        {props.icon}
        <h4 className='font-bold text-xs text-black'>{props.itemName}</h4>
        </div>
        {(props.moduleType != "availible") && <div className='absolute z-10 right-1 top-1  badge-primary badge opacity-90'><span className='font-bold text-[0.5rem]'>
          {(props.moduleType == "availibleSoon") && "Kommer snart"}
          {(props.moduleType == "noAccess") && "Inte tillgänglig"}
        </span></div> }
    </button>
  )
}
