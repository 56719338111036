import React from 'react'

export interface EntranceTicketDesignOptions{
    oneSided: boolean;
}

export  function EntranceTicketDesignOptions(props: {designOptions: EntranceTicketDesignOptions, onDesignOptionChanged: (designOptions: EntranceTicketDesignOptions) => void}) {
  return  <div className='flex flex-row'>

 <div className="form-control flex-row flex ">
   <label className="label cursor-pointer space-x-2">
     <span className="label-text label font-bold">Ensidiga entrébiljetter</span> 
     <input  type="checkbox" checked={props.designOptions.oneSided} onChange={(e) => {props.onDesignOptionChanged({...props.designOptions, oneSided : e.target.checked})}}  className="checkbox checkbox-primary checkbox-lg" />
   </label>
 </div>
 </div>;
}
