import React from "react";
import LoadingIndicator from "./LoadingIndicator";

function LoadingIndicatorModal() {
  return (
    <div className="bg-black/[.5] fixed  left-0 bottom-0 w-screen h-screen z-10 flex justify-center items-center">
        <LoadingIndicator />
    </div>
  );
}

export default LoadingIndicatorModal;
