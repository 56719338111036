import React from 'react'
import MenuItem from './MenuItem'
import {HiOutlineTicket, HiQrcode} from "react-icons/hi";
import {GiTicket} from "react-icons/gi";
import {FaFileInvoiceDollar} from "react-icons/fa";
import {ImStatsDots} from "react-icons/im";
import {FiSettings} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { MdEventAvailable } from 'react-icons/md';


export default function ModulesMenu() {
    const navigate = useNavigate();
  return (
    <div className='grid grid-cols-4 grid-rows-4 gap-4 '>
        <MenuItem icon={<HiOutlineTicket className='text-3xl text-primary'/>} itemName={"Entrébiljetter"} onClick={() => {
            navigate("/entre");

        }} moduleType={"availible"}/>
        <MenuItem icon={<HiQrcode className='text-3xl text-primary'/>} itemName={"Skriv ut Qr-koder"} onClick={() => {}} moduleType={"availibleSoon"}/>
        <MenuItem icon={<ImStatsDots className='text-3xl text-primary'/>} itemName={"Statistik"} onClick={() => {}} moduleType={"availibleSoon"}/>
        <MenuItem icon={<FaFileInvoiceDollar className='text-3xl text-primary'/>} itemName={"Redovisning"} onClick={() => {}} moduleType={"availibleSoon"}/>
        <MenuItem icon={<MdEventAvailable className='text-3xl text-primary'/>} itemName={"Evenemang"} onClick={() => {}} moduleType={"availibleSoon"}/>
        <MenuItem icon={<FiSettings className='text-3xl text-primary'/>} itemName={"Inställningar"} onClick={() => {}} moduleType={"availibleSoon"}/>
    </div>
  )
}
